import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'eflex/config/environment';
import { getCaptionText } from 'eflex/util/translation-helper';

export default class EvisionImage extends Model {
  @service intl;
  @service currentUser;

  @attr('number') fileSize;
  @attr('string') url;
  @attr('string') thumbnailUrl;
  @attr('string') serialNumber;
  @attr('boolean', { defaultValue: false }) pass;
  @attr('string') cameraVersion;
  @attr('string') exposure;
  @attr('string') pallet;
  @attr('date') dateCreated;
  @attr('boolean', { defaultValue: false }) isConforming;
  @attr('string') filePath;
  @attr('string') fileType;
  @attr('string') fileName;
  @attr('boolean', { defaultValue: true }) hasImage;
  @attr task;
  @attr station;

  @tracked isActive;
  @tracked isSelected = false;

  get isPdf() {
    return this.fileType === 'pdf';
  }

  get stationName() {
    return getCaptionText(this.station?.captions, this.currentUser);
  }

  get taskName() {
    return getCaptionText(this.task?.captions, this.currentUser);
  }

  get hasTaskName() {
    return this.taskName != null;
  }

  get hasStationName() {
    return this.stationName != null;
  }

  get downloadUrl() {
    if (this.hasImage) {
      return this.fullUrl;
    } else {
      return this.fullThumbnailUrl;
    }
  }

  get fullUrl() {
    return `${config.photoUrlPrefix}/${this.url}`;
  }

  get fullThumbnailUrl() {
    return `${config.photoUrlPrefix}/${this.thumbnailUrl}`;
  }

  get stationPath() {
    if (this.station == null) {
      return null;
    }

    if (this.station.area != null && this.station.parent != null) {
      return `${this.station.area.name} / ${this.station.parent.name} / ${this.stationName}`;
    } else {
      return this.stationName;
    }
  }

  get statusText() {
    if (this.pass) {
      return this.intl.t('pass');
    } else {
      return this.intl.t('fail');
    }
  }
}
