/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';

const NO_CODE_WARNINGS = {
  scrap: 'errors.noScrapCodesForStation',
  reject: 'errors.noRejectCodesForStation',
  fault: 'errors.noFaultCodesForStation',
};

export default class CodeConfirmationModal extends Component {
  @service currentUser;
  @service codeRepo;
  @service notifier;
  @service intl;

  @tracked selected = [];
  @tracked note;

  codes = trackedFunction(this, async () => {
    const availableCodes = await this.codeRepo.retrieveCodes(this.args.codeType, this.args.station);
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    if (isEmpty(availableCodes)) {
      this.notifier.sendWarning(NO_CODE_WARNINGS[this.args.codeType]);
    }

    return availableCodes;
  });

  get invalid() {
    return isEmpty(this.selected);
  }

  get timestamp() {
    return moment().format('M-DD-YY h:mma').slice(0, -1);
  }

  codeMatcher(code, term) {
    return `${code.code} - ${code.description}`.indexOf(term);
  }

  @action
  onConfirm() {
    return this.args.onConfirm({
      note: this.note,
      codes: this.selected.map(code => ({
        code: code.code,
        description: code.description,
      })),
    });
  }

  @action
  confirmWithoutCode() {
    return this.args.onConfirm({
      codes: [{
        code: 'NOCODE',
        description: this.intl.t('reasonCodes.noCodesConfigured'),
      }],
    });
  }
}
