/* import __COLOCATED_TEMPLATE__ from './pass-fail.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class CriteriaVisorPassFail extends Component {
  @service intl;
  @tracked isDisplayed = Boolean(this.args.passed);

  get options() {
    return [
      {
        label: this.intl.t('passed'),
        value: true,
      },
      {
        label: this.intl.t('failed'),
        value: false,
      },
    ];
  }

  get breadcrumb() {
    return this.options.find(opt => opt.value === this.args.passed)?.label;
  }

  constructor() {
    super(...arguments);
    this.args.initParam('passed', this.args.passed);
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.updateParam('passed', null);
  }

  @action
  onSelected(selected) {
    this.args.updateParam('passed', selected?.value);
  }
}
