/* import __COLOCATED_TEMPLATE__ from './icon-button.hbs'; */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class IconButton extends Component {
  onClick = task({ drop: true }, waitFor(async () => {
    if (this.args.disabled) {
      return;
    }

    await this.args.onClick();
  }));
}
