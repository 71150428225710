import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import selectVal from 'eflex/helpers/select-val';
import { or } from 'ember-truth-helpers';
const limitSources = [
    {
        label: 'useDeviceLimits',
        value: 'false'
    },
    {
        label: 'useEflexLimits',
        value: 'true'
    }
];
export default class TaskConfigTorque extends Component {
    @service
    taskConfigRepo;
    @service
    taskRepo;
    get finalAngleDisabled() {
        return !this.args.taskConfig.finalAngleLimitsEnabled || this.allLimitsDisabled;
    }
    get finalTorqueDisabled() {
        return !this.args.taskConfig.finalTorqueLimitsEnabled || this.allLimitsDisabled;
    }
    get allLimitsDisabled() {
        return !this.args.task.torqueUseEflexLimits;
    }
    get currentLimitSource() {
        return limitSources.find((item1)=>item1.value === String(this.args.task.torqueUseEflexLimits));
    }
    setUseEflexLimits = (value1)=>{
        this.args.task.torqueUseEflexLimits = JSON.parse(value1);
    };
    setBoltCount = (val1)=>{
        const taskConfig1 = this.args.taskConfig;
        taskConfig1.boltCount = val1;
        this.taskRepo.removeEdhrMappingsBelowMaxBoltCount(this.args.task);
        this.taskConfigRepo.removeInvalidBoltAnimations(taskConfig1);
    };
    static{
        template(`
    <div class="row form-row model-specific">
      <div class="col-3">
        <@form.element
          @controlType="number"
          @label={{t "programNumber"}}
          @property="programNumber"
        />
      </div>
      <div class="col">
        <@form.element
          class="bolt-count"
          @controlType="number"
          @label={{t "plant.station.bolt"}}
          @property="boltCount"
          @onChange={{this.setBoltCount}}
        />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-3">
        <@form.element
          @controlType="radio-group"
          @options={{limitSources}}
          @value={{this.currentLimitSource}}
          @optionLabelPath="label"
          @onChange={{selectVal this.setUseEflexLimits}}
        as |el|>
          <el.control
            @translate={{true}}
          />
        </@form.element>
      </div>
      <div class="col model-specific">
        <@form.element
          @controlType="checkbox"
          @property="finalTorqueLimitsEnabled"
          @label={{t "plant.task.torque.finalTorque"}}
          class="mb-1"
        as |el|>
          <el.control @disabled={{or @disabled this.allLimitsDisabled}} />
        </@form.element>
        <div class="row form-row text-uppercase">
          <div class="col">
            <@form.element
              @controlType="number"
              @label={{t "lowerLimit"}}
              @property="finalTorqueLowerLimit"
              class="small"
            as |el|>
              <el.control disabled={{or @disabled this.finalTorqueDisabled}} />
            </@form.element>
          </div>
          <div class="col">
            <@form.element
              @controlType="number"
              @label={{t "upperLimit"}}
              @property="finalTorqueUpperLimit"
              class="small"
            as |el|>
              <el.control disabled={{or @disabled this.finalTorqueDisabled}} />
            </@form.element>
          </div>
        </div>
      </div>
      <div class="col model-specific">
        <@form.element
          @controlType="checkbox"
          @property="finalAngleLimitsEnabled"
          @label={{t "plant.task.torque.finalAngle"}}
          class="mb-1" as |el|
        >
          <el.control @disabled={{or this.allLimitsDisabled @disabled}} />
        </@form.element>
        <div class="row form-row text-uppercase">
          <div class="col">
            <@form.element
              @controlType="number"
              @label={{t "lowerLimit"}}
              @property="finalAngleLowerLimit"
              class="small"
            as |el|>
              <el.control
                disabled={{or @disabled this.finalAngleDisabled}}
              />
            </@form.element>
          </div>
          <div class="col">
            <@form.element
              @controlType="number"
              @label={{t "upperLimit"}}
              @property="finalAngleUpperLimit"
              class="small"
            as |el|>
              <el.control
                disabled={{or @disabled this.finalAngleDisabled}}
              />
            </@form.element>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
