import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { CAN_SEND_IMAGES_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { range, pipe, concat } from 'ramda';
import { sortByProp, concatRight } from 'ramda-adjunct';
import { t } from 'ember-intl';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
export default class EdhrTriggerConfig extends Component {
    @service
    store;
    @service
    intl;
    get parentTrigger() {
        return this.args.triggerConfig.parentTrigger;
    }
    get nonDeletedMappings() {
        return this.parentTrigger.edhrMappings.filter((item1)=>!item1.isDeleted);
    }
    get task() {
        return this.parentTrigger.task;
    }
    get showMappings() {
        return !this.args.isStationEvent && isPresent(this.nonDeletedMappings);
    }
    get processDataOptions() {
        const jemProcessDataDefs1 = this.task.jemProcessDataDefs.map((jemProcessDataDef1)=>({
                id: jemProcessDataDef1.id,
                processDataType: EdhrProcessDataTypes.jemProcessDataDef,
                name: jemProcessDataDef1.name,
                jemProcessDataDef: jemProcessDataDef1
            }));
        let variableDefs1 = [];
        if (this.task.isNodeRed) {
            variableDefs1 = this.task.variableDefs.filter((item1)=>item1.isFromDevice).map((variableDef1)=>({
                    id: variableDef1.id,
                    processDataType: EdhrProcessDataTypes.variableDef,
                    name: variableDef1.name,
                    variableDef: variableDef1
                }));
        }
        let bolts1 = [];
        if (this.task.isTorque) {
            bolts1 = range(1, this.task.maxBoltCount + 1).flatMap((i1)=>[
                    this._boltToProcessDatum(i1, EdhrProcessDataTypes.boltTorque),
                    this._boltToProcessDatum(i1, EdhrProcessDataTypes.boltAngle)
                ]);
        }
        let options1 = pipe(concatRight(jemProcessDataDefs1), concatRight(variableDefs1), sortByProp('name'), // these should always be last
        concatRight([
            this._getMappingOption(EdhrProcessDataTypes.currentDateTime),
            this._getMappingOption(EdhrProcessDataTypes.hardwareName),
            this._getMappingOption(EdhrProcessDataTypes.userDefinedString)
        ]))(bolts1);
        if (CAN_SEND_IMAGES_TASK_TYPES.has(this.task.taskType)) {
            options1 = concat(options1, [
                this._getMappingOption(EdhrProcessDataTypes.sendImage)
            ]);
        }
        if (this.task.isBarcode) {
            options1 = concat(options1, [
                this._getMappingOption(EdhrProcessDataTypes.scannedBarcode)
            ]);
        }
        if (this.task.isDecision) {
            options1 = concat(options1, [
                this._getMappingOption(EdhrProcessDataTypes.selectedDecision)
            ]);
        }
        return options1;
    }
    _boltToProcessDatum(boltNumber1, processDataType1) {
        return {
            id: this._getBoltId(boltNumber1, processDataType1),
            processDataType: processDataType1,
            name: `${this.intl.t('bolt')} ${boltNumber1} ${this.intl.t(processDataType1)}`,
            boltIndex: boltNumber1
        };
    }
    _getBoltId(boltNumber1, boltFieldType1) {
        return String(boltNumber1) + boltFieldType1;
    }
    _getMappingOption(processDataType1) {
        return {
            id: processDataType1,
            processDataType: processDataType1,
            name: this.intl.t(processDataType1)
        };
    }
    addMapping = (count1)=>{
        for(let i1 = 1; i1 <= count1; i1++){
            this.store.createRecord('edhrMapping', {
                edhrTrigger: this.parentTrigger
            });
        }
    };
    removeMapping = (mapping1)=>{
        mapping1.deleteRecord();
    };
    setProcessDatum = (mapping1, processDatum1)=>{
        Object.assign(mapping1, {
            boltIndex: processDatum1.boltIndex,
            processDataType: processDatum1.processDataType,
            jemProcessDataDef: processDatum1.jemProcessDataDef,
            variableDef: processDatum1.variableDef
        });
    };
    getDataMappingReference = (mapping1)=>{
        switch(mapping1.processDataType){
            case EdhrProcessDataTypes.jemProcessDataDef:
                {
                    return this.processDataOptions.find((item1)=>item1.id === mapping1.jemProcessDataDef?.id);
                }
            case EdhrProcessDataTypes.variableDef:
                {
                    return this.processDataOptions.find((item1)=>item1.id === mapping1.variableDef?.id);
                }
            case EdhrProcessDataTypes.boltTorque:
            case EdhrProcessDataTypes.boltAngle:
                {
                    return this.processDataOptions.find((item1)=>item1.id === this._getBoltId(mapping1.boltIndex, mapping1.processDataType));
                }
            default:
                {
                    return this.processDataOptions.find((item1)=>item1.id === mapping1.processDataType);
                }
        }
    };
    static{
        template(`
    <div class="row form-row align-nested-header pt-0">
      <div class="col">
        <div class="nested-header">
          {{t "operation"}}
        </div>
        <@form.element
          @model={{this.parentTrigger}}
          @controlType="text"
          @property="operation"
          @size="sm"
          class="trigger-operation"
        />
      </div>
      <div class="col-4">
        <div class="nested-header">
          {{t "reasonCode"}}
        </div>
        <@form.element
          @model={{this.parentTrigger}}
          @controlType="text"
          @property="reasonCode"
          @size="sm"
          class="trigger-reason-code"
        as |el|>
          <el.control disabled={{@disabled}} />
        </@form.element>
      </div>
    </div>
    {{#if this.showMappings}}
      <div class="row form-row py-0">
        <div class="col-4">
          <div class="nested-header">
            {{t "processData"}}
          </div>
        </div>
        <div class="col-4">
          <div class="nested-header">
            {{t "userDefinedString"}}
          </div>
        </div>
        <div class="col-4">
          <div class="nested-header">
            {{t "fieldName"}}
          </div>
        </div>
      </div>
      {{#each this.nonDeletedMappings as |mapping|}}
        <div class="edhr-row row form-row position-relative pt-0 pb-1">
          <div class={{if mapping.isUserDefinedString "col-4" "col-8"}}>
            <@form.element
              @model={{this.parentTrigger}}
              @controlType="power-select"
              @options={{this.processDataOptions}}
              @optionLabelPath="name"
              @value={{this.getDataMappingReference mapping}}
              @onChange={{fn this.setProcessDatum mapping}}
              class="trigger-mapping-key"
            as |el|>
              <el.control
                @triggerClass="trigger-mapping-select form-control form-control-sm"
                @searchField="name"
                @disabled={{@disabled}}
              />
            </@form.element>
          </div>
          {{#if mapping.isUserDefinedString}}
            <div class="col-4">
              <@form.element
                @controlType="text"
                @model={{mapping}}
                @property="customValue"
                @size="sm"
                class="trigger-mapping-value"
              as |el|>
                <el.control disabled={{@disabled}} />
              </@form.element>
            </div>
          {{/if}}
          {{#unless mapping.isSendFile}}
            <div class="col-4">
              <@form.element
                @controlType="text"
                @model={{mapping}}
                @property="fieldName"
                @size="sm"
                class="trigger-mapping-name"
              as |el|>
                <el.control disabled={{@disabled}} />
              </@form.element>
            </div>
          {{/unless}}
          <div class="col-auto absolute-trash">
            <div class="col-icon">
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn this.removeMapping mapping}}
              />
            </div>
          </div>
        </div>
      {{/each}}
    {{/if}}
    {{#unless @isStationEvent}}
      <div class="row form-row pt-0 add-process-data">
        <div class="col">
          <QuantitySelect
            class="add-mapping text-uppercase"
            @size="sm"
            @disabled={{@disabled}}
            @onSubmit={{this.addMapping}}
          >
            {{t "addProcessData"}}
          </QuantitySelect>
        </div>
      </div>
    {{/unless}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
