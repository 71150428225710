/* import __COLOCATED_TEMPLATE__ from './stv.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { reject } from 'ramda';
import getJemTaskComponent from 'eflex/util/get-jem-task-component';
import * as JemTaskState from 'eflex/util/jem-task-state';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class JemStv extends Component {
  TaskStatuses = TaskStatuses;

  @service taskRepo;

  get taskComponent() {
    return getJemTaskComponent(this.task.taskType);
  }

  get task() {
    return this.args.currentTask ?? this.args.tasks[0];
  }

  get viewingIndex() {
    let index = this.displayedTasks.indexOf(this.task);

    if (index < 0) {
      index = 0;
    } else if (index >= this.displayedTasks.length) {
      index = this.displayedTasks.length - 1;
    }

    return index;
  }

  get taskConfirmButtonIsShown() {
    return JemTaskState.taskConfirmButtonIsShown({
      childStatus: this.childStatus,
      task: this.task,
      taskConfig: this.args.currentTaskConfig,
    });
  }

  get taskConfirmIsDisabled() {
    return JemTaskState.taskConfirmIsDisabled({
      childStatus: this.childStatus,
      task: this.task,
      taskConfig: this.args.currentTaskConfig,
      notAuthorized: this.args.notAuthorized,
    });
  }

  get taskRejectButtonIsShown() {
    return JemTaskState.taskRejectButtonIsShown({
      childStatus: this.childStatus,
      task: this.task,
      taskConfig: this.args.currentTaskConfig,
    });
  }

  get taskRejectIsDisabled() {
    return JemTaskState.taskRejectIsDisabled({
      childStatus: this.childStatus,
      task: this.task,
      taskConfig: this.args.currentTaskConfig,
      notAuthorized: this.args.notAuthorized,
    });
  }

  @cached
  get displayedTasks() {
    return reject(task => {
      const taskConfig = this._getTaskConfig(task);
      return !taskConfig || taskConfig.disabled;
    }, this.args.tasks);
  }

  @cached
  get childStatus() {
    return this.args.buildStatus?.getChildStatusForTask(this.task);
  }

  get nextTaskDisabled() {
    return !this.args.currentTask || this.viewingIndex + 1 >= this.displayedTasks.length;
  }

  _getTaskConfig(task) {
    return this.taskRepo.getConfigForModelOrBuildDatum(
      task,
      this.args.model,
      this.args.buildStatus?.buildDatum,
    );
  }

  @action
  nextTask() {
    const nextIndex = this.viewingIndex + 1;

    if (nextIndex >= this.displayedTasks.length) {
      return;
    }

    const task = this.displayedTasks[nextIndex];

    if (!task) {
      return;
    }

    this.args.onSelectTask?.(task);
  }

  @action
  prevTask() {
    if (this.viewingIndex < 0) {
      return;
    }

    const previousIndex = this.viewingIndex - 1;
    const task = this.displayedTasks[previousIndex];

    if (!task) {
      return;
    }

    this.args.onSelectTask?.(task);
  }

  @action
  onTaskComplete(status) {
    return this.args.onTaskComplete(status, this.childStatus);
  }
}
