import JemConfiguration from 'eflex/models/jem-configuration';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  targetCycleTime: [
    validator('number', {
      gte: 0,
      allowString: true,
      allowNone: false,
    }),
  ],

  jemWarningPercent: [
    validator('number', {
      gt: 0,
      lte: 100,
      allowString: true,
      allowNone: false,
    }),
  ],

  jemTransferOffset: [
    validator('number', {
      gte: 0,
      allowString: true,
      allowNone: false,
    }),
  ],
})

class StationJemConfiguration extends JemConfiguration {
  @attr('number', { defaultValue: 0 }) targetCycleTime;
  @attr('number', { defaultValue: 70 }) jemWarningPercent;
  @attr('number', { defaultValue: 0 }) jemTransferOffset;
}
export default StationJemConfiguration;

