/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class OptionsWithDefault extends Component {
  get optionsWithDefault() {
    if (isPresent(this.args.defaultOption)) {
      return [
        {
          isDefaultOption: true,
          [this.args.displayedField]: this.args.defaultOption,
        },
        ...this.args.options,
      ];
    }

    return this.args.options;
  }

  get selectedOption() {
    if (this.args.selected == null) {
      return this.optionsWithDefault[0];
    } else {
      return this.args.selected;
    }
  }
}
