import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import pProps from 'p-props';
import { toTree } from 'eflex/util/tree-helpers';

const TAKE_AMOUNT = 20;

export default class SchedulesRoute extends AuthenticatedRoute {
  @service areaRepo;
  @service store;

  queryParams = {
    category: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
  };

  model(params) {
    return pProps({
      schedules: this.store.query('schedule', { category: params.category, page: params.page, take: TAKE_AMOUNT }),
      areas: this.areaRepo.areas,
    });
  }

  setupController(controller, { schedules, areas }) {
    Object.assign(controller, {
      scheduleCount: schedules.meta?.count ?? 0,
      queriedSchedules: schedules,
      take: TAKE_AMOUNT,
      treeData: areas.map(area =>
        toTree(area, {
          lastModel: 'station',
        }),
      ),
    });
  }

  resetController(controller) {
    controller.closeSchedulePanel();
    controller.changeCategory('current');
  }
}
