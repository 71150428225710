import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import numbro from 'numbro';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';

export default class EvisionCameraStatusController extends Controller {
  @service store;

  @tracked showImageModal = false;
  @tracked image;
  @tracked cameraStatuses = [];

  showImage = task(waitFor(async status => {
    const images = await this.store.query('evisionImage', { selectedImages: status.lastImage.id });

    Object.assign(this, {
      image: images[0],
      showImageModal: true,
    });
  }));

  formatRejectPercentage = (val) => {
    if (val == null) {
      return null;
    }

    return `${numbro(val).format({ mantissa: 2 })}%`;
  };
}
