/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import NavBarMap from 'eflex/util/nav-bar-map';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NavBar extends Component {
  @service resizeListener;
  @service currentUser;

  navItems = NavBarMap;
}
