import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionCacheService extends Service {
  @service store;

  perPage = 30;
  cachedWorkInstructions = null;
  cachedFolders = null;

  constructor() {
    super(...arguments);
    this.setupAsync.perform();
  }

  setupAsync = task(waitFor(async () => {
    this.cachedWorkInstructions = [];
    this.cachedFolders = [];

    //NOTE: this preloading doesn't do any good for work instructions in folders
    await this.queryInstructions.perform({ pageNumber: 1 });
    await this.queryFolders.perform({ pageNumber: 1 });
  }));

  queryInstructions = task({ enqueue: true }, waitFor(async query => {
    query.folder ??= 'noFolder';
    query.searchTerm ??= null;
    const { searchTerm, pageNumber, folder } = query;

    const cacheKey = camelize(searchTerm + folder + pageNumber + this.perPage);
    const cachedResult = this.cachedWorkInstructions.find(item => item.cacheKey === cacheKey);

    if (cachedResult) {
      return cachedResult;
    }

    this.cachedWorkInstructions = this.cachedWorkInstructions.filter(item => item.cacheKey !== cacheKey);

    query.perPage = this.perPage;
    const instructions = await this.store.query('workInstruction', query);

    const result = {
      cacheKey,
      searchTerm,
      folder,
      pageNumber,
      perPage: this.perPage,
      instructions: [...instructions],
      instructionCount: instructions?.meta?.count ?? 0,
    };

    this.cachedWorkInstructions.push(result);

    return result;
  }));

  queryFolders = task({ enqueue: true }, waitFor(async query => {
    query.searchTerm ??= null;
    const { searchTerm, pageNumber } = query;

    const cachedResult = this.cachedFolders.find((result) => {
      return result.searchTerm === searchTerm &&
        result.pageNumber === pageNumber &&
        result.perPage === this.perPage;
    });

    if (cachedResult) {
      return cachedResult;
    }

    query.perPage = this.perPage;
    const folders = await this.store.query('workInstructionFolder', query);

    const result = {
      searchTerm,
      pageNumber,
      perPage: this.perPage,
      folders: [...folders],
      folderCount: folders?.meta?.count ?? 0,
    };

    this.cachedFolders.push(result);

    return result;
  }));
}
