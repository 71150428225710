/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task, waitForProperty } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class InputsLockObject extends Component {
  @service imageEditor;

  onDidInsert = task(waitFor(async () => {
    await waitForProperty(this.imageEditor, 'canvas');

    this.#checkLocked();

    this.imageEditor
      .on('selection:created', this.#checkLocked)
      .on('selection:updated', this.#checkLocked);
  }));

  @action
  toggleLock(locked) {
    const objects = this.imageEditor.canvas.getActiveObjects();
    if (objects == null) {
      return;
    }

    for (const obj of objects) {
      obj.lockMovementX = locked;
      obj.lockMovementY = locked;
      obj.lockRotation = locked;
      obj.lockScalingX = locked;
      obj.lockScalingY = locked;
      obj.lockSkewingX = locked;
      obj.lockSkewingY = locked;
    }

    this.imageEditor.updateHistory();
    this.args.onChange(locked);
  }

  #checkLocked = () => {
    const obj = this.imageEditor.canvas.getActiveObject();

    if (obj == null) {
      return;
    }

    const locked =
      obj.lockMovementX &&
      obj.lockMovementY &&
      obj.lockRotation &&
      obj.lockScalingX &&
      obj.lockScalingY &&
      obj.lockSkewingX &&
      obj.lockSkewingY;

    this.args.onChange(locked);
  };

  willDestroy() {
    super.willDestroy(...arguments);
    this.imageEditor
      .off('selection:created', this.#checkLocked)
      .off('selection:updated', this.#checkLocked);
  }
}
