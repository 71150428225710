/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { toTree } from 'eflex/util/tree-helpers';

export default class BiPanelLocations extends Component {
  @service areaRepo;

  get treeData() {
    return this.areaRepo.areas.map(area => toTree(area, { lastModel: 'station' }));
  }
}
