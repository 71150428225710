/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TaskStatuses from 'eflex/constants/task-statuses';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';

class JemActions extends Component {
  @tracked showScrapModal = false;
  @tracked showScrapCodeModal = false;
  @tracked showStopModal = false;
  @tracked showRejectModal = false;

  @action
  onScrap() {
    if (this.args.station.confirmScrapCode) {
      this.showScrapCodeModal = true;
      return;
    }

    this.showScrapModal = true;
  }

  @action
  onReject() {
    if (this.args.station.confirmRejects) {
      this.showRejectModal = true;
      return;
    }

    return this.args.setStatusAndSave(TaskStatuses.REJECTED);
  }

  onConfirmScrap = task({ drop: true }, waitFor(async () => {
    await this.args.setStatusAndSave(TaskStatuses.SCRAPPED);
    this.showScrapModal = false;
  }));

  onConfirmScrapCode = task({ drop: true }, waitFor(async codes => {
    await this.args.setStatusAndSave(TaskStatuses.SCRAPPED, codes);
    this.showScrapCodeModal = false;
  }));

  onConfirmStop = task({ drop: true }, waitFor(async () => {
    await this.args.setStatusAndSave(TaskStatuses.STOPPED);
    this.showStopModal = false;
  }));

  onRejectConfirm = task({ drop: true }, waitFor(async (codes) => {
    await this.args.setStatusAndSave(TaskStatuses.REJECTED, codes);
    this.showRejectModal = false;
  }));
}

export default JemActions;
