/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { sortByProp } from 'ramda-adjunct';

export default class ModelContextSelector extends Component {
  @service defaultTaskConfigContext;

  get filteredModels() {
    const options = sortByProp('code', this.args.treeItem.area.models ?? []);

    if (this.args.allowAll) {
      options.unshift(this.defaultTaskConfigContext.getContext(this.args.treeItem));
    }

    return options;
  }

  get selectedOption() {
    if (this.args.selectedContext.allSelected) {
      return this.filteredModels[0];
    } else {
      return this.args.selectedContext;
    }
  }
}
