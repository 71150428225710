/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class KineticWorkQueueModal extends Component {
  @service store;
  @service notifier;
  @service eventBus;

  @tracked assemblyOps = new TrackedArray();

  onDidInsert = task(waitFor(async () => {
    try {
      const assemblyOps = await this._getData.perform();

      this.assemblyOps = new TrackedArray(assemblyOps);

      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    }
  }));

  fetch = task(waitFor(async (params, pagination) => {
    const assemblyOps = await this._getData.perform({ ...params, ...pagination });
    this.assemblyOps.push(...assemblyOps);
  }));

  _getData = task(waitFor(async (params = {}) => {
    return new TrackedArray(await this.store.query('kineticAssemblyOperation', {
      skip: 0,
      take: PAGE_SIZE,
      ...params,
    }));
  }));

  @action
  onOperationSelected(assemblyOperation) {
    this.args.onCancel();
    this.args.onSubmitOperation(assemblyOperation);
  }
}
