import BomConfig from 'eflex/models/bom-config';
import { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import nonUnique from 'eflex/util/non-unique';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { isEmpty } from '@ember/utils';
import { sortByProp } from 'ramda-adjunct';
import { without, reject, concat, pipe, filter, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import BomConfigValidations from 'eflex/util/bom-config-validations';
import { buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({ ...BomConfigValidations })
class EflexComponent extends BomConfig {
  @attr('boolean') isAlwaysRun;
  @attr('boolean', { defaultValue: false }) isComplex;

  @hasMany('task', { async: false, inverse: 'component' }) tasks;
  @hasMany('componentOption', { async: false, polymorphic: true, inverse: 'component', embedded: true }) options;

  @service intl;

  get isComponent() {
    return true;
  }

  get isDirty() {
    return super.isDirty || this.options.some(item => item.isDirty);
  }

  get nonDeletedOptions() {
    return this.options.filter(item => !item.isDeleted);
  }

  get simpleOptions() {
    return this.nonDeletedOptions.filter(item => item.isSimple);
  }

  get complexOptions() {
    return this.nonDeletedOptions.filter(item => !item.isSimple);
  }

  get invalidOptions() {
    return anyInvalid(this.options);
  }

  get validatedRelationships() {
    return ['options'];
  }

  get peers() {
    const components = this.isComplex ? this.bomSource?.complexComponents : this.bomSource?.simpleComponents;

    return intoArray(
      without([this]),
      reject(prop('isAlwaysRun')),
      reject(prop('isDeleted')),
    )(components ?? []);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.invalidOptions;
  }

  get disabled() {
    return isEmpty(this.options);
  }

  get duplicateOptionValues() {
    return nonUnique(this.simpleOptions, 'value', true);
  }

  get displayName() {
    if (this.isAlwaysRun) {
      return this.intl.t('plant.area.components.alwaysRun');
    } else {
      return this.name;
    }
  }

  get availableOptions() {
    return concat(
      [{ name: this.intl.t('noConfiguration') }],
      pipe(
        filter(prop('isSimple')),
        sortByProp('priority'),
      )(this.options ?? []),
    );
  }
}

export default EflexComponent;
