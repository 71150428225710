/* import __COLOCATED_TEMPLATE__ from './main-header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

class MainHeader extends Component {
  @service resizeListener;
  @service licensing;
  @service session;
  @service currentUser;

  @action
  toggleNav() {
    if (this.resizeListener.navBarCollapsed) {
      this.resizeListener.openNavBar();
      this.resizeListener.triggerResize();
    } else {
      this.resizeListener.collapseNavBar();
      this.resizeListener.triggerResize();
    }
  }
}

export default MainHeader;
