/* import __COLOCATED_TEMPLATE__ from './date-range.hbs'; */
import moment from 'moment-timezone';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CriteriaVisorDateRange extends Component {
  @service intl;

  @tracked isDisplayed = this.args.beginDate || this.args.endDate;
  @tracked selectedRange;

  constructor() {
    super(...arguments);
    this.args.initParam('beginDate', this.beginDate?.toJSON());
    this.args.initParam('endDate', this.endDate?.toJSON());
  }

  get beginDate() {
    return this.args.beginDate ? new Date(this.args.beginDate) : null;
  }

  get endDate() {
    return this.args.endDate ? new Date(this.args.endDate) : null;
  }

  get beginDateBreadcrumb() {
    const beginDate = this.beginDate;
    if (!beginDate) {
      return null;
    }
    return this.intl.t('breadcrumbs.beginDate', { beginDate: moment(beginDate).format('lll') });
  }

  get endDateBreadcrumb() {
    const endDate = this.endDate;
    if (!endDate) {
      return null;
    }

    return this.intl.t('breadcrumbs.endDate', { endDate: moment(endDate).format('lll') });
  }

  dateRangeOptions = [
    {
      label: 'datetime.today',
      value: 'today',
      beginDate: moment().startOf('day').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.yesterday',
      value: 'yesterday',
      beginDate: moment().subtract(1, 'days').startOf('day').toDate(),
      endDate: moment().subtract(1, 'days').endOf('day').toDate(),
    },
    {
      label: 'datetime.lastweek',
      value: 'week',
      beginDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.lastmonth',
      value: 'month',
      beginDate: moment().subtract(1, 'months').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
    {
      label: 'datetime.lastquarter',
      value: 'quarter',
      beginDate: moment().subtract(3, 'months').toDate(),
      endDate: moment().add(1, 'hours').toDate(),
    },
  ];

  @action
  onBeginDateChange(beginDate) {
    this.args.updateParam('beginDate', beginDate?.toJSON());
  }

  @action
  onEndDateChange(endDate) {
    this.args.updateParam('endDate', endDate?.toJSON());
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.selectedRange = null;
    this.onBeginDateChange(null);
    this.onEndDateChange(null);
    this.isDisplayed = isDisplayed;
  }

  @action
  onSelectedRange(range) {
    this.selectedRange = range;
    this.onBeginDateChange(range?.beginDate);
    this.onEndDateChange(range?.endDate);
  }
}
