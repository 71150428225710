/* import __COLOCATED_TEMPLATE__ from './schedule.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import weekdays from 'eflex/constants/weekdays';

export default class SchedulePanel extends Component {
  @service scheduleRepo;
  @service store;

  get weekdays() {
    return weekdays;
  }

  @action
  updateStartDate(date) {
    this.scheduleRepo.updateDate(this.args.schedule, date);
  }

  @action
  updateEndDate(date) {
    this.scheduleRepo.updateDate(this.args.schedule, date, true);
  }

  @action
  updateStations(stations = []) {
    const stationRecords = stations.map((station) => {
      return this.store.peekRecord('station', station.id);
    });

    this.args.schedule.stations = stationRecords;
  }

  @action
  updateTime(day, type, time) {
    if (!time) {
      day[`${type}Hours`] = null;
      day[`${type}Minutes`] = null;
      return;
    }

    time = moment.tz(time, day.schedule.timezone);
    day[`${type}Hours`] = time.hours();
    day[`${type}Minutes`] = time.minutes();
  }

}
