import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isBlank, isPresent } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { checkIpUnique } from 'eflex/util/validators';
import { validIpAddress } from 'eflex/constants/regex';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],

  ipAddress: [
    validator('format', { regex: validIpAddress }),
    validator('inline', {
      validate(ipAddress, options, openProtocolTool) {
        return checkIpUnique(
          openProtocolTool,
          getOwner(openProtocolTool).lookup('service:hardwareRepo').openProtocolTools,
        );
      },
    }),
  ],

  port: [
    validator('presence', true),
    validator('inline', {
      validate(port, options, openProtocolTool) {
        return checkIpUnique(
          openProtocolTool,
          getOwner(openProtocolTool).lookup('service:hardwareRepo').openProtocolTools,
        );
      },
    }),
  ],

  version: [validator('presence', true)],

  revision: [validator('presence', true), validator('length', { is: 3 })],
})
class OpenProtocolTool extends Hardware {
  @service intl;

  @attr('string') ipAddress;
  @attr('number', { defaultValue: 1 }) version;
  @attr('boolean', { defaultValue: false }) isMismatch;
  @attr('number', { defaultValue: 4545 }) port;
  @attr('string', { defaultValue: '001' }) revision;
  @attr('string') hardwareSerialNumber;
  @attr('date') calibrationDate;
  @attr('date') calibrationExpirationDate;
  @attr('number') warningThresholdDays;

  get isConfigured() {
    return isPresent(this.ipAddress);
  }

  get fullAddress() {
    if (isBlank(this.ipAddress) || isBlank(this.port)) {
      return null;
    }

    return `${this.ipAddress}:${this.port}`;
  }

  get hardwareGroup() {
    return this.intl.t('torqueTool');
  }
}

export default OpenProtocolTool;
