/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CollapseToggle extends Component {
  @tracked isCollapsed = false;

  @service resizeListener;

  get collapsedClass() {
    const direction = this.args.direction ?? 'left';

    switch (true) {
      case direction === 'left' && this.isCollapsed: {
        return 'panel-arrow-right';
      }
      case direction === 'left' && !this.isCollapsed: {
        return 'panel-arrow-left';
      }
      case direction === 'right' && this.isCollapsed: {
        return 'panel-arrow-left';
      }
      default: {
        return 'panel-arrow-right';
      }
    }
  }

  @action
  onClick() {
    this.isCollapsed = !this.isCollapsed;
    this.args.onToggle?.(this.isCollapsed);
    this.resizeListener.triggerResize();
  }
}
