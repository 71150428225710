/* import __COLOCATED_TEMPLATE__ from './oee-state.hbs'; */
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

class JemOeeState extends Component {
  @service store;
  @service oeeStateRepo;

  get oeeActiveState() {
    return this.oeeStateRepo.oeeStates.find(oeeState =>
      oeeState.stationId === this.args.station.id &&
      oeeState.endDate == null &&
      !oeeState.isCustom,
    )?.state;
  }

  loadOeeStates = task(waitFor(async (elem, [station]) => {
    this.store.unloadAll('oeeState');
    await this.store.query('oeeState', { stationId: station.id });
  }));

  @action
  onOeeStateUpdate(state) {
    this.store.push(this.store.normalize('oeeState', state));
  }

  onShiftUpdate = task(waitFor(async () => {
    this.store.unloadAll('oeeState');
    await this.store.query('oeeState', { stationId: this.args.station.id });
  }));
}

export default JemOeeState;
