/* import __COLOCATED_TEMPLATE__ from './spindle.hbs'; */
import Component from '@glimmer/component';

export default class JemSpindle extends Component {
  get angleIconClass() {
    return this._getStatusIconClass(this.args.spindle?.angleStatus);
  }

  get angleClass() {
    return this._getStatusClass(this.args.spindle?.angleStatus);
  }

  get torqueIconClass() {
    return this._getStatusIconClass(this.args.spindle?.torqueStatus);
  }

  get torqueClass() {
    return this._getStatusClass(this.args.spindle?.torqueStatus);
  }

  _getStatusClass(status) {
    if (status == null) {
      return null;
    }

    if (status === 1 && this.args.spindle?.errorCode == null) {
      return 'jem-text-success';
    } else {
      return 'jem-text-danger';
    }
  }

  _getStatusIconClass(status) {
    if (status == null) {
      return null;
    }

    if (status === 1 && this.args.spindle?.errorCode == null) {
      return 'icon-checkmark';
    } else {
      return 'icon-x';
    }
  }
}
