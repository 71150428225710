/* import __COLOCATED_TEMPLATE__ from './model-dropdown.hbs'; */
import Component from '@glimmer/component';
import { sortByProp } from 'ramda-adjunct';

export default class JemModelDropdown extends Component {
  // don't use composable helpers sort since it uses a different sorting algorithm that could be different than the model context selector
  get sortedModels() {
    return sortByProp('code', this.args.models ?? []);
  }
}
