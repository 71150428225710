import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { without, prop, reject, chain, map, uniq } from 'ramda';
import { compact } from 'ramda-adjunct';
import { getNextPriority } from 'eflex/util/component-priority-helpers';

const getFlattenedComponentReferences = (rules, references = []) => {
  if (!isEmpty(rules)) {
    references = references.concat(rules);
    const newRules = rules.flatMap(rule => rule.rules);
    return getFlattenedComponentReferences(newRules, references);
  } else {
    return references;
  }
};

export default class ComponentRepoService extends Service {
  @service store;
  @service taskRepo;

  addSimpleOption(component) {
    const priority = getNextPriority(component.simpleOptions);
    const option = this.store.createRecord('simpleComponentOption', {
      component,
      priority,
    });

    component.tasks.forEach(taskRecord => {
      this.taskRepo.createConfig(taskRecord, option);
    });

    return option;
  }

  addComplexOption(component) {
    const priority = getNextPriority(component.complexOptions);
    const option = this.store.createRecord('complexComponentOption', {
      component,
      condition: 'OR',
      priority,
    });

    component.tasks.forEach(taskRecord => {
      this.taskRepo.createConfig(taskRecord, option);
    });

    return option;
  }

  delete(config, components) {
    if (config == null) {
      return;
    }

    const references = this._checkReferences(config, components);

    if (isPresent(references)) {
      return intoArray(
        uniq,
        map(reference => ({
          subject: config.name,
          component: reference.rootRule?.comparedComponent?.name,
          componentOption: reference.rootRule?.parent?.name,
        })),
      )(references);
    }

    config.peers
      .filter((sibling) => sibling.priority > config.priority)
      .forEach(sibling => { sibling.priority = sibling.priority - 1; });

    config.deleteRecord();
  }

  _checkReferences(config, components) {
    let field;

    switch (config.constructor.modelName) {
      case 'component': {
        field = 'component';
        break;
      }
      case 'simple-component-option':
      case 'complex-component-option': {
        field = 'componentOption';
        break;
      }
      default: {
        return;
      }
    }

    components = components.filter(item => !item.isDeleted);
    if (field === 'component') {
      components = without([config], components);
    }

    let references = this._getAllComponentReferences(components).filter(item => item[field] === config);

    if (field === 'componentOption') {
      references = without([config], references);
    }

    return references;
  }

  _getAllComponentReferences(components) {
    const rules = intoArray(
      chain(prop('options')),
      reject(prop('isDeleted')),
      chain(prop('rules')),
      compact,
      reject(prop('isDeleted')),
    )(components);

    return getFlattenedComponentReferences(rules);
  }
}
