/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import $ from 'jquery';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { without, pluck, zipObj } from 'ramda';
import { registerDestructor } from '@ember/destroyable';

let loadedJqueryQueryBuilder = false;

const createRecordFromRule = (rule, parent, options, store) => {
  const componentOption = options.find(item => item.id === rule.value);

  const newRule = store.createRecord('complexComponentOption', {
    parent,
    componentOption,
    comparedComponent: componentOption?.component,
  });

  if (rule.condition) {
    newRule.condition = rule.condition;
  }

  if (rule.operator) {
    newRule.operatorName = rule.operator;
  }

  rule.rules?.forEach(nestedRule => {
    createRecordFromRule(nestedRule, newRule, options, store);
  });
};

export default class ComponentComplexOptionBuilder extends Component {
  @service store;
  @service notifier;

  #queryBuilder;

  loadQueryBuilder = task(waitFor(async element => {
    if (!loadedJqueryQueryBuilder) {
      await import('jQuery-QueryBuilder');
      loadedJqueryQueryBuilder = true;
    }

    const rules = this.args.selectedOption.toRulesObject();
    rules.rules ??= []; // ensure top level has rules

    // eslint-disable-next-line ember/no-jquery
    $(element).queryBuilder({
      rules,
      default_condition: this.args.selectedOption.condition,
      allow_empty: true,
      allow_invalid: true,
      filters: this.args.components.map(component => {
        const options = without([this.args.selectedOption], component.options);

        return {
          id: component.id,
          label: component.name,
          type: 'string',
          input: 'select',
          operators: ['equal', 'not_equal'],
          values: zipObj(
            pluck('id', options),
            pluck('name', options),
          ),
        };
      }),
    });

    registerDestructor(this, () => {
      element.queryBuilder.destroy();
    });

    this.#queryBuilder = element.queryBuilder;
  }));

  @action
  updateComplexOption() {
    const queryBuilder = this.#queryBuilder;

    if (!queryBuilder.validate()) {
      this.notifier.sendError('components.options.invalidComplexOption');
      return;
    }

    const updated = queryBuilder.getRules({ allow_invalid: true });
    const allOptions = this.args.components.flatMap(component => component.options);

    const selectedOption = this.args.selectedOption;

    selectedOption.condition = updated.condition;
    selectedOption.rules.forEach(rule => { rule.deleteRecord(); });

    updated.rules.forEach(rule => {
      createRecordFromRule(rule, selectedOption, allOptions, this.store);
    });

    this.args.onConfirm?.(selectedOption);
  }
}
