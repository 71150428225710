/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class RejectCodesByStation extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  getData = task({ drop: true }, waitFor(async params => {
    const rejectOccurrenceQuery = await this.queryRunner.queryWithParams.perform(params, [
      {
        $match: {
          status: TaskStatuses.REJECTED,
        },
      },
      {
        $unwind: {
          path: '$rejectCodes',
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $unwind: {
          path: '$rejectCodes.codes',
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $group: {
          _id: null,
          docs: {
            $push: '$$ROOT',
          },
          distinctCodes: {
            $addToSet: {
              $ifNull: ['$rejectCodes.codes.code', 'other'],
            },
          },
        },
      },
      {
        $unwind: {
          path: '$docs',
        },
      },
      {
        $addFields: {
          'docs.distinctCodes': '$distinctCodes',
        },
      },
      {
        $replaceRoot: {
          newRoot: '$docs',
        },
      },
      {
        $group: {
          _id: {
            stationId: '$location._id',
            stationName: {
              $first: '$location.captions.text',
            },
            distinctCodes: '$distinctCodes',
            code: {
              $ifNull: ['$rejectCodes.codes.code', 'other'],
            },
          },
          count: {
            $sum: 1,
          },
        },
      },
      {
        $group: {
          _id: {
            stationId: '$_id.stationId',
            stationName: '$_id.stationName',
            distinctCodes: '$_id.distinctCodes',
          },
          codes: {
            $push: {
              code: '$_id.code',
              count: '$count',
            },
          },
        },
      },
      {
        $addFields: {
          missingCodes: {
            $map: {
              input: '$_id.distinctCodes',
              in: {
                $cond: {
                  if: {
                    $in: ['$$this', '$codes.code'],
                  },
                  then: '$$REMOVE',
                  else: {
                    code: '$$this',
                    count: 0,
                  },
                },
              },
            },
          },
        },
      },
      {
        $addFields: {
          allCodeCounts: {
            $concatArrays: ['$codes', '$missingCodes'],
          },
        },
      },
      {
        $addFields: {
          allCodeCounts: {
            $filter: {
              input: '$allCodeCounts',
              cond: {
                $ne: ['$$this', null],
              },
            },
          },
        },
      },
      {
        $project: {
          _id: 0,
          station: '$_id.stationName',
          totalRejects: {
            $sum: {
              $map: {
                input: '$allCodeCounts',
                in: '$$this.count',
              },
            },
          },
          distinctCodes: {
            $sortArray: {
              input: '$_id.distinctCodes',
              sortBy: 1,
            },
          },
          allCodeCounts: {
            $sortArray: {
              input: '$allCodeCounts',
              sortBy: {
                code: 1,
              },
            },
          },
        },
      },
      {
        $project: {
          station: 1,
          totalRejects: 1,
          distinctCodes: 1,
          allCodeCounts: {
            $map: {
              input: '$allCodeCounts',
              in: '$$this.count',
            },
          },
        },
      },
      {
        $sort: {
          totalRejects: 1,
        },
      },
    ]);

    const rejectOccurrenceData = await this.queryRunner.runQuery.perform('BuildStatuses', rejectOccurrenceQuery);
    return {
      chartData: this.getEchartOptions(rejectOccurrenceData),
      exportData: () => this.formatExportData(clone(rejectOccurrenceData)),
    };
    // jscpd:ignore-end
  }));

  rejectOccurrenceData = trackedTask(this, this.getData, () => [this.params]);

  getEchartOptions(formattedData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.rejectCodeOccurrencesStation'),
      },

      legend: {
        show: true,
        bottom: 0,
      },

      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          let result = '';
          params.forEach(({ marker, seriesName, value, seriesIndex }) => {
            const itemValue = value[seriesIndex + 1];
            if (itemValue && itemValue !== 0) {
              result += `<div>${marker} ${seriesName}<span style="padding-right: 10px;">:</span>
              <span style="float:right;font-weight:bold;">${itemValue}</span></div>`;
            }
          });
          return `<div>${params[0].name}</div>${result}`;
        },
      },

      dataset: this.formatDataSet(formattedData),

      yAxis: {
        type: 'category',
        boundaryGap: true,
        interval: 1,
      },

      xAxis: {},

      series: this.formatSeriesData(formattedData),
    };
  }

  formatDataSet = (rejectOccurrenceData) => {
    const headers = ['station'].concat(rejectOccurrenceData?.[0]?.distinctCodes);
    const source = [headers];

    rejectOccurrenceData.forEach((station) => {
      source.push([station.station].concat(station.allCodeCounts));
    });

    return {
      source,
    };
  };

  formatSeriesData = (rejectOccurrenceData) => {
    const seriesData = [];
    rejectOccurrenceData?.[0]?.distinctCodes?.forEach((code, index) => {
      seriesData.push({
        name: code,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: (params) => {
            return params?.value[params.encode.x[0]] !== 0 ? params?.value[params.encode.x[0]] : '';
          },
        },
        color: echartsTheme.excessBiColors[index],
      });
    });
    return seriesData;
  };

  formatExportData = (rejectOccurrenceData) => {
    rejectOccurrenceData?.forEach((station) => {
      station?.distinctCodes?.forEach((code, index) => {
        station[code] = station.allCodeCounts[index];
      });
      delete station.allCodeCounts;
      delete station.distinctCodes;
    });
    return rejectOccurrenceData;
  };
}
