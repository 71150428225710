/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const hasSetupRouter = false;

export default class NavItem extends Component {
  @tracked expanded = false;

  @service licensing;
  @service intl;
  @service router;
  @service resizeListener;

  constructor() {
    super(...arguments);

    if (!hasSetupRouter) {
      this.router._router.setupRouter();
    }

    if (this.args.item.children?.some(child => this.router.isActive(child.key))) {
      this.expanded = true;
    }
  }

  get label() {
    return this.intl.t(`navigation.${this.args.item.label}`);
  }

  get key() {
    return this.args.item.key;
  }

  get className() {
    return this.key?.replace(/\./, '-');
  }

  get canNav() {
    if (this.licensing.noProductsLicensed) {
      return this.key === 'settings';
    }

    if (this.args.item.lic != null) {
      return this.licensing?.license?.[`${this.args.item.lic}Enabled`];
    } else {
      return true;
    }
  }

  @action
  toggleItem() {
    this.expanded = !this.expanded;
  }
}
