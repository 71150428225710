import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { inject as service } from '@ember/service';
import { copyable } from 'eflex/decorators';
import { getOwner } from '@ember/application';

const checkValueGreaterThanThumbnails = function (value, options, cameraConfiguration) {
  if (value > cameraConfiguration.daysToStoreThumbnails) {
    return getOwner(cameraConfiguration).lookup('service:intl').t('evision.cameras.valueLessThanThumbnail');
  }
  return true;
};

@buildValidations({
  daysToStorePassedCompressed: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreFailedCompressed: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreFailedRaw: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStorePassedRaw: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', { validate: checkValueGreaterThanThumbnails }),
  ],

  daysToStoreThumbnails: [
    validator('number', { gte: 0, allowString: true, allowNone: false }),
    validator('inline', {
      validate(daysToStoreThumbnails, options, cameraConfiguration) {
        if (
          daysToStoreThumbnails < cameraConfiguration.daysToStorePassedCompressed ||
          daysToStoreThumbnails < cameraConfiguration.daysToStoreFailedCompressed ||
          daysToStoreThumbnails < cameraConfiguration.daysToStoreFailedRaw ||
          daysToStoreThumbnails < cameraConfiguration.daysToStorePassedRaw
        ) {
          return getOwner(cameraConfiguration).lookup('service:intl').t('evision.cameras.thumbnailValueTooLow');
        }

        return true;
      },
    }),
  ],
})
@copyable
class CameraConfiguration extends Model {
  @attr('number', { defaultValue: 365 }) daysToStorePassedCompressed;
  @attr('number', { defaultValue: 365 }) daysToStoreFailedCompressed;
  @attr('number', { defaultValue: 365 }) daysToStorePassedRaw;
  @attr('number', { defaultValue: 365 }) daysToStoreFailedRaw;
  @attr('number', { defaultValue: 365 }) daysToStoreThumbnails;
  @attr('boolean', { defaultValue: true }) evisionEnabled;

  @service store;

  copy() {
    return this.store.createRecord('cameraConfiguration', {
      daysToStorePassedCompressed: this.daysToStorePassedCompressed,
      daysToStoreFailedCompressed: this.daysToStoreFailedCompressed,
      daysToStorePassedRaw: this.daysToStorePassedRaw,
      daysToStoreFailedRaw: this.daysToStoreFailedRaw,
      daysToStoreThumbnails: this.daysToStoreThumbnails,
      evisionEnabled: this.evisionEnabled,
    });
  }
}

export default CameraConfiguration;
