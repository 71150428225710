import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
const JemTaskEdhrDetails = template(`
<div class="component-jem-task-schedule-details" ...attributes>
  <div class="detail-section {{@childStatus.taskStatusClass}}"></div>

  {{#if @childStatus.edhrProcessData.length}}
    <div class="jem-task-table bootstrap-table my-3 me-3 bg-white">
      <div class="row form-row header-row">
        <div class="col">{{t "data"}}</div>
        <div class="col">{{t "value"}}</div>
        <div class="col-3">{{t "result"}}</div>
      </div>
      {{#each @childStatus.edhrProcessData as |edhrDatum|}}
        <div
          class="
          row form-row edhr-datum
          {{if edhrDatum.isNoReply 'text-muted' (if edhrDatum.isOk 'jem-text-success' 'jem-text-danger')}}
          "
        >
          <div class="col text-wrap" title={{edhrDatum.formattedName}}>
            {{edhrDatum.formattedName}}
          </div>
          <div class="col text-truncate" title={{edhrDatum.value}}>
            {{edhrDatum.value}}
          </div>
          <div class="col-3 text-truncate" title={{edhrDatum.result}}>
            <div class="d-flex">
              <div
                class="
                  icon
                  {{if edhrDatum.isNoReply '' (if edhrDatum.isOk 'icon-checkmark' 'icon-x')}}
                "
              ></div>
              <div class={{if edhrDatum.isNoReply '' 'ms-2'}}>{{edhrDatum.result}}</div>
            </div>
          </div>
        </div>
      {{/each}}
    </div>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskEdhrDetails
        childStatus=@childStatus
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
