import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class ProductionSchedulerRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return this.store.query('productionSchedule', {
      isCompleted: false,
      includePartCounts: true,
    });
  }

  resetController(controller, isExiting) {
    if (!isExiting) {
      return;
    }

    Object.assign(controller, {
      showSchedulePanel: false,
      selectedStation: null,
    });
  }
}
