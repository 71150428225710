import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],
  identifier: [
    validator('presence', true),
    validator('inline', {
      validate(identifier, options, universalRobot, attribute) {
        const hardwareRepo = getOwner(universalRobot).lookup(('service:hardwareRepo'));
        const checked = hardwareRepo.universalRobots.filter(item => !item.isDeleted);
        return checkUniqueField(universalRobot, checked, attribute).result;
      },
    }),
  ],
})
class UniversalRobot extends Hardware {
  @service intl;

  @attr('string') identifier;

  get isConfigured() {
    return isPresent(this.identifier);
  }

  get hardwareGroup() {
    return this.intl.t('robot');
  }
}

export default UniversalRobot;
