import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import formatDate from 'eflex/helpers/format-date';
import formatCycleTime from 'eflex/helpers/format-cycle-time';
import { isEmpty } from '@ember/utils';
import Wildcard from 'eflex/util/wildcard';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { getCaptionText } from 'eflex/util/translation-helper';
import TaskStatuses from 'eflex/constants/task-statuses';
import { pluck } from 'ramda';

const formatTime = function (time) {
  if (time != null && time !== 0) {
    return formatDate(time);
  } else {
    return '--';
  }
};

const TimeWidth = 200;
const CycleWidth = 125;
const TimeSortFormat = 'MM/DD/YYYY h:mm:ss A';

export default class BiSerialNumberReportController extends Controller {
  @service queryRunner;
  @service store;
  @service intl;
  @service currentUser;

  @tracked selectedSerial;

  getSerials = task(waitFor(async search => {
    const query = [
      { $sort: { serialNumber: 1 } },
      {
        $group: {
          _id: '$serialNumber',
        },
      },
      { $limit: 500 },
      { $project: {
        _id: 0,
        serialNumber: '$_id',
      } },
    ];

    if (!isEmpty(search)) {
      query.unshift({
        $match: {
          serialNumber: Wildcard.convertWildcard(search),
        },
      });
    }

    const results = await this.queryRunner.runQuery.perform('BuildStatuses', query);
    return pluck('serialNumber', results);
  }));

  get tableConfiguration() {
    return {
      columns: [
        { title: this.intl.t('station'), field: 'name' },
        { title: this.intl.t('startTime'), field: 'startTime', width: TimeWidth },
        { title: this.intl.t('finishTime'), field: 'endTime', width: TimeWidth },
        {
          title: this.intl.t('cycletime'),
          field: 'cycleTime',
          width: CycleWidth,
          formatter: this.goodBadFormatter,
        },
      ],
      childConfiguration: this.childConfiguration,
    };
  }

  get childConfiguration() {
    return {
      columns: [
        { title: this.intl.t('task'), field: 'name' },
        {
          title: this.intl.t('startTime'),
          field: 'start',
          width: TimeWidth,
          sorter: 'datetime',
          sorterParams: {
            format: TimeSortFormat,
          },
        },
        {
          title: this.intl.t('finishTime'),
          field: 'end',
          width: TimeWidth,
          sorter: 'datetime',
          sorterParams: {
            format: TimeSortFormat,
          },
        },
        {
          title: this.intl.t('cycletime'),
          field: 'cycle',
          width: CycleWidth,
          formatter: this.goodBadFormatter,
        },
      ],
      childConfiguration: this.detailConfiguration,
    };
  }

  get detailConfiguration() {
    return {
      columns: [
        { title: this.intl.t('name'), field: 'datumName' },
        { title: this.intl.t('value'), field: 'datumValue' },
      ],
      options: {
        dataTree: false,
      },
    };
  }

  goodBadFormatter(cell) {
    const data = cell.getData();
    if (data.isGood) {
      cell.getElement().classList.add('good');
    } else {
      cell.getElement().classList.add('bad');
    }

    return formatCycleTime(cell.getValue());
  }

  fetchBuildStatuses = task(waitFor(async (params = {}) => {
    if (this.selectedSerial == null) {
      return;
    }

    const matchStep = {
      $match: {
        serialNumber: this.selectedSerial,
      },
    };

    let sortStep = {
      $sort: {
        timestamp: -1,
      },
    };

    if (params.sort?.[0] != null) {
      let sortField = params.sort[0].field;
      const sortDirection = params.sort[0].dir === 'asc' ? 1 : -1;

      if (sortField === 'name') {
        sortField = 'location.captions.text';
      }

      sortStep = {
        $sort: {
          [sortField]: sortDirection,
        },
      };
    }

    const countQuery = [
      matchStep,
      sortStep,
      {
        $group: {
          _id: null,
          count: { $sum: 1 },
        },
      },
    ];

    const [{ count }] = await this.queryRunner.runQuery.perform('BuildStatuses', countQuery);

    const statusQuery = [
      matchStep,
      sortStep,
      { $skip: params.skip ?? 0 },
      { $limit: params.size },
    ];

    const statuses = await this.queryRunner.runQuery.perform('BuildStatuses', statusQuery);

    const normalized = statuses.map(status => {
      return this.store.push(this.store.normalize('buildStatus', status));
    });

    const rows = normalized.map(status => ({
      id: status.id,
      name: getCaptionText(status.location?.captions, this.currentUser),
      startTime: formatTime(status.startTime),
      endTime: formatTime(status.endTime),
      cycleTime: status.cycleTime,
      isGood: TaskStatuses.isGood(status.status),
      isParentRow: true,

      children: status.children.map(child => ({
        id: child.id,
        name: getCaptionText(child.location?.captions, this.currentUser),
        start: formatTime(child.startTime),
        end: formatTime(child.endTime),
        cycle: child.cycleTime,
        isGood: TaskStatuses.isGood(child.status),
        isChildRow: true,

        children: child.processData.map(datum => ({
          datumName: datum.name,
          datumValue: datum.displayValue,
        })),
      })),
    }));

    return {
      count,
      data: rows,
    };
  }));
}
