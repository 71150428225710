import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { task, all } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class ContainPartsController extends Controller {
  @service store;
  @service intl;
  @service router;
  @service currentUser;

  queryParams = ['serialNumber', 'campaign'];

  @tracked serialNumber;
  @tracked campaign;
  jemCampaigns = this.store.peekAll('jemCampaign');
  jemContainments = this.store.peekAll('jemContainment');

  get dirtyCampaigns() {
    return this.jemCampaigns?.filter(item => item.isDirty) ?? [];
  }

  get selected() {
    return this.jemContainments?.filter(item => item.isSelected) ?? [];
  }

  get dirtyContainments() {
    return this.jemContainments?.filter(item => item.isDirty) ?? [];
  }

  get displayedContainments() {
    if (this.campaign) {
      return this.jemContainments
        ?.filter(item => !item.scrap)
        ?.filter(item => !item.isDeleted)
        .filter(item => item.campaign === this.campaign) ?? [];
    } else {
      return this.jemContainments
        ?.filter(item => !item.scrap)
        ?.filter(item => !item.isDeleted) ?? [];
    }
  }

  get isDirty() {
    return !isEmpty(this.dirtyCampaigns) || !isEmpty(this.dirtyContainments);
  }

  get nonDeletedJemContainments() {
    return this.jemContainments.filter(item => !item.isDeleted);
  }

  get isInvalid() {
    return this.nonDeletedJemContainments.some(item => item.hasError || item.isInvalid);
  }

  save = task(waitFor(async () => {
    if (this.isInvalid) {
      return;
    }

    await all(this.dirtyCampaigns.map(dirtyCampaign => dirtyCampaign.save()));
    await all(this.dirtyContainments.map(dirtyContainment => dirtyContainment.save()));
  }));

  @action
  campaignAddText(term) {
    return `${this.intl.t('add')} ${term}`;
  }

  @action
  showCreateOption(term) {
    return !this.jemCampaigns.some(item => item.name === term);
  }

  @action
  updateCampaign(campaign) {
    const name = campaign.isDefaultOption != null ? null : campaign.name;
    this.campaign = name;
  }

  @action
  createCampaign(containment, name) {
    const campaign = this.store.createRecord('jemCampaign', { name });
    this.addCampaign(containment, campaign);
  }

  @action
  addCampaign(containment, { name }) {
    containment.campaign = name;
  }

  @action
  addContainment() {
    this.store.createRecord('jemContainment', {
      userName: this.currentUser.user.userName,
      campaign: this.campaign,
    });
  }

  @action
  rollback() {
    this.jemContainments.forEach(jemContainment => { jemContainment.rollbackAttributes(); });
    this.jemCampaigns.forEach(jemCampaign => { jemCampaign.rollbackAttributes(); });
  }

  @action
  uncontain() {
    this.selected.forEach(selected => { selected.deleteRecord(); });
  }

  @action
  searchSerial() {
    this.store.unloadAll('buildStatus');
    this.store.unloadAll('jemContainment');
    this.router.refresh('containParts');
  }
}
