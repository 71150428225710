/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

class KineticCopyOperationModal extends Component {
  @tracked selectedPart;
  @tracked selectedPartRev;
  @tracked selectedOperation;

  @service kineticRepo;

  @action
  selectPart(part) {
    Object.assign(this, {
      selectedPart: part,
      selectedPartRev: null,
      selectedOperation: null,
    });
  }

  @action
  selectPartRev(partRev) {
    Object.assign(this, {
      selectedPartRev: partRev,
      selectedOperation: null,
    });
  }
}

export default KineticCopyOperationModal;
