import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { checkUniqueField } from 'eflex/util/validators';
import { getOwner } from '@ember/application';

@buildValidations({
  name: [validator('presence', true)],

  clientId: [
    validator('inline', {
      validate(clientId, options, nodeRed, attribute) {
        const checked = getOwner(nodeRed).lookup('service:hardwareRepo').nodeReds.filter(item => !item.isDeleted);
        return checkUniqueField(nodeRed, checked, attribute).result;
      },
    }),
    validator('presence', true),
  ],
})
class NodeRed extends Hardware {
  @attr('string') clientId;
  @attr('string') url;

  @service intl;

  get isConfigured() {
    return isPresent('clientId');
  }

  get hardwareGroup() {
    return this.intl.t('nodeRed');
  }
}

export default NodeRed;
