import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SerialNumberMonitorController extends Controller {
  @service bomSourceRepo;
  @service router;

  @controller('serialNumberMonitor/bomSource') serialNumberMonitorBomSourceController;

  @action
  bomSourceSelected(bomSource) {
    this.router.transitionTo('serialNumberMonitor.bomSource', bomSource.id);
  }
}
