import Model, { attr } from '@ember-data/model';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  username: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),

  state: [validator('inclusion', { in: WieSettings.approvalStates })],
})
class WorkInstructionApproveReject extends Model {
  @attr('string') username;
  @attr('string') state;
  @attr('string') notes;

  @service userRepo;

  get displayUsername() {
    return this.userRepo.users.find(item => item.userName === this.username)?.displayUsername;
  }

  approve() {
    this.state = WieSettings.approvalStates.APPROVED;
  }

  reject() {
    this.state = WieSettings.approvalStates.REJECTED;
  }
}

export default WorkInstructionApproveReject;
