import { fabric } from 'fabric';
import { guidFor } from '@ember/object/internals';
import { all, task, taskGroup, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { ControlStyles, TextTypes, EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { compare, applyPatch } from 'fast-json-patch';
import { ObjectId } from 'bson';
import Service, { inject as service } from '@ember/service';
import {
  initializeCanvas, cleanupCanvas, getCanvasObjects,
  getCanvasDefaults, getAllCanvasImages, toGlobalPoint,
  getCanvasObjectByPoint, getLineParts,
} from 'eflex/util/fabric-helpers';
import toFastProperties from 'to-fast-properties';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import Evented from '@ember/object/evented';
import { waitFor } from '@ember/test-waiters';
import { filter, find, forEach, pipe, collectBy, drop, clone } from 'ramda';
import { dp } from '@eflexsystems/ramda-helpers';
import { TrackedArray } from 'tracked-built-ins';

const CLIPBOARD_CANVAS_IDENTIFIER = 'CANVAS';
const SET_ACTIVE_OBJECT_DELAY_TIME = 10;
const UPDATE_HISTORY_DELAY_TIME = 200;
const GRID_SIZE = 25;
const GRID_IMAGE = '/images/wie-grid.png';
const ArrowHead = {
  HEIGHT: 12,
  WIDTH: 12,
};

const MAX_HISTORY = 30;

export default class ImageEditor extends Service.extend(Evented) {
  @taskGroup({ enqueue: true }) editorChanges;

  @service workInstructionImageRepo;

  @tracked inToolMode = false;
  @tracked _clipboard = null;
  @tracked _undoStack = new TrackedArray();
  @tracked _redoStack = new TrackedArray();
  @tracked showGridBorderRight = true;
  @tracked showGridBorderBottom = true;
  @tracked canvas;

  _isHistoryActive = true;
  isGrouping = false;
  _previousJson;

  get isHistoryActive() {
    if (this._updateDiffs.isRunning) {
      return false;
    }

    return this._isHistoryActive;
  }

  set isHistoryActive(val) {
    this._isHistoryActive = val;
  }

  get canUndo() {
    return this._undoStack?.length > 0;
  }

  get canRedo() {
    return this._redoStack?.length > 0;
  }

  get _propertiesToInclude() {
    return [
      'id',
      'eflex',
      'hasBorders',
      'hasControls',
      'selectable',
      'lockScalingX',
      'lockScalingY',
      'lockMovementX',
      'lockMovementY',
      'lockSkewingX',
      'lockSkewingY',
      'lockRotation',
      'perPixelTargetFind',
      'backgroundImage',
    ];
  }

  undo = task({ group: 'editorChanges' }, waitFor(async () => {
    if (!this.canUndo) {
      return;
    }
    await this._updateDiffs.perform('_undoStack', '_redoStack');
  }));

  redo = task({ group: 'editorChanges' }, waitFor(async () => {
    if (!this.canRedo) {
      return;
    }
    await this._updateDiffs.perform('_redoStack', '_undoStack');
  }));

  loadCanvasJson = task(waitFor(async json => {
    this.disableHistory();

    await new Promise((resolve) => {
      this.canvas.loadFromJSON(json, resolve);
    });

    this._previousJson = this.getCanvasJson();
    this.enableHistory();
    this.trigger('wie:canvas:loaded');
  }));

  addImage = task(waitFor(async (imgUrl, options) => {
    options.perPixelTargetFind = true;

    let image = await new Promise((resolve) => {
      fabric.Image.fromURL(imgUrl, resolve, { crossOrigin: 'Anonymous' });
    });

    if (!image?.getSrc()) {
      return;
    }

    image = this.#fitImageToCanvas(image);
    if (options) {
      for (const key of Object.keys(options)) {
        image[key] = options[key];
      }

      this.canvas.renderAll();
    }

    if (options?.left == null && options?.top == null) {
      this.canvas.centerObject(image);
    }

    this.applyItemKey(image);
    this.canvas.add(image).setActiveObject(image);
    return image.toJSON(this._propertiesToInclude);
  }));

  addBackgroundImage = task(waitFor(async (imgUrl, options) => {
    let image = await new Promise((resolve) => {
      fabric.Image.fromURL(imgUrl, resolve, { crossOrigin: 'Anonymous' });
    });

    if (!image?.getSrc()) {
      return;
    }

    image = this.#fitImageToCanvas(image, true);

    if (options) {
      for (const key of Object.keys(options)) {
        image[key] = options[key];
      }

      this.canvas.renderAll();
    }

    if (options?.left == null && options?.top == null) {
      this.canvas.centerObject(image);
    }

    this.canvas.setBackgroundImage(image);
    this.canvas.renderAll();

    this.updateHistory();

    return image.toJSON(this._propertiesToInclude);
  }));

  addIcon = task({}, waitFor(async (icon, options) => {
    const { height, width } = this.#determineIconHeightWidth(icon);

    options.originX = 'center';
    options.originY = 'center';
    const cloneObj = await new Promise((resolve) => {
      icon.group.clone(resolve);
    });

    const { height: grpY, width: grpX } = cloneObj;
    options.scaleX = width / grpX;
    options.scaleY = height / grpY;

    cloneObj.eflex = options.eflex ?? {};
    cloneObj.eflex.type = EflexObjTypes.ICON;

    this.canvas.add(cloneObj);
    this.canvas.setActiveObject(cloneObj);

    if (cloneObj.type === 'group') {
      cloneObj.forEachObject(function (obj) {
        const newOpts = {};
        if (!obj.fill) {
          newOpts.stroke = options.fill;
        }
        if (!obj.stroke) {
          newOpts.fill = options.fill;
        }
        obj.set(newOpts);
      });
    }

    if (options != null) {
      options.eflex.type = EflexObjTypes.ICON;
    }

    cloneObj.set(options).setCoords();
    this.canvas.renderAll();

    return cloneObj;
  }));

  toggleGrid = task({ enqueue: true }, waitFor(async () => {
    if (this.canvas.overlayImage != null) {
      this.disableGrid();
    } else {
      await this.enableGrid.perform();
    }
  }));

  enableGrid = task(waitFor(async () => {
    const gridImage = await new Promise((resolve) => {
      fabric.util.loadImage(GRID_IMAGE, resolve);
    });

    const grid = new fabric.Rect({
      width: this.canvas.width,
      height: this.canvas.height,
      strokeWidth: 0, // Keep the image crisp
      excludeFromExport: true,
    });

    grid.set(
      'fill',
      new fabric.Pattern({
        source: gridImage,
        repeat: 'repeat',
      }),
    );

    this.canvas.overlayImage = grid;
    this.canvas.renderAll();
  }));

  getImageCanvasJson = task(waitFor(async image => {
    const response = await this.workInstructionImageRepo.saveWieImage.perform(image.src, {
      type: WieSettings.imageTypes.ASSET,
      thumbnail: true,
    });

    return {
      background: getCanvasDefaults().backgroundColor,
      objects: [
        {
          type: 'image',
          originX: 'left',
          originY: 'top',
          left: 0,
          top: 0,
          crossOrigin: 'Anonymous',
          src: this.workInstructionImageRepo.createVersionedImageUrl(response.image.id),
          eflex: {
            id: response.image.id,
          },
        },
      ],
    };
  }));

  saveCanvasImages = task(waitFor(async fromSaveAs => {
    const imageObjects = getAllCanvasImages(this.canvas);
    if (this.canvas.backgroundImage != null && !this.canvas.backgroundImage?.eflex.isExternal) {
      imageObjects.push(this.canvas.backgroundImage);
    }

    await all(imageObjects.map((img) => this._saveImage.perform(img, fromSaveAs)));
    this._previousJson = this.getCanvasJson();
    this.canvas.renderAll();
  }));

  copy = task(waitFor(async () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject == null) {
      return;
    }

    await this._setClipboard.perform(activeObject);
  }));

  cut = task(waitFor(async () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject == null) {
      return;
    }

    await this._setClipboard.perform(activeObject);
    if (activeObject.type === 'activeSelection') {
      activeObject.forEachObject((obj) => {
        this.removeObject(obj);
      });
    } else {
      this.removeObject(activeObject);
    }

    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }));

  paste = task(waitFor(async (x = null, y = null) => {
    if (!this._clipboard) {
      return;
    }

    if (x == null) {
      x = this._clipboard?.left + 10;
    }
    if (y == null) {
      y = this._clipboard?.top + 10;
    }

    const cloned = await this._cloneObject.perform(this._clipboard);
    cloned.set({ left: x, top: y });

    if (cloned.type === 'activeSelection') {
      cloned.canvas = this.canvas;
      cloned.getObjects().forEach((obj) => {
        this.canvas.add(obj);
      });
    } else if (cloned.type === 'group') {
      this.canvas.add(cloned);
    } else if (cloned.eflex?.type === EflexObjTypes.STRAIGHT_LINE) {
      this.canvas.add(cloned);
      this.#pasteLineParts(cloned);
    } else {
      this.canvas.add(cloned);
    }

    cloned.setCoords();

    this.canvas.discardActiveObject();
    this.canvas.setActiveObject(cloned);
    this.canvas.renderAll();
  }));

  _saveImage = task(waitFor(async (imgObj, fromSaveAs = false) => {
    const existingId = imgObj.eflex?.id;

    if (existingId != null && !fromSaveAs) {
      return;
    }

    const { image } =
      existingId != null && fromSaveAs
        ? (await this.workInstructionImageRepo.duplicateWieImage.perform(existingId))
        : (await this.workInstructionImageRepo.saveWieImage.perform(imgObj.getSrc(), {
          type: WieSettings.imageTypes.ASSET,
          thumbnail: false,
        }));

    imgObj.set('eflex', Object.assign(imgObj.eflex, { id: image.id }));
    const src = this.workInstructionImageRepo.createVersionedImageUrl(image.id);
    await new Promise((resolve) => {
      imgObj.setSrc(src, resolve, { crossOrigin: 'Anonymous' });
    });
  }));

  _cloneObject = task(waitFor(async obj => {
    let cloned;
    if (obj.type === 'activeSelection' || obj.type === 'group') {
      let objects = await this._cloneMultipleObjects.perform(obj.getObjects());
      objects = this.#updateItemKeys(objects);

      const cloneProperties = clone(obj.toJSON(this._propertiesToInclude));
      delete cloneProperties.id;
      cloneProperties.id = new ObjectId().toString();
      toFastProperties(cloneProperties);

      cloned = new obj.constructor(objects, cloneProperties);
      if (this.#hasLines(cloned)) {
        this.#hideControls(cloned);
      }
    } else {
      cloned = await new Promise((resolve) => {
        obj.clone(resolve, this._propertiesToInclude);
      });

      delete cloned.id;
      cloned.eflex = obj.eflex ? clone(obj.eflex) : {};

      if (cloned.type === 'image') {
        this.applyItemKey(cloned, true);
      }

      if (obj.cloneItems) {
        cloned.cloneItems = await this._cloneMultipleObjects.perform(obj.cloneItems);
      } else if (obj.eflex?.type === EflexObjTypes.STRAIGHT_LINE) {
        cloned.cloneItems = await this._cloneMultipleObjects.perform(getLineParts(this.canvas, obj));
      }

      toFastProperties(cloned);
    }

    return cloned;
  }));

  _cloneMultipleObjects = task(waitFor(async objs => {
    return await all(objs.map((obj) => this._cloneObject.perform(obj)));
  }));

  _setClipboard = task(waitFor(async obj => {
    const clip = await this._cloneObject.perform(obj);
    this._clipboard = clip;
    if (!window.isTesting) {
      navigator.clipboard.writeText(CLIPBOARD_CANVAS_IDENTIFIER);
    }
  }));

  _updateDiffs = task(waitFor(async (upProp, downProp) => {
    const currentJsonClone = clone(this._previousJson);
    const upDiff = this[upProp].pop();
    const newJson = applyPatch(this._previousJson, upDiff).newDocument;
    const downDiff = compare(newJson, currentJsonClone);

    this[downProp].push(downDiff);

    const overlay = this.canvas.overlayImage;

    await this.loadCanvasJson.perform(newJson);

    if (overlay != null) {
      this.canvas.overlayImage = overlay;
    }

    this.#triggerHistoryModified();
  }));

  #triggerHistoryModified() {
    this.trigger('wie:history:modified');
    this.#truncateDiffStack('_undoStack');
    this.#truncateDiffStack('_redoStack');
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.cleanup();
  }

  initialize(selector, options = {}) {
    if (this.canvas != null) {
      this.cleanup();
    }

    this.canvas = initializeCanvas(selector, options);
    this.showGridBorderRight = this.canvas.width % GRID_SIZE === 0;
    this.showGridBorderBottom = this.canvas.height % GRID_SIZE === 0;

    this.#addDefaultEvents();

    fabric.Object.prototype.set({
      borderColor: ControlStyles.borderColor,
      cornerColor: ControlStyles.cornerColor,
      cornerStrokeColor: ControlStyles.cornerStrokeColor,
      cornerSize: ControlStyles.cornerSize,
      cornerStyle: 'rect',
      borderScaleFactor: 3,
      transparentCorners: false,
    });

    this._previousJson = this.getCanvasJson();
    this.canvas.renderAll();
  }

  cleanup() {
    this._setActiveObjectWithDebounce.cancelAll({ resetState: true });
    this._updateHistoryDebounce.cancelAll({ resetState: true });
    this.disableHistory();

    this.canvas
      ?.off('object:modified')
      .off('object:added')
      .off('object:removed')
      .off('object:moving')
      .off('object:rotating')
      .off('object:skewing')
      .off('object:scaling')
      .off('selection:cleared')
      .off('selection:updated')
      .off('selection:created');

    cleanupCanvas(this.canvas);
    this.canvas = null;
    this._previousJson = null;
    this._undoStack = new TrackedArray();
    this._redoStack = new TrackedArray();
  }

  disableHistory() {
    this.isHistoryActive = false;
  }

  enableHistory() {
    this.isHistoryActive = true;
  }

  updateHistory() {
    if (!this.isHistoryActive) {
      return;
    }

    const newJson = this.getCanvasJson();
    toFastProperties(newJson);
    const diff = compare(newJson, this._previousJson);

    if (diff.length === 0) {
      return;
    }

    this._undoStack.push(diff);
    this._previousJson = newJson;
    this._redoStack = new TrackedArray();

    this.#triggerHistoryModified();
  }

  notifyModified(target) {
    if (!target) {
      return;
    }
    this.canvas.fire('object:modified', { target });
    this.canvas.renderAll();
  }

  getCanvasWidth() {
    return this.canvas.getWidth();
  }

  getCanvasHeight() {
    return this.canvas.getHeight();
  }

  getCanvasImageDataUrl() {
    return this.canvas.toDataURL({
      format: 'jpeg',
      quality: 0.8,
    });
  }

  getCanvasJson(includeVersion = false) {
    const canvasJson = this.canvas.toDatalessJSON(this._propertiesToInclude);
    const cloned = clone(canvasJson);

    if (!includeVersion) {
      delete cloned.version;
    }

    if (canvasJson.backgroundImage != null) {
      cloned.backgroundImage = clone(this.canvas.get('backgroundImage').toJSON(this._propertiesToInclude));
    }

    return toFastProperties(cloned);
  }

  applyItemKey(obj, reapply = false) {
    if (obj.eflex?.itemKey != null && !reapply) {
      return obj;
    }

    const itemKey = guidFor(obj) + moment.now();
    if (obj.eflex != null) {
      obj.eflex.itemKey = itemKey;
    } else {
      obj.eflex = { itemKey };
    }

    return obj;
  }

  updateTextRange(obj, styles, range) {
    obj.setSelectionStyles(styles, range[0], range[1]);
    this.canvas.fire('object:modified', { target: obj });
    this.canvas.renderAll();
  }

  updateCanvasProperty(prop, value) {
    this.canvas.set(prop, value);
    this.canvas.renderAll();
  }

  #hideControls(obj) {
    obj.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: false,
    });
  }

  removeObject(obj) {
    this.canvas.remove(obj);
  }

  removeSelectedObject(skipEditing = true) {
    if (!this.canvas) {
      return;
    }

    const objs = this.canvas.getActiveObjects();

    if (objs == null || (skipEditing && objs.some(this.#isEditing))) {
      return;
    }

    this.canvas.discardActiveObject();

    this.disableHistory();
    objs.forEach((obj, index) => { this.removeObject(obj, index > 0); });
  }

  removeBackgroundImage() {
    this.canvas.setBackgroundImage(null);
    this.canvas.renderAll();

    this.updateHistory();
  }

  addShape(type, options) {
    let shape;
    options.width ??= 10;
    options.height ??= 10;
    options.originX = 'left';
    options.originY = 'top';
    options.perPixelTargetFind = true;
    options.strokeUniform = true;

    switch (type) {
      case 'rect': {
        shape = new fabric.Rect(options);
        break;
      }
      case 'ellipse': {
        options.rx ??= 10;
        options.ry ??= 10;

        shape = new fabric.Ellipse(options);
        break;
      }
      case 'triangle': {
        shape = new fabric.Triangle(options);
        break;
      }
    }

    this.canvas.add(shape);
    shape.setCoords();
    this.canvas.renderAll();

    return shape;
  }

  isLocked({ lockMovementX, lockMovementY, visible }) {
    return lockMovementX && lockMovementY && visible;
  }

  startNormalMode(discardActive = true) {
    if (this.canvas == null) {
      return;
    }

    this.inToolMode = false;
    this.canvas.set({
      selection: true,
      defaultCursor: 'default',
      hoverCursor: 'move',
      isDrawingMode: false,
      isTextMode: false,
    });

    this.#enableObjectEvents(true);
    if (discardActive) {
      this.canvas.discardActiveObject();
    }
    this.canvas.renderAll();
  }

  startTextMode() {
    this.inToolMode = true;
    this.canvas.set({
      selection: false,
      defaultCursor: 'crosshair',
      hoverCursor: 'move',
      isDrawingMode: false,
      isTextMode: true,
    });

    this.#enableObjectEventsUsingCallback((obj) => Object.values(TextTypes).includes(obj.type));
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  startFreeDrawingMode() {
    this.inToolMode = true;
    this.canvas.set({
      selection: false,
      defaultCursor: 'crosshair',
      hoverCursor: 'crosshair',
      isDrawingMode: this.canvas.getActiveObject()?.eflex?.type === EflexObjTypes.FREE_LINE,
      isTextMode: false,
    });

    this.#enableObjectEvents(false);
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  startIconsOrShapeMode() {
    this.inToolMode = true;
    this.canvas.set({
      selection: false,
      defaultCursor: 'crosshair',
      hoverCursor: 'crosshair',
      isDrawingMode: false,
      isTextMode: false,
    });

    this.#enableObjectEvents(false);
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  sendToBack(obj) {
    this.canvas.sendToBack(obj);
    this.#updateLayer(obj, 'down');
  }

  bringToFront(obj) {
    this.canvas.bringToFront(obj);
    this.#updateLayer(obj);
  }

  sendBackwards(obj) {
    this.#moveLayer(obj, 'down');
  }

  bringForward(obj) {
    this.#moveLayer(obj, 'up');
  }

  cannotArrange() {
    return getCanvasObjects(this.canvas).filter(item => item.visible).length < 2;
  }

  groupSelection() {
    this.isGrouping = true;
    const active = this.canvas.getActiveObject();

    if (active.type !== 'activeSelection') {
      return;
    }

    //when grouping, fabric will auto place the group on the topmost layer.
    //we need to move the new group to the relative location of the lowest object in the selection.
    const layer = getCanvasObjects(this.canvas).indexOf(active.getObjects())[0];

    const group = active.toGroup();

    if (this.#hasLines(group)) {
      this.#getLinesFromSelection(group).forEach((line) => {
        this.#updateLineItemsLayer(line, 'down', group);
      });

      this.#hideControls(group);
      group.dirty = true;
      this.canvas.renderAll();
    }

    group.perPixelTargetFind = true;
    group.subTargetCheck = true;
    group.moveTo(layer);
    this.isGrouping = false;

    this.enableHistory();
    this.updateHistory();
    return group;
  }

  ungroupSelection() {
    const activeObject = this.canvas.getActiveObject();
    activeObject.getObjects().forEach((object) => {
      this.canvas.remove(object);
    });

    const active = activeObject.toActiveSelection();
    if (this.#hasLines(active)) {
      this.#hideControls(active);
    }

    this.canvas.fire('selection:updated', { selected: active });
    this.updateHistory();
    return active;
  }

  clearClipboard() {
    this._clipboard = null;
  }

  activateObjectByPoint(x, y) {
    const point = new fabric.Point(x, y);

    const activeObject = this.canvas.getActiveObject();

    if (activeObject != null && activeObject.containsPoint(point)) {
      return;
    }

    const obj = getCanvasObjectByPoint(this.canvas, x, y);

    if (obj != null) {
      this.canvas.setActiveObject(obj);
    } else {
      this.canvas.discardActiveObject();
    }

    this.canvas.renderAll();
  }

  createArrow(options = {}) {
    return new fabric.Triangle({
      hasControls: false,
      hasBorders: false,
      left: options.x,
      top: options.y,
      width: ArrowHead.WIDTH,
      height: ArrowHead.HEIGHT,
      angle: options.angle,
      originX: 'center',
      originY: 'center',
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      fill: options.fill,
      stroke: options.stroke,
      strokeWidth: options.strokeWidth,
      centeredScaling: true,
      visible: options.visible,
      eflex: {
        childObject: true,
        itemKey: options.itemKey,
        itemPosition: options.itemPosition,
        type: EflexObjTypes.ARROWHEAD,
      },
    });
  }

  createHandle(options = {}) {
    return new fabric.Rect({
      hasControls: false,
      hasBorders: false,
      left: options.x,
      top: options.y,
      width: ControlStyles.cornerSize,
      height: ControlStyles.cornerSize,
      originX: 'center',
      originY: 'center',
      selectable: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      fill: ControlStyles.cornerColor,
      stroke: `${ControlStyles.cornerStrokeColor}FF`,
      strokeWidth: 3,
      visible: false,
      eflex: {
        childObject: true,
        itemKey: options.itemKey,
        itemPosition: options.itemPosition,
        type: EflexObjTypes.HANDLE,
      },
    });
  }

  getObjectIndex(obj, source = this.canvas) {
    return source.getObjects().indexOf(obj);
  }

  calcLineAngle(x1, y1, x2, y2) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    let angle = Math.atan2(dy, dx);
    angle *= 180 / Math.PI;
    angle += 90;
    return angle;
  }

  moveActiveObject(direction, pixels) {
    if (!this.canvas) {
      return;
    }

    const active = this.canvas.getActiveObject();
    if (active == null || this.isLocked(active)) {
      return;
    }

    if (direction === 'x') {
      active.set('left', active.left + pixels);
    } else {
      active.set('top', active.top + pixels);
    }

    this._moveLines(active);
    active.setCoords();

    this._updateHistoryDebounce.perform();
    this.canvas.renderAll();
  }

  _updateHistoryDebounce = task({ restartable: true }, waitFor(async () => {
    await timeout(getDelayTime(UPDATE_HISTORY_DELAY_TIME));
    this.updateHistory();
  }));

  #truncateDiffStack(stackProp) {
    const diffStack = this[stackProp];
    if (diffStack.length > MAX_HISTORY) {
      this[stackProp] = new TrackedArray(drop(diffStack.length - MAX_HISTORY, diffStack));
    }
  }

  _updateSelection() {
    let active = this.canvas.getActiveObject();
    if (active == null) {
      return;
    }

    const type = active.eflex?.type || active.type;

    switch (type) {
      case 'activeSelection': {
        active = this.#filterLockedObjectSelection(active);

        if (this.#hasLines(active)) {
          this.#hideControls(active);
          this.#selectLineItems(active);
        }
        break;
      }

      case EflexObjTypes.STRAIGHT_LINE: {
        this.#toggleLineHandles(active, true);
        this.canvas.renderAll();
        break;
      }
    }
  }

  #filterLockedObjectSelection(active) {
    const activeObjects = active.getObjects();

    const filteredObjs = activeObjects.filter(
      (obj) => !obj.lockMovementX && !obj.lockMovementY && !obj.lockRotation && !obj.lockSkewingX && !obj.lockSkewingY,
    );

    if (filteredObjs.length !== activeObjects.length) {
      this.canvas.discardActiveObject();
      active = new fabric.ActiveSelection(filteredObjs, { canvas: this.canvas });
      this.canvas.setActiveObject(active);
      this.canvas.renderAll();
    }

    return active;
  }

  #isEditing(obj) {
    return obj?.isEditing;
  }

  #enableObjectEvents(enable) {
    this.canvas.forEachObject(function (obj) {
      if (obj.eflex?.childObject != null) {
        return;
      }

      obj.selectable = enable;
      obj.evented = enable;
    });
  }

  #enableObjectEventsUsingCallback(_filter) {
    this.canvas.forEachObject(function (obj) {
      if (obj.eflex?.childObject != null) {
        return;
      }

      const enable = Boolean(_filter(obj));
      obj.selectable = enable;
      obj.evented = enable;
    });
  }

  #determineIconHeightWidth(icon) {
    const {
      height: initCanvasHeight,
      width: initCanvasWidth,
      lowerCanvasEl: { clientHeight: actualCanvasHeight, clientWidth: actualCanvasWidth },
    } = this.canvas;
    const {
      target: {
        height: iconNaturalHeight,
        width: iconNaturalWidth,
        clientHeight: iconClientHeight,
        clientWidth: iconClientWidth,
      },
    } = icon;

    // landscape == 1, portrait == 0
    const iconLayout = iconNaturalWidth >= iconNaturalHeight ? 1 : 0;

    const heightMultiplier =
      iconLayout === 0 ? iconClientHeight : iconClientHeight * (iconNaturalHeight / iconNaturalWidth);

    const widthMultiplier =
      iconLayout === 1 ? iconClientWidth : iconClientWidth * (iconNaturalWidth / iconNaturalHeight);

    return {
      height: heightMultiplier * (initCanvasHeight / actualCanvasHeight),
      width: widthMultiplier * (initCanvasWidth / actualCanvasWidth),
    };
  }

  #updateLayer(obj, direction = 'up') {
    if (obj.eflex?.type === EflexObjTypes.STRAIGHT_LINE) {
      this.#updateLineItemsLayer(obj, direction);
    }

    this.canvas.fire('object:modified', obj);
  }

  #pasteLineParts(obj) {
    if (obj.cloneItems?.length > 0) {
      this.applyItemKey(obj, true);
      obj.cloneItems.forEach((item) => {
        item.eflex.itemKey = obj.eflex.itemKey;
        this.canvas.add(item);
      });
    }

    this.updateLineItemsPosition(obj);
  }

  #updateItemKeys(objs) {
    const parsedObjs = objs.filter(item => !item.eflex?.itemKey);

    pipe(
      filter(dp('eflex.itemKey')),
      collectBy(dp('eflex.itemKey')),
      forEach(v => {
        const parentObj = find(o => o.eflex.childObject == null, v);
        const arrows = [];
        const handles = [];
        this.applyItemKey(parentObj, true);

        v.forEach(o => {
          o.eflex.itemKey = parentObj.eflex.itemKey;
          // maintain correct layering order for line parts
          switch (o.eflex.type) {
            case EflexObjTypes.ARROWHEAD: {
              arrows.push(o);
              break;
            }
            case EflexObjTypes.HANDLE: {
              handles.push(o);
              break;
            }
            default: {
              parsedObjs.push(o);
            }
          }
        });

        parsedObjs.push(...arrows, ...handles);
      }),
    )(objs);

    return parsedObjs;
  }

  #hasLines(obj) {
    return this.#getLinesFromSelection(obj).length > 0;
  }

  #selectLineItems(active) {
    const lines = this.#getLinesFromSelection(active);

    const allObjs = active.getObjects();
    const selectableObjs = allObjs.filter(item => !item.eflex?.childObject);

    // if selection goes from many items down to one line,
    // make sure just that one line is selected and not it's parts
    if (lines.length === selectableObjs.length && selectableObjs.length === 1) {
      this.canvas.discardActiveObject();
      this._moveLines(lines[0]);
      this.canvas.setActiveObject(lines[0]);
      this.canvas.renderAll();
      return;
    }

    lines.forEach((line) => {
      this.#toggleLineHandles(line, false);
      // add line parts to the selection if it does not already have 2 handles and 2 arrowheads
      const lineParts = allObjs
        .filter(item => item.eflex?.childObject)
        .filter(item => item.eflex?.itemKey === line.eflex.itemKey);

      if (lineParts.length !== 4) {
        active.add(...getLineParts(this.canvas, line));
      }
    });

    this._moveLines(active);

    this.canvas.renderAll();
  }

  #getLinesFromSelection(obj) {
    return obj.getObjects().filter(item => item.eflex?.type === EflexObjTypes.STRAIGHT_LINE);
  }

  #getLineByHandle(handle) {
    return getCanvasObjects(this.canvas).find((obj) =>
      obj.eflex?.type === EflexObjTypes.STRAIGHT_LINE && obj.eflex?.itemKey === handle.eflex?.itemKey,
    );
  }

  #removeLines(obj, disableHistory = true) {
    if (disableHistory) {
      this.disableHistory();
    }

    if (this.isGrouping) {
      return;
    }

    const type = obj.eflex?.type ?? obj.type;

    switch (type) {
      case EflexObjTypes.STRAIGHT_LINE: {
        this.#removeLineItems(obj);
        break;
      }
      case 'activeSelection':
      case 'group': {
        obj.forEachObject((subObj) => {
          const subType = subObj.eflex?.type ?? subObj.type;

          if (subType === EflexObjTypes.STRAIGHT_LINE) {
            this.#removeLineItems(subObj);
          } else if (subType === 'activeSelection' || subType === 'group') {
            this.#removeLines(subObj, false);
          }
        });
        break;
      }
      default: {
        if (this.canvas._objects.length === 0) {
          this.enableHistory();
        }
        return;
      }
    }

    if (disableHistory) {
      this.enableHistory();
    }
  }

  #removeLineItems(line) {
    const items = getLineParts(this.canvas, line);
    items.forEach((item) => {
      this.removeObject(item);
    });
  }

  _moveLines(obj) {
    const type = obj.eflex?.type ?? obj.type;

    switch (type) {
      case EflexObjTypes.STRAIGHT_LINE: {
        this.updateLineItemsPosition(obj);
        break;
      }
      case EflexObjTypes.HANDLE: {
        this.#updateLineByHandle(obj);
        break;
      }
      case 'activeSelection':
      case 'group': {
        obj.forEachObject((subObj) => {
          const subType = subObj.eflex?.type || subObj.type;

          if (subType === EflexObjTypes.STRAIGHT_LINE) {
            this.updateLineItemsPosition(subObj);
          } else if (subType === 'activeSelection' || subType === 'group') {
            this._moveLines(subObj);
          }
        });
        break;
      }
    }
  }

  #deselectLines(objects, selected) {
    if (objects == null) {
      return;
    }

    const lines = objects.filter(item => item.eflex?.type === EflexObjTypes.STRAIGHT_LINE);

    lines.forEach((line) => {
      line.set(this.#calcGlobalLinePoints(line));
      line.setCoords();
      if (selected == null || selected?.eflex?.itemKey !== line.eflex.itemKey) {
        this.#toggleLineHandles(line, false);
      }
    });

    const handles = objects.filter(item => item.eflex?.type === EflexObjTypes.HANDLE);

    handles.forEach((handle) => {
      if (selected == null || selected?.eflex?.itemKey !== handle.eflex.itemKey) {
        const line = this.#getLineByHandle(handle);
        if (line == null) {
          return;
        }
        this.#toggleLineHandles(line, false);
      }
    });

    this.canvas.renderAll();
  }

  #updateLineByHandle(handle) {
    const line = this.#getLineByHandle(handle);
    if (line == null) {
      return;
    }

    const point = handle.getCenterPoint();

    switch (handle.eflex?.itemPosition) {
      case 'start': {
        line.set({
          x1: point.x,
          y1: point.y,
        });
        break;
      }
      case 'end': {
        line.set({
          x2: point.x,
          y2: point.y,
        });
        break;
      }
      default: {
        return;
      }
    }

    line.setCoords();
    this.updateLineItemsPosition(line);
    this._setActiveObjectWithDebounce.perform(line);
    this.canvas.renderAll();
  }

  updateLineItemsPosition(line) {
    const gp = this.#calcGlobalLinePoints(line);

    const items = getLineParts(this.canvas, line);

    items.forEach((item) => {
      let newX, newY;
      if (!item) {
        return;
      }

      let angle = this.calcLineAngle(gp.x1, gp.y1, gp.x2, gp.y2);

      switch (item.eflex.itemPosition) {
        case 'start': {
          angle -= 180;
          newX = gp.x1;
          newY = gp.y1;
          break;
        }
        case 'end': {
          newX = gp.x2;
          newY = gp.y2;
          break;
        }
        default: {
          return;
        }
      }

      item.set({
        angle,
        flipX: false,
        flipY: false,
      });

      item.setPositionByOrigin(
        {
          x: newX,
          y: newY,
        },
        'center',
        'center',
      );

      item.setCoords();
    });

    this.canvas.renderAll();
  }

  #updateLineItemsLayer(line, direction = 'up', source = this.canvas) {
    if (line.eflex?.type !== EflexObjTypes.STRAIGHT_LINE) {
      return;
    }

    const lineLayer = this.getObjectIndex(line, source);

    let distance = 0;
    const items = getLineParts(source, line);
    if (direction === 'down') {
      items.reverse();
      distance = 1;
    }

    items.forEach((item) => {
      if (item) {
        item.moveTo(lineLayer + distance);
      }
    });
  }

  #calcGlobalLinePoints(line) {
    const localPoints = line.calcLinePoints();

    const startPoint = toGlobalPoint(line, new fabric.Point(localPoints.x1, localPoints.y1));
    const endPoint = toGlobalPoint(line, new fabric.Point(localPoints.x2, localPoints.y2));

    return {
      x1: startPoint.x,
      y1: startPoint.y,
      x2: endPoint.x,
      y2: endPoint.y,
    };
  }

  #addDefaultEvents() {
    const objectMoveLines = ({ target: obj }) => {
      this._moveLines(obj);
    };

    this.canvas
      .on('object:modified', () => {
        this.updateHistory();
      })
      .on('object:added', event => {
        const obj = event.target;
        obj.id ??= new ObjectId().toString();
        this.updateHistory();
        this.trigger('object:added', event);
      })
      .on('object:removed', event => {
        const obj = event.target;
        this.#removeLines(obj);
        this.updateHistory();
        this.trigger('object:removed', event);
      })
      .on('object:moving', objectMoveLines)
      .on('object:rotating', objectMoveLines)
      .on('object:skewing', objectMoveLines)
      .on('object:scaling', objectMoveLines)
      .on('selection:cleared', event => {
        this.#deselectLines(event.deselected);
        this.trigger('selection:cleared', event);
      })
      .on('selection:updated', event => {
        this.trigger('selection:updated', event);
        this.#deselectLines(event.deselected, event.selected);
        this._updateSelection();
      })
      .on('selection:created', event => {
        this.trigger('selection:created', event);
        this._updateSelection();
      });
  }

  _setActiveObjectWithDebounce = task({ restartable: true }, waitFor(async obj => {
    await timeout(getDelayTime(SET_ACTIVE_OBJECT_DELAY_TIME));
    this.canvas.setActiveObject(obj);
  }));

  #fitImageToCanvas(image, scaleUp = false) {
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    if (image.width <= canvasWidth && image.height <= canvasHeight && !scaleUp) {
      return image;
    }

    const PORTRAIT = 0;
    const LANDSCAPE = 1;

    const orientation = image.height > image.width ? PORTRAIT : LANDSCAPE;

    let scaleRatio;

    if (orientation === LANDSCAPE) {
      scaleRatio = canvasWidth / image.width;
    } else {
      scaleRatio = canvasHeight / image.height;
    }

    if (image.width * scaleRatio > canvasWidth) {
      scaleRatio = canvasWidth / image.width;
    } else if (image.height * scaleRatio > canvasHeight) {
      scaleRatio = canvasHeight / image.height;
    }

    image.scaleX = scaleRatio;
    image.scaleY = scaleRatio;

    return image;
  }

  #nearestObject(target, direction) {
    if (target.type === 'activeSelection') {
      return null;
    }

    const objects = [];
    const layers = [];
    let targetLayerIndex;
    const allObjects = this.canvas.getObjects();

    // eslint-disable-next-line unicorn/no-for-loop
    for (let i = 0; i < allObjects.length; i++) {
      const obj = allObjects[i];
      if (obj.eflex?.childObject) {
        continue;
      }
      objects[i] = obj;
      const layerLength = layers.push(i);
      if (obj === target) {
        targetLayerIndex = layerLength - 1;
      }
    }

    let modifier;
    if (direction === 'up') {
      modifier = 1;
    } else {
      modifier = -1;
    }

    const nearLayerIndex = layers[targetLayerIndex + modifier];
    const nearObj = objects[nearLayerIndex];

    if (!nearObj) {
      return null;
    }

    return {
      obj: nearObj,
      layer: nearLayerIndex,
      children: getCanvasObjects(this.canvas).filter((obj) =>
        obj.eflex?.childObject && obj.eflex?.itemKey === nearObj.eflex?.itemKey,
      ),
    };
  }

  #moveLayer(obj, direction) {
    if (obj.type === 'activeSelection') {
      this.#moveActiveSelectionLayer(obj, direction);
      return;
    }

    const nearObj = this.#nearestObject(obj, direction);

    if (nearObj == null) {
      return;
    }

    let moveToLayer = nearObj.layer;

    if (direction === 'up') {
      //move past any child objects
      moveToLayer += nearObj.children.length;
    }

    obj.moveTo(moveToLayer);
    this.#updateLayer(obj, direction);
  }

  #moveActiveSelectionLayer(obj, direction) {
    //layering does not play nice with activeSelection.
    //turn activeSelection to group, move it, then ungroup as a work around.
    const nearestTo = direction === 'down' ? 0 : obj.size() - 1;

    const nearObj = this.#nearestObject(obj.getObjects()[nearestTo], direction);

    if (nearObj == null) {
      return;
    }

    this.disableHistory();
    obj = this.groupSelection();

    let moveToLayer = nearObj.layer;

    if (direction === 'up') {
      //move past any child objects
      moveToLayer += nearObj.children.length;
    }

    obj.moveTo(moveToLayer);

    obj = this.ungroupSelection();
    const children = obj.getObjects();

    if (direction === 'down') {
      children.reverse();
    }

    children.forEach((o) => {
      o.moveTo(moveToLayer);
    });

    this.enableHistory();

    this.#updateLayer(obj, direction);
  }

  disableGrid() {
    this.canvas.overlayImage = null;
    this.canvas.renderAll();
  }

  #toggleLineHandles(line, show = true) {
    const handles = getLineParts(this.canvas, line, null, EflexObjTypes.HANDLE);
    handles.forEach(function (obj) {
      obj.visible = show;
      obj.dirty = true;
    });
  }
}
