/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import getJemTaskComponent from 'eflex/util/get-jem-task-component';
import * as JemTaskState from 'eflex/util/jem-task-state';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class JemTaskRow extends Component {
  TaskStatuses = TaskStatuses;

  @tracked showDetails = true;

  @service taskRepo;
  @service currentUser;

  get taskComponent() {
    return getJemTaskComponent(this.args.task.taskType);
  }

  get taskConfirmButtonIsShown() {
    if (!this.args.isSelected) {
      return false;
    }

    return JemTaskState.taskConfirmButtonIsShown({
      childStatus: this.args.childStatus,
      task: this.args.task,
      taskConfig: this.args.taskConfig,
    });
  }

  get taskConfirmIsDisabled() {
    if (!this.args.isSelected) {
      return true;
    }

    return JemTaskState.taskConfirmIsDisabled({
      childStatus: this.args.childStatus,
      task: this.args.task,
      taskConfig: this.args.taskConfig,
      notAuthorized: this.args.notAuthorized,
    });
  }

  get taskRejectButtonIsShown() {
    if (!this.args.isSelected) {
      return false;
    }

    return JemTaskState.taskRejectButtonIsShown({
      childStatus: this.args.childStatus,
      task: this.args.task,
      taskConfig: this.args.taskConfig,
    });
  }

  get taskRejectIsDisabled() {
    if (!this.args.isSelected) {
      return true;
    }

    return JemTaskState.taskRejectIsDisabled({
      childStatus: this.args.childStatus,
      task: this.args.task,
      taskConfig: this.args.taskConfig,
      notAuthorized: this.args.notAuthorized,
    });
  }

  get showBigOkButton() {
    if (!this.args.isSelected) { return false; }
    if (!this.args.childStatus) { return false; }
    if (!this.args.childStatus.isStarted) { return false; }
    if (this.args.notAuthorized) { return false; }
    if (!this.args.taskConfig) { return false; }

    return true;
  }

  get buttonColSpan() {
    if (this.args.childStatus?.isStarted || !this.args.station.jemShowTaskCycle) {
      return '2';
    } else {
      return '1';
    }
  }

  @action
  onClick() {
    if (this.args.isSelected) {
      this.showDetails = !this.showDetails;
    } else {
      this.showDetails = true;
      this.args.onSelectTask(this.args.task);
    }
  }

  @action
  onTaskComplete(status) {
    return this.args.onTaskComplete(status, this.args.childStatus);
  }
}
