import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { sortByProp } from 'ramda-adjunct';
import { t } from 'ember-intl';
import { append, isPresent } from '@eflexsystems/ember-composable-helpers';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
export default class NodeRedTriggerConfig extends Component {
    @service
    taskRepo;
    @service
    store;
    get parentTrigger() {
        return this.args.triggerConfig.parentTrigger;
    }
    get variableMappings() {
        return this.parentTrigger.variableMappings.filter((item1)=>!item1.isDeleted);
    }
    get previousTasks() {
        let task1;
        if (this.parentTrigger.task != null) {
            task1 = this.parentTrigger.task;
        } else {
            task1 = sortByProp('row', this.parentTrigger.station.tasks ?? []).at(-1);
        }
        return this.taskRepo.getAllPreviousTasks(task1, this.args.isStationEvent);
    }
    get previousJemProcessDataDefs() {
        return this.previousTasks.flatMap((_task1)=>_task1.jemProcessDataDefs).filter((item1)=>!item1.isDeleted);
    }
    get filteredVariableDefs() {
        return this.previousTasks.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted && def1.isFromDevice);
    }
    setVariableMappingValue = (variableMapping1, value1)=>{
        switch(value1.constructor?.modelName){
            case 'variable-def':
                {
                    Object.assign(variableMapping1, {
                        variableDefForValue: value1,
                        jemProcessDataDefForValue: null,
                        value: null
                    });
                    break;
                }
            case 'jem-process-data-def':
                {
                    Object.assign(variableMapping1, {
                        variableDefForValue: null,
                        jemProcessDataDefForValue: value1,
                        value: null
                    });
                    break;
                }
            default:
                {
                    Object.assign(variableMapping1, {
                        variableDefForValue: null,
                        jemProcessDataDefForValue: null,
                        value: value1
                    });
                }
        }
    };
    addMultipleVariableMappings = (quantity1)=>{
        for(let i1 = 1, end1 = quantity1; i1 <= end1; i1++){
            this.parentTrigger.variableMappings.push(this.store.createRecord('variableMapping'));
        }
    };
    deleteVariableMapping = (variableMapping1)=>{
        variableMapping1.deleteRecord();
    };
    static{
        template(`
    <div class="row form-row align-nested-header pt-0">
      <div class="col">
        <div class="nested-header">
          {{t "mqttRequestTopic"}}
        </div>
        <@form.element
          @model={{this.parentTrigger}}
          @controlType="text"
          @property="mqttTopic"
          @size="sm"
          class="trigger-mqtt-topic"
        />
      </div>
    </div>
    {{#if (isPresent this.variableMappings)}}
      <div class="row form-row align-nested-header py-0">
        <div class="col">
          <div class="nested-header">
            {{t "variableName"}}
          </div>
        </div>
        <div class="col">
          <div class="nested-header">
            {{t "variableValue"}}
          </div>
        </div>
      </div>
      {{#each this.variableMappings as |mapping|}}
        <div class="variable-row position-relative pt-0 pb-1 row form-row">
          <div class="col">
            <@form.element
              @controlType="text"
              @model={{mapping}}
              @property="name"
              @size="sm"
              @disabled={{@disabled}}
              class="variable-name"
            as |el|>
              <el.control disabled={{@disabled}} />
            </@form.element>
          </div>
          <div class="col">
            <@form.element>
              <PowerSelectWithCreate
                @triggerClass="form-control form-control-sm"
                @options={{
                  unless
                  @isOeeEvent
                  (append
                    this.filteredVariableDefs
                    this.previousJemProcessDataDefs
                    mapping.valueForOptions
                  )
                }}
                @selected={{mapping.selectedValue}}
                @searchEnabled={{true}}
                @searchField="name"
                @disabled={{@disabled}}
                @onCreate={{fn this.setVariableMappingValue mapping}}
                @onChange={{fn this.setVariableMappingValue mapping}}
                class="variable-value"
              as |value|>
                {{value.name}}
              </PowerSelectWithCreate>
            </@form.element>
          </div>
          <div class="col-auto absolute-trash">
            <div class="col-icon">
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn this.deleteVariableMapping mapping}}
              />
            </div>
          </div>
        </div>
      {{/each}}
    {{/if}}
    <div class="row form-row pt-0">
      <div class="col">
        <QuantitySelect
          class="text-uppercase"
          @size="sm"
          @disabled={{@disabled}}
          @onSubmit={{this.addMultipleVariableMappings}}
        >
          {{t "components.addVariable"}}
        </QuantitySelect>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
