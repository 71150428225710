import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Component from '@glimmer/component';
let JemTaskNodeRedDetails = class JemTaskNodeRedDetails extends Component {
    get vars() {
        return this.args.task.variableDefs.filter((item1)=>item1.isFromDevice).map((def1)=>({
                name: def1.name,
                value: this.args.childStatus?.processData?.find((item1)=>item1.name === def1.name)?.value
            }));
    }
    static{
        template(`
    <div class="jem-process-data" ...attributes>
      {{#each this.vars as |variable|}}
        <div class="jem-variable detail-section {{@childStatus.taskStatusClass}}">
          <div class="process-data-def-name detail-text-label">
            {{variable.name}}
          </div>
          <div class="process-data-def-value detail-text-value">
            {{variable.value}}
          </div>
        </div>
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default template(`
{{yield
  (hash
    taskDetails=(component
      JemTaskNodeRedDetails
      task=@task
      childStatus=@childStatus
    )
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
