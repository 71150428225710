/* import __COLOCATED_TEMPLATE__ from './criteria-visor.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

export default class CriteriaVisor extends Component {
  @tracked params = {};

  constructor() {
    super(...arguments);
    this.initParam('searchTerm', this.args.searchTerm);
  }

  @action
  initParam(key, value) {
    if (isBlank(value)) {
      return;
    }

    this.params[key] = value;
  }

  @action
  updateParam(key, value) {
    if (isBlank(value)) {
      delete this.params[key];
    } else {
      this.params[key] = value;
    }

    this.args.onParamChange(key, value);
  }

  @action
  onSearch() {
    return this.args?.onSearch({ ...this.params });
  }
}
