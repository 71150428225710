import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class KineticOperationsRoute extends AuthenticatedRoute {
  @service router;

  async beforeModel(transition) {
    await super.beforeModel(transition);
    const selectedOperation = this.controllerFor('kineticOperations/operation').operation;
    if (selectedOperation) {
      this.router.transitionTo('kineticOperations.operation', selectedOperation.id);
    }
  }

  resetController(controller) {
    Object.assign(controller.operationController, {
      selectedPart: null,
      selectedPartRev: null,
      operation: null,
    });
  }
}
