import LocationRepoBase from 'eflex/services/location-repo-base';
import { recursiveSave } from 'eflex/util/tree-helpers';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { maxOf } from '@eflexsystems/ramda-helpers';
import { isEmpty } from '@ember/utils';
import { prop } from 'ramda';

const getOrder = (siblings) => {
  if (isEmpty(siblings)) {
    return 0;
  }

  return maxOf(prop('order'), siblings) + 1;
};

export default class KineticOperationRepoService extends LocationRepoBase {
  @service taskRepo;

  operations = this.store.peekAll('kineticOperation');

  create(properties = {}) {
    properties.order = getOrder(this.operations);
    return super.create('kineticOperation', properties);
  }

  createAndSave = task(waitFor(async (properties = {}) => {
    const kineticOperation = this.create(properties);
    await kineticOperation.save();
    return kineticOperation;
  }));

  copyOperationTasks = task(waitFor(async (operation, targetOperation) => {
    operation.children.forEach((taskLocation) => {
      this.taskRepo.copyTask(taskLocation, targetOperation, true);
    });

    await recursiveSave(targetOperation);
  }));
}
