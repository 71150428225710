import { template } from "@ember/template-compiler";
import { get, uniqueId, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { findBy, reverse } from '@eflexsystems/ember-composable-helpers';
import { and, eq, or } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import JemSpindle from 'eflex/components/jem/spindle';
const JemTaskMultispindleDetails = template(`
<div class="component-jem-task-multispindle-details" ...attributes>
  <div class="detail-section">
    <div class="pset detail-text-label">
      {{t "plant.station.program"}}
    </div>
    <div class="detail-text-value">
      {{@taskConfig.programNumber}}
    </div>
  </div>
  <div class="jem-spindles bg-white">
    {{#each @childStatus.groupedSpindleData as |datum|}}
      {{#let
        (uniqueId)
        (findBy "isActiveResult" true datum)
      as |groupId activeResult|}}
        <div class="jem-task-table jem-spindle-group bootstrap-table my-3 mx-2">
          <div class="row form-row border-bottom border-dark spindle-row spindle-started
            {{if activeResult.isPassed 'spindle-good'}}
            {{if activeResult.isRejected 'spindle-rejected'}}"
          >
            <div class="col-auto spindle-name">
              {{get datum '0.name'}}
            </div>
            {{#unless activeResult.isNotReceived}}
              <div class="col-auto ps-0 d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm d-flex align-items-center"
                  data-bs-target="#{{groupId}}"
                  data-bs-toggle="collapse"
                  disabled={{or (eq datum.length 1) (and activeResult.isRejected @childStatus.isStarted)}}
                >
                  <FaIcon class="me-1" @icon="list" @prefix="fas"/>
                  {{datum.length}}
                </button>
              </div>
            {{/unless}}
            <div class="multispindle-status col-auto ms-auto text-uppercase">
              {{#if activeResult.isPassed}}
                {{t "status.251"}}
              {{else if activeResult.isRejected}}
                {{t "rejected"}}
              {{else}}
                {{t "started"}}
              {{/if}}
            </div>
          </div>
          <div class="row form-row header-row">
            <div class="col torque-col">{{t "torque"}}</div>
            <div class="col angle-col">{{t "angle"}}</div>
          </div>
          {{#each (reverse datum) as |spindle|}}
            <JemSpindle
              id={{if spindle.isCollapsedResult groupId}}
              @spindle={{spindle}}
              @isActive={{eq spindle.multispindleProcessDatum @childStatus.latestMultispindleDatum}}
              @isStarted={{@childStatus.isStarted}}
            />
          {{/each}}
        </div>
      {{/let}}
    {{/each}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskMultispindleDetails
        childStatus=@childStatus
        taskConfig=@taskConfig
    ))}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
