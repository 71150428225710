/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { task, timeout } from 'ember-concurrency';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import getDelayTime from 'eflex/util/get-delay-time';
import { waitFor } from '@ember/test-waiters';
import { unregisterDestructor, registerDestructor } from '@ember/destroyable';
import exportToCsv from 'eflex/util/export-to-csv';

export default class EChart extends Component {
  #destructor;

  onResize = task({ restartable: true }, waitFor(async element => {
    await timeout(getDelayTime(100));
    const ECharts = await this._loadEcharts.perform();
    ECharts.getInstanceByDom(element)?.resize();
  }));

  startAutoRefresh = task({ restartable: true }, waitFor(async (element, [onClick, option]) => {
    if (window.isTesting || window.isIntegrationTest) {
      return;
    }

    while (this.args.autoRefresh > 0) {
      await timeout(this.args.autoRefresh);
      await this.createChart.perform(element, [onClick, option]);
    }
  }));

  createChart = task({ enqueue: true }, waitFor(async (element, [onClick, option]) => {
    const ECharts = await this._loadEcharts.perform();

    let chart = ECharts.getInstanceByDom(element);
    this.#destroyChart(chart);

    const options = {
      renderer: 'canvas',
      useDirtyRect: true,
    };

    if (window.isTesting) {
      options.width = 1;
      options.height = 1;
    }

    chart = ECharts.init(element, EchartsTheme, options);

    this.#destructor = registerDestructor(this, () => {
      this.#destroyChart(chart);
    });

    chart.setOption(option ?? {});

    if (onClick) {
      chart.on('click', (param) => {
        onClick(param?.dataIndex, chart);
      });
    }
  }));

  _loadEcharts = task(waitFor(async () => {
    const echarts = await import('echarts');
    window.ECharts ??= echarts;
    return echarts;
  }));

  #destroyChart(chart) {
    if (!this.isDestroyed && !this.isDestroying && this.#destructor) {
      unregisterDestructor(this, this.#destructor);
    }

    if (!chart) {
      return;
    }

    chart.off('click');
    chart.dispose();
  }

  exportToCsv = task(waitFor(async () => {
    await exportToCsv(this.args.exportData, this.args.option?.title?.text);
  }));
}
