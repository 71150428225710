import { template } from "@ember/template-compiler";
import { get, hash } from '@ember/helper';
import { t } from 'ember-intl';
import isPickWithNoConfiguredInput from 'eflex/helpers/is-pick-with-no-configured-input';
import getTaskHardwareInput from 'eflex/helpers/get-task-hardware-input';
import getTaskHardwareOutput from 'eflex/helpers/get-task-hardware-output';
import { JemTaskTimerDetails } from 'eflex/components/jem/task/timer';
const JemTaskPickDetails = template(`
<div ...attributes>
  <div class="detail-section">
    {{#unless (isPickWithNoConfiguredInput @station @task)}}
      <div class="detail-text-label sensor">
        {{t "plant.task.sensor"}}
      </div>
      <div class="hardware-input mb-3 {{@childStatus.taskStatusClass}}"></div>
      {{#if @hardware.name}}
        <div class="detail-text-value hardware-name">
          {{@hardware.name}}-
        </div>
      {{/if}}
      <div class="detail-text-value hardware-input-name">
        {{get (getTaskHardwareInput @station @task) 'inputName'}}
      </div>
    {{/unless}}
  </div>
  <div class="detail-section">
    <div class="detail-text-label indicator">
      {{t "plant.task.pick.indicator"}}
    </div>
    <div class="hardware-output {{@childStatus.taskStatusClass}}">
      {{#let (getTaskHardwareOutput @station @task) as |output|}}
        {{#if output.hardware.name}}
          <div class="detail-text-value hardware-name">
            {{output.hardware.name}}-
          </div>
        {{/if}}
        <div class="detail-text-value hardware-output-name">
          {{output.outputName}}
        </div>
      {{/let}}
    </div>
  </div>
  {{#if @taskConfig.isTimer}}
    <JemTaskTimerDetails
      @childStatus={{@childStatus}}
      @taskConfig={{@taskConfig}}
    />
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPickDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        station=@station
        hardware=@hardware
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
