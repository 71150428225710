import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import caption from 'eflex/helpers/caption';
import modelDisplayName from 'eflex/helpers/model-display-name';
const JemTaskPushToScheduleDetails = template(`
<div class="component-jem-task-schedule-details" ...attributes>
  <div class="detail-section {{@childStatus.taskStatusClass}}">
    {{#each @childStatus.scheduleProcessData as |scheduleDatum|}}
      <div class="schedule-datum">
        <div class="detail-text-label">
          {{caption scheduleDatum.station.captions}}
        </div>
        <div class="detail-text-value">
          {{scheduleDatum.scheduleTarget}}x {{modelDisplayName scheduleDatum.model}}
        </div>
      </div>
    {{/each}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPushToScheduleDetails
        childStatus=@childStatus
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
