import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class LocationRepoService extends Service {
  @service store;
  @service areaRepo;
  @service groupRepo;
  @service stationRepo;
  @service taskRepo;
  @service('taskConfigRepo') taskconfigurationCommands;

  createAndSave = task(waitFor(async (properties = {}) => {
    let repo;
    if (properties.parent) {
      repo = this.#getRepoForType(properties.parent.childType);
    } else {
      repo = this.areaRepo;
    }

    return await repo.createAndSave.perform(properties);
  }));

  deleteAndSave = task(waitFor(async deleted => {
    await this.#getRepoForType(deleted.type).deleteAndSave.perform(deleted);
  }));

  bulkSave = task(waitFor(async treeItems => {
    const bulkSave = this.store.createRecord('treeItemBulkSave', treeItems);
    await bulkSave.save();
    bulkSave.unloadRecord();
  }));

  #getRepoForType(type) {
    return this[`${type.toLowerCase()}Repo`];
  }
}
