import { anyInvalid, anyTreeItemDirty } from 'eflex/util/getter-helpers';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached, tracked } from '@glimmer/tracking';
import { arrayAttr, caption } from 'eflex/decorators';

export default class Group extends Model {
  @attr('string') path;
  @attr('number', { defaultValue: 0 }) order;
  @attr('string', { defaultValue: 'group' }) type;
  @attr('string') resourceGroupId;

  @arrayAttr captions;
  @caption name;

  @hasMany('station', { inverse: 'parent', async: false, cascadeDelete: true }) stations;
  @belongsTo('area', { inverse: 'groups', async: false }) parent;

  @tracked isChecked = false;

  get childType() {
    return 'station';
  }

  get treeIconClass() {
    return 'icon-group';
  }

  @cached
  get children() {
    return this.stations.filter(item => !item.isDeleted);
  }

  get allChildren() {
    return this.stations;
  }

  get area() {
    return this.parent;
  }

  set area(val) {
    this.parent = val;
  }

  get usesOperations() {
    return this.area.usesOperations;
  }

  get tasks() {
    return this.children.flatMap(station => station.children);
  }

  @cached
  get isSelfOrChildInvalid() {
    return anyInvalid(this.children);
  }

  @cached
  get isSelfOrChildDirty() {
    return this.isDirty || anyTreeItemDirty(this.stations);
  }

  get validatedRelationships() {
    return ['stations'];
  }

  rollbackAttributes() {
    this.stations.forEach(station => { station.rollbackAttributes(); });
    super.rollbackAttributes(...arguments);
  }

}
