import { template } from "@ember/template-compiler";
import { fn, get } from '@ember/helper';
import { inject as service } from '@ember/service';
import BarcodeComponentOptions from 'eflex/constants/tasks/jem-barcode-component-options';
import Component from '@glimmer/component';
import { gt, or, and, not } from 'ember-truth-helpers';
import selectVal from 'eflex/helpers/select-val';
import { t } from 'ember-intl';
import BsTooltip from 'eflex/components/bs-tooltip';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
export default class TaskConfigBarcode extends Component {
    @service
    store;
    get currentPassThrough() {
        return BarcodeComponentOptions?.find((item1)=>item1.value === String(this.args.task.passThrough));
    }
    addMultipleStrings = (stringQuantity1)=>{
        const task1 = this.args.task;
        const strings1 = task1.strings;
        const store1 = this.store;
        const taskConfigs1 = task1.taskConfigs;
        for(let i1 = 0; i1 < stringQuantity1; i1++){
            strings1.push(store1.createRecord('barcodeString'));
            taskConfigs1.forEach((config1)=>{
                config1.strings.push(store1.createRecord('barcodeString'));
            });
        }
    };
    removeString = (index1)=>{
        const task1 = this.args.task;
        task1.taskConfigs.forEach((config1)=>{
            config1.strings.splice(index1, 1);
        });
        task1.strings.splice(index1, 1);
    };
    setPassThrough = (value1)=>{
        this.args.task.passThrough = JSON.parse(value1);
    };
    static{
        template(`
    {{#if (and @taskConfig.usesComponents (not @task.component.isAlwaysRun))}}
      <@form.element
        @controlType="radio-group"
        @inline={{true}}
        @property="currentPassThrough"
        @model={{this}}
        @options={{BarcodeComponentOptions}}
        @optionLabelPath="label"
        @onChange={{selectVal this.setPassThrough}}
        class="component-pass-through pb-2"
      as |el|>
        <el.control
          @translate={{true}}
          @inline={{true}}
        />
      </@form.element>
    {{/if}}

    <div class="bootstrap-table">
      <div class="row form-row header-row">
        <div class="col-icon model-specific border-0" />
        <div class="col">
          {{t "startChar"}}
        </div>
        <div class="col model-specific">
          {{t "plant.task.barcode.stringToRead"}}
        </div>
        <div class="col-icon border-0" />
      </div>
      {{#each @taskConfig.strings as |configString index|}}
        <div class="row form-row">
          <div class="col-icon model-specific col-enable">
            <@form.element
              @model={{configString}}
              @controlType="checkbox"
              @property="enabled"
              class="barcode-enabled"
            />
          </div>
          <div class="col">
            <@form.element
              @controlType="number"
              @model={{get @task.strings index}}
              @property="compareLocation"
            as |el|>
              <el.control
                disabled={{or @disabled configString.isRegex}}
                class="start-char"
              />
              {{#if configString.isRegex}}
                <BsTooltip
                  @title={{t "plant.task.barcodereader.startPositionNotUsed"}}
                  @triggerEvents="hover"
                />
              {{/if}}
            </@form.element>
          </div>
          <div class="col model-specific">
            {{#if @task.passThrough}}
              <@form.element @value={{t "componentPassThrough"}} as |el|>
                <el.control
                  class="barcode-string"
                  disabled={{true}}
                />
              </@form.element>
            {{else}}
              <@form.element
                @model={{configString}}
                @property="barcodeString"
              as |el|>
                <el.control class="barcode-string" />
              </@form.element>
            {{/if}}
          </div>
          <div class="col-icon">
            {{#if (gt @taskConfig.strings.length 1)}}
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn this.removeString index}}
              />
            {{/if}}
          </div>
        </div>
      {{/each}}

      <div class="row form-row no-hover-row">
        <div class="col-auto">
          <QuantitySelect
            class="text-uppercase"
            @disabled={{@disabled}}
            @max={{this.remainingStrings}}
            @onSubmit={{this.addMultipleStrings}}
          >
            {{t "plant.task.barcode.addOrString"}}
          </QuantitySelect>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
