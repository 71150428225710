/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { valuesAndLabels } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { getOwner, setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import TaskTypeSelectAddTaddTaskButton from 'eflex/components/task-type-select/add-task-button';
import { pipe, map } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

class TaskTypeDropdownItem {
  @service intl;
  @service licensing;

  @tracked type;

  get name() {
    return this.type.label;
  }

  get value() {
    return this.type.value;
  }

  get disabled() {
    return this.licensing.license?.[`${this.value}Disabled`] ?? false;
  }

  get translatedName() {
    if (this.name != null && (this.intl.exists(this.name) || window.isTesting)) {
      return this.intl.t(this.name);
    } else {
      return null;
    }
  }

  get iconClass() {
    return `${this.value?.toLowerCase()}-icon`;
  }

  get disabledClass() {
    if (this.disabled) {
      return ' disabled';
    } else {
      return null;
    }
  }

  constructor(type) {
    this.type = type;
  }
}

export default class TaskTypeSelect extends Component {
  get triggerComponent() {
    if (this.args.buttonTrigger) {
      return TaskTypeSelectAddTaddTaskButton;
    } else {
      return null;
    }
  }

  get triggerClass() {
    if (this.args.buttonTrigger) {
      return 'add-task btn btn-primary';
    } else {
      return null;
    }
  }

  get content() {
    const owner = getOwner(this);
    return pipe(
      map(type => {
        const item = new TaskTypeDropdownItem(type);
        setOwner(item, owner);
        return item;
      }),
      sortByProp('translatedName'),
    )(valuesAndLabels);
  }
}
