/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
import AnimationEvents from 'eflex/constants/jem/instruction-animation-events';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { any, uniqBy, reject, filter, chain, pipe, prop, findLast, propEq, collectBy } from 'ramda';
import { compact, concatRight } from 'ramda-adjunct';
import { intoArray } from '@eflexsystems/ramda-helpers';

export default class JemInstructionsPane extends Component {
  get matchedAllBoltAnimations() {
    const activeBolt = this.args.childStatus.activeBolt;

    const previousBolt = findLast(
      propEq(activeBolt?.boltNumber - 1, 'boltNumber'),
      this.args.childStatus.boltProcessData ?? [],
    );

    return pipe(
      filter(propEq('all', 'bolt')),
      collectBy(prop('object')),
      intoArray(
        chain(objectAnimations => {
          const animations = filter(animation => {
            switch (animation.event) {
              case AnimationEvents.START: {
                return activeBolt?.isStarted;
              }

              case AnimationEvents.PASS: {
                return previousBolt?.isPassed;
              }

              case AnimationEvents.REJECT: {
                return activeBolt?.isRejected;
              }

              default: {
                return null;
              }
            }
          }, objectAnimations);

          const hasReject = any(propEq(AnimationEvents.REJECT, 'event'), animations);

          return reject(animation => animation.event === AnimationEvents.PASS && hasReject)(animations);
        }),
        compact,
      ),
    )(this.args.displayedConfig?.animations ?? []);
  }

  get matchedBoltAnimations() {
    const animations = this.args.displayedConfig?.animations;
    if (!animations) {
      return [];
    }

    const activeBoltNumber = this.args.childStatus?.activeBolt?.boltNumber;

    const currentBoltProcessData = this.args.childStatus?.groupedBoltProcessData.map(bolts => {
      const bolt = bolts.at(-1);
      if (bolt.isPassed || bolts.length === 1) {
        return bolt;
      } else {
        return bolts.at(-2);
      }
    });

    return intoArray(
      chain(boltData => {
        return pipe(
          intoArray(
            filter(propEq(`${boltData.boltNumber}`, 'bolt')),
            filter(animation => {
              switch (animation.event) {
                case AnimationEvents.START: {
                  // show start animations for an active bolt that is started,
                  // or bolts that have pass or fail data (meaning they were started at one time)
                  return (
                    (boltData.boltNumber === activeBoltNumber && boltData.isStarted) ||
                    boltData.isPassed ||
                    boltData.isRejected
                  );
                }

                case AnimationEvents.PASS: {
                  return boltData.isPassed;
                }

                case AnimationEvents.REJECT: {
                  return boltData.isRejected;
                }

                default: {
                  return null;
                }
              }
            }),
          ),
          collectBy(prop('object')),
          // pass/reject animation supercedes start animation per bolt/object
          chain(objectAnimations => {
            const hasReject = any(prop('isPassOrReject'), objectAnimations);
            return reject(animation => animation.event === AnimationEvents.START && hasReject)(objectAnimations);
          }),
        )(animations);
      }),
      compact,
    )(currentBoltProcessData ?? []);
  }

  get animationsToRun() {
    const matched = [];

    if (!(this.args.childStatus != null && !isEmpty(this.args.displayedConfig?.animations))) {
      return matched;
    }

    return pipe(
      concatRight(this.matchedBoltAnimations),
      concatRight(this.matchedAllBoltAnimations),
      reject(prop('isDeleted')),
      uniqBy(animation => `${animation.object}${animation.bolt}${animation.event}`),
    )(matched);
  }
}
