/* import __COLOCATED_TEMPLATE__ from './serial-display.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

class JemSerialDisplay extends Component {
  @service intl;

  get displayedSerialNumber() {
    const serialNumber = this.args.buildStatus?.serialNumber;

    if (serialNumber != null) {
      return serialNumber;
    } else if (this.args.buildStatus != null) {
      return this.intl.t('controls.ajaxindicator.loading');
    } else {
      return this.intl.t('plant.station.jem.unknown');
    }
  }
}

export default JemSerialDisplay;
