import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import { TriggerEvents, StationEvents, OeeEvents } from 'eflex/constants/tasks/trigger-events';
import Component from '@glimmer/component';
import AdamIo from 'eflex/components/trigger-configs/adam-io';
import Audio from 'eflex/components/trigger-configs/audio';
import Edhr from 'eflex/components/trigger-configs/edhr';
import LightGuide from 'eflex/components/trigger-configs/light-guide';
import NodeRed from 'eflex/components/trigger-configs/node-red';
import PlcHardware from 'eflex/components/trigger-configs/plc-hardware';
import TurckIo from 'eflex/components/trigger-configs/turck-io';
import { dasherize } from '@ember/string';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import HardwareSelect from 'eflex/components/hardware-select';
import TrashCan from 'eflex/components/trash-can';
import { fn } from '@ember/helper';
const ALLOWED_TRIGGER_TYPES = [
    'light-guide',
    'adam-io',
    'edhr',
    'audio',
    'plc-hardware',
    'turck-io',
    'node-red'
];
const OEE_ALLOWED_TRIGGER_TYPES = [
    'light-guide',
    'adam-io',
    'audio',
    'plc-hardware',
    'turck-io',
    'node-red'
];
const HIDDEN_TRIGGER_CONFIG_TYPES = new Set([
    'work-instruction-hardware-trigger-config'
]);
export default class TriggerConfigs extends Component {
    @service
    triggerRepo;
    get useModelSpecific() {
        return this.args.useModelSpecific ?? true;
    }
    get usesHardwareTypes() {
        return this.args.location.type === 'task' && this.args.location.usesOperations;
    }
    get nonDeletedTriggerConfigs() {
        return this.args.triggerConfigs?.filter((item1)=>!item1.isDeleted) ?? [];
    }
    get triggerEvents() {
        if (this.args.isOeeEvent) {
            return OeeEvents;
        } else if (this.args.isStationEvent) {
            return StationEvents;
        } else {
            return TriggerEvents;
        }
    }
    get visibleTriggerConfigs() {
        return this.nonDeletedTriggerConfigs.filter((triggerConfig1)=>!HIDDEN_TRIGGER_CONFIG_TYPES.has(triggerConfig1.constructor.modelName));
    }
    get allowedHardwareTypes() {
        return this.args.isOeeEvent ? OEE_ALLOWED_TRIGGER_TYPES : ALLOWED_TRIGGER_TYPES;
    }
    getTriggerComponent = (triggerConfig1)=>{
        let modelName1;
        if (triggerConfig1.hardware) {
            modelName1 = triggerConfig1.hardware.constructor.modelName;
        } else {
            modelName1 = dasherize(triggerConfig1.parentTrigger.hardwareAssignedType);
        }
        switch(modelName1){
            case 'adam-io':
                {
                    return AdamIo;
                }
            case 'audio':
                {
                    return Audio;
                }
            case 'edhr':
                {
                    return Edhr;
                }
            case 'light-guide':
                {
                    return LightGuide;
                }
            case 'node-red':
                {
                    return NodeRed;
                }
            case 'plc-hardware':
                {
                    return PlcHardware;
                }
            case 'turck-io':
                {
                    return TurckIo;
                }
            default:
                {
                    return null;
                }
        }
    };
    addTrigger = (hardware1)=>{
        this.triggerRepo.createTrigger(hardware1, this.args.location);
    };
    deleteTrigger = (trigger1)=>{
        trigger1.triggerConfigs.forEach((triggerConfig1)=>{
            triggerConfig1.deleteRecord();
        });
        trigger1.deleteRecord();
    };
    static{
        template(`
    <div class="component-trigger-configs trigger-configs bg-gray-100 flex-fill" ...attributes>
      <div class="bootstrap-table">
        <BsForm
          @model={{this}}
          @disabled={{@disabled}}
          @showValidations={{@showValidations}}
        as |form|>
          <div class="row form-row header-row">
            <div class="col-3">
              {{t "hardware"}}
            </div>
            {{#if this.usesHardwareTypes}}
              <div class="col-1">
                {{t "hardwareNumber"}}
              </div>
            {{/if}}
            <div class="col-1">
              {{t "event"}}
            </div>
            <div class="col">
              {{t "config"}}
            </div>
            <div class="col-icon border-0" />
          </div>
          <HardwareSelect
            @form={{form}}
            @usesOperations={{this.usesHardwareTypes}}
            @types={{this.allowedHardwareTypes}}
            @invisibleLabel={{true}}
            @required={{true}}
          as |hardwareSelect|>
            {{#each this.visibleTriggerConfigs as |triggerConfig|}}
              {{#let triggerConfig.parentTrigger as |trigger|}}
                <div class="trigger-config row form-row align-items-end">
                  <div class="col-3">
                    <form.element
                      @controlType="checkbox"
                      @model={{triggerConfig}}
                      @property="enabled"
                      @label={{if this.usesHardwareTypes (t trigger.hardwareAssignedType) trigger.hardware.name}}
                    />
                  </div>
                  <hardwareSelect.index
                    class='col-1'
                    @model={{trigger}}
                    @size='sm'
                  />
                  <div class="col-1">
                    <form.element
                      @controlType="power-select"
                      @options={{this.triggerEvents}}
                      @model={{trigger}}
                      @property="event"
                      class="trigger-event"
                    as |el|>
                      <el.control
                        @triggerClass="form-control form-control-sm"
                      as |triggerEvent|>
                        {{t triggerEvent}}
                      </el.control>
                    </form.element>
                  </div>
                  <div class="col">
                    {{#let (this.getTriggerComponent triggerConfig) as |TriggerComponent|}}
                      {{#if TriggerComponent}}
                        <TriggerComponent
                          @form={{form}}
                          @triggerConfig={{triggerConfig}}
                          @useModelSpecific={{this.useModelSpecific}}
                          @isStationEvent={{@isStationEvent}}
                          @isOeeEvent={{@isOeeEvent}}
                        />
                      {{/if}}
                    {{/let}}
                  </div>

                  <div class="col-icon ps-0 mb-1">
                    <TrashCan
                      class="delete-trigger"
                      @onDelete={{fn this.deleteTrigger trigger}}
                    />
                  </div>
                </div>
              {{/let}}
            {{/each}}
            <div class="row form-row no-hover-row pt-3">
              <div class="col-auto">
                <hardwareSelect.select
                  class='test-add-trigger'
                  @controlType='button-power-select'
                  @usesOperations={{this.usesHardwareTypes}}
                  @placeholder={{t "addEvent"}}
                  @onChange={{this.addTrigger}}
                />
              </div>
            </div>
          </HardwareSelect>
        </BsForm>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
