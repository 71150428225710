import Model, { attr, hasMany } from '@ember-data/model';
import { isBlank, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { arrayAttr, caption } from 'eflex/decorators';
import { dpPluck, intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
import { map } from 'ramda';

class Hardware extends Model {
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean', { defaultValue: false }) isConnected;
  @attr('string') connectionError;
  @arrayAttr captions;
  @caption name;

  @hasMany('task', { async: false, inverse: 'hardware', as: 'hardware' }) tasks;
  @hasMany('hardwareIo', { async: false, inverse: 'hardware', as: 'hardware' }) hardwareIos;

  @hasMany('stationHardware', {
    async: false,
    inverse: 'hardware',
    as: 'hardware',
    cascadeDelete: true,
  }) stationHardwares;

  @hasMany('trigger', {
    async: false,
    polymorphic: true,
    as: 'hardware',
    inverse: 'hardware',
    cascadeDelete: true,
  }) triggers;

  @service intl;
  @service taskRepo;
  @service stationRepo;
  @service hardwareInputDefRepo;

  get polymorphic() {
    return true;
  }

  // must be implemented by base class
  get isConfigured() {
    return false;
  }

  get hasError() {
    return isPresent(this.connectionError);
  }

  get isInUse() {
    return isPresent(this.allStationOptions);
  }

  get taskStations() {
    return this.tasks?.map(item => item.parent);
  }

  get triggerDisplayName() {
    return this.name;
  }

  get attachedStations() {
    return [...new Set(this.allStationOptions)];
  }

  get displayName() {
    let displayName = this.intl.t(camelize(this.constructor.modelName));

    if (!isBlank(this.name)) {
      displayName = `${displayName} - ${this.name}`;
    }

    return displayName;
  }

  get displayType() {
    return this.intl.t(camelize(this.constructor.modelName));
  }

  get triggerStations() {
    return intoArray(
      dpPluck('task.parent'),
      compact,
    )(this.triggers);
  }

  get hardwareOutputStations() {
    return intoArray(
      map(hardwareIo =>
        this.taskRepo.tasks.find(task => task.hardwareOutputs.includes(hardwareIo))?.parent,
      ),
      compact,
    )(this.hardwareIos);
  }

  get hardwareInputStations() {
    return intoArray(
      map(hardwareIo =>
        this.taskRepo.tasks.find(item => item.hardwareInput === hardwareIo)?.parent,
      ),
      compact,
    )(this.hardwareIos);
  }

  get hardwareInputDefStations() {
    return intoArray(
      map(hardwareIo =>
        this.hardwareInputDefRepo.hardwareInputDefs.find(item => item.input === hardwareIo)?.task?.parent,
      ),
      compact,
    )(this.hardwareIos);
  }

  get stationCameras() {
    return this.stationRepo.stations.filter(item => item.webCam === this);
  }

  get allStationOptions() {
    const taskStations = this.taskStations ?? [];

    return taskStations
      .concat(this.hardwareOutputStations ?? [])
      .concat(this.hardwareInputStations ?? [])
      .concat(this.hardwareInputDefStations ?? [])
      .concat(this.stationCameras ?? []);
  }
}

export default Hardware;
