import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
const JemTaskPrintDetails = template(`
<div class="detail-section" ...attributes>
  <div class="detail-text-label printer-name">
    {{@hardware.cupsName}}
  </div>
  <div class="d-flex align-items-baseline">
    <div class="detail-text-value status success print-copies me-2">
      {{@task.printCopies}}
    </div>
    <div class="detail-text-small">
      {{t "plant.task.printCopies"}}
    </div>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPrintDetails
        task=@task
        hardware=@hardware
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
