import { later } from '@ember/runloop';

export const schedulePush = (record, cb) => {
  if (record == null) {
    cb();
    return;
  }

  if (
    record.isDestroyed ||
    !record.isLoaded ||
    (!record.isDirty && record.isDeleted) ||
    (record.isSaving && record.isDeleted)
  ) {
    return;
  }

  if (record?.isSaving) {
    // eslint-disable-next-line ember/no-runloop
    later(null, () => {
      schedulePush(record, cb);
    }, 10);
  } else {
    cb();
  }
};

export const updateFromWebSocket = (type, store, updatedRecord) => {
  const id = updatedRecord.id ?? updatedRecord._id;
  let record = store.peekRecord(type, id);

  schedulePush(record, () => {
    record = store.push(store.normalize(type, updatedRecord));
  });

  return record;
};
