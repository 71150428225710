/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class InputsLayerArrangement extends Component {
  @service imageEditor;

  @action
  sendToBack() {
    const activeObject = this.imageEditor.canvas.getActiveObject();
    this.imageEditor.sendToBack(activeObject);
  }

  @action
  bringToFront() {
    const activeObject = this.imageEditor.canvas.getActiveObject();
    this.imageEditor.bringToFront(activeObject);
  }

  @action
  sendBackwards() {
    const activeObject = this.imageEditor.canvas.getActiveObject();
    this.imageEditor.sendBackwards(activeObject);
  }

  @action
  bringForward() {
    const activeObject = this.imageEditor.canvas.getActiveObject();
    this.imageEditor.bringForward(activeObject);
  }
}
