/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Tokens from 'eflex/constants/date-picker-tokens';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class DatePickerWithTokens extends Component {
  tokens = Tokens;

  @action
  selectToken(token) {
    this.args.onChangeStartDate(token.startDate());
    this.args.onChangeEndDate(token.endDate());
    this.args.onChangeSelectedToken(token);
  }

  @action
  setStartDate(date) {
    this.args.onChangeStartDate(date);
    this.args.onChangeSelectedToken(null);
  }

  @action
  setEndDate(date) {
    this.args.onChangeEndDate(date);
    this.args.onChangeSelectedToken(null);
  }
}
