import GridRoute from 'eflex/util/grid-route';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class EvisionImageRetrievalRoute extends GridRoute {
  @service store;

  getData = task(waitFor(async (params = {}) => {
    const results = await this.store.query('evisionImage', params);
    const data = new TrackedArray(results);

    data.meta = { count: results.meta?.count ?? 0 };

    return data;
  }));
}
