import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class SettingsServerController extends Controller {
  @service currentUser;
  @service systemConfig;

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get isDirty() {
    return this.systemConfiguration.isDirty;
  }

  get disabled() {
    return this.currentUser.isNotAdmin;
  }

  save = task(waitFor(async () => {
    await this.systemConfiguration.save();
  }));

  @action
  rollback() {
    this.systemConfiguration.rollbackAttributes();
  }
}
