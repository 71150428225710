import { template } from "@ember/template-compiler";
import { fn, get, hash } from '@ember/helper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import getHardwareInputDefIo from 'eflex/helpers/get-hardware-input-def-io';
import { and, eq } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
const CheckButton = template(`
<BsButton
  class='complete-jem-task check-button'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete @taskStatuses.NOT_RECEIVED}}
  ...attributes
>
  {{yield}}
  {{t 'check'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RecheckButton = template(`
<BsButton
  class='restart-jem-task recheck-button'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete @taskStatuses.NOT_RECEIVED}}
  ...attributes
>
  {{yield}}
  {{t 'recheck'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const JemTaskErrorProofingDetails = template(`
<div
  class="component-jem-task-error-proofing-details"
  ...attributes
>
  {{#each @task.hardwareInputDefs as |hardwareInputDef index|}}
    {{#let
      (get @childStatus.errorProofingProcessData index)
      (findBy "taskConfig" @taskConfig hardwareInputDef.hardwareInputDefConfigs)
      (getHardwareInputDefIo @station hardwareInputDef)
    as |data config input|}}
      <div class="error-proofing-input">
        <div class="detail-section">
          <div class="detail-text-label input-name">
            {{input.name}}
          </div>
          <div class="d-flex align-items-baseline">
            <div class="col-state expected-state">
              <div class="detail-text-small">
                {{t "expecting"}}
              </div>
              <div class="d-flex align-items-baseline">
                <div class="expected-value fst-normal detail-text-value text-uppercase">
                  {{config.state}}
                </div>
                {{#if (and config.changeState (eq @taskConfig.task.triggerType "jemButton"))}}
                  <div class="ms-2">
                    {{#if (eq config.state "on")}}
                      <FaIcon @icon="arrow-up" @prefix="fas" />
                    {{else}}
                      <FaIcon @icon="arrow-down" @prefix="fas" />
                    {{/if}}
                  </div>
                {{/if}}
              </div>
            </div>
            <div class="col-state current-state">
              <div class="detail-text-small">
                {{t "current"}}
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="
                    current-value
                    detail-text-value
                    me-2
                    text-uppercase
                    {{if data.isPassed "jem-text-success"}}
                    {{if data.isFailed "jem-text-danger"}}
                  "
                >
                  {{data.state}}
                </div>
                {{#if data.isPassed}}
                  <div class="grid-icon-green-checkmark m-0"></div>
                {{/if}}
                {{#if data.isFailed}}
                  <div class="grid-icon-danger-alert-red m-0"></div>
                {{/if}}
              </div>
            </div>
          </div>
        </div>
      </div>
    {{/let}}
  {{/each}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      okButtonOverride=(component CheckButton)
      retryButtonOverride=(component RecheckButton)
      taskDetails=(component
        JemTaskErrorProofingDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        station=@station
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
