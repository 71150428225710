import { task, waitForQueue } from 'ember-concurrency';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { removeObject, removeObjects } from 'eflex/util/array-helpers';
import { isEmpty } from '@ember/utils';
import { PAGE_SIZE } from 'eflex/constants/pagination';
import SerialNumberStatuses from 'eflex/constants/serial-number-statuses';

export default class SerialNumberMonitorBomSourceController extends Controller {
  @service buildDataRepo;
  @service validationErrorNotifier;
  @service currentUser;
  @service store;
  @service eventBus;
  @service notifier;

  queryParams = ['serialNumber', 'statusCategory'];

  @tracked serialNumber;
  @tracked buildData = new TrackedArray();
  @tracked selectedBomSource;
  @tracked selectedSerialNumber;
  @tracked panelBuildDatum;
  @tracked datumToCopy;
  @tracked showBomCopy = false;
  @tracked showBomBuilder = false;
  @tracked statusCategory = SerialNumberStatuses.Queued.status;
  @tracked currentSerialNumberOptionsTab = 'comp-opt-data';

  get isDirty() {
    return this.buildData.some(item => item.isDirty);
  }

  get isInvalid() {
    return this.buildData
      .filter(item => item.isDirty)
      .some(item => item.isInvalid);
  }

  get isEditDisabled() {
    return this.currentUser.user.isNotAdmin || !this.selectedSerialNumber;
  }

  get uniqComponents() {
    const selectedSerialNumber = this.selectedSerialNumber;
    const components = selectedSerialNumber.bomSource.components.filter(item => !item.isDeleted);

    return selectedSerialNumber.components
      ?.filter(item => !item.isAlwaysRun)
      .filter(component =>
        components.some(item => item.id === component.id),
      );
  }

  fetch = task(waitFor(async pagination => {
    const data = await this.store.query('buildDatum', {
      bomSource: this.selectedBomSource.id,
      statusCategory: this.statusCategory,
      serialNumber: this.serialNumber,
      ...pagination,
    });

    this.buildData.push(...data);
  }));

  search = task(waitFor(async () => {
    this.clean();

    try {
      this.buildData = new TrackedArray(await this.store.query('buildDatum', {
        bomSource: this.selectedBomSource.id,
        statusCategory: this.statusCategory,
        serialNumber: this.serialNumber,
        skip: 0,
        take: PAGE_SIZE,
      }));

      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    }

    if (isEmpty(this.buildData)) {
      this.notifier.sendError('logs.noData');
      return;
    }

    this.selectedSerialNumber = this.buildData[0];
  }));

  selectStatusCategory = task(waitFor(async statusCategory => {
    this.statusCategory = statusCategory;
    await this.search.perform();
  }));

  clean() {
    // using unloadAll throws an assertion when saving the same reloaded records later
    this.buildDataRepo.buildData.forEach(buildData => {
      buildData.unloadRecord();
    });

    Object.assign(this, {
      selectedSerialNumber: null,
      buildData: new TrackedArray(),
      panelBuildDatum: null,
    });
  }

  @action
  deleteSerial() {
    if (this.selectedSerialNumber?.isNew) {
      removeObject(this.buildData, this.selectedSerialNumber);
      this.selectedSerialNumber.rollbackAttributes();
    } else {
      this.selectedSerialNumber.deleteRecord();
    }

    this.selectedSerialNumber = null;
    this.closeBomBuilder();
  }

  save = task(waitFor(async () => {
    const toSave = this.buildData.filter(item => item.isDirty);

    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(toSave);
      return;
    }

    removeObjects(this.buildData, toSave.filter(saved => saved.isDeleted));

    await this.buildDataRepo.save.perform(toSave);
  }));

  addNewSerial = task(waitFor(async () => {
    const newDatum = this.buildDataRepo.create(this.selectedBomSource);

    this.buildData.push(newDatum);

    Object.assign(this, {
      selectedSerialNumber: newDatum,
      panelBuildDatum: newDatum,
      showBomBuilder: true,
    });

    const grid = document.querySelector('.serial-number-status-grid .ember-grid-component');

    if (grid == null) {
      return;
    }

    await waitForQueue('afterRender');

    grid.scrollTo({
      top: grid.scrollHeight,
      behavior: 'smooth',
    });
  }));

  @action
  editSerial() {
    Object.assign(this, {
      panelBuildDatum: this.selectedSerialNumber,
      showBomBuilder: true,
    });
  }

  @action
  copySerial() {
    Object.assign(this, {
      datumToCopy: this.selectedSerialNumber,
      showBomBuilder: false,
      showBomCopy: true,
    });
  }

  @action
  closeBomBuilder() {
    Object.assign(this, {
      panelBuildDatum: null,
      showBomBuilder: false,
    });
  }

  @action
  closeBomCopy() {
    Object.assign(this, {
      datumToCopy: null,
      showBomCopy: false,
    });
  }

  @action
  updateBuildData(newBuildData) {
    this.buildData.push(...newBuildData);
  }

  @action
  rollback() {
    if (this.panelBuildDatum?.isNew) {
      this.closeBomBuilder();
    }

    this.buildData.filter(item => item.isDirty).forEach(buildData => { buildData.rollbackAttributes(); });
    this.buildData = new TrackedArray(this.buildData.filter(item => !item.isDeleted));

    if (this.selectedSerialNumber?.isDeleted && !this.buildData[0]?.isDeleted) {
      this.selectedSerialNumber = this.buildData[0];
    } else {
      this.selectedSerialNumber = null;
    }
  }

  @action
  swapSelected(selectedDatum) {
    this.selectedSerialNumber = selectedDatum;
    if (this.showBomBuilder && selectedDatum.userCreated) {
      this.panelBuildDatum = selectedDatum;
    } else if (this.showBomBuilder) {
      this.closeBomBuilder();
    } else if (this.showBomCopy) {
      this.closeBomCopy();
    }
  }
}
