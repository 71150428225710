/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { sortByProp } from 'ramda-adjunct';

export default class CriteriaVisorTreeItem extends Component {
  @tracked _expanded = false;

  get isChecked() {
    let children;
    const treeItem = this.args.treeItem;

    if (treeItem.type === this.args.type) {
      children = [treeItem];
    } else if (this.args.type === 'station') {
      children = treeItem.stations;
    } else {
      children = treeItem.tasks;
    }

    const selectedTreeItems = this.args.selectedTreeItems;
    return children.some(child => selectedTreeItems.has(child));
  }

  // jscpd:ignore-start
  get expanded() {
    return this._expanded || !isBlank(this.args.searchTerm);
  }

  get filteredChildren() {
    let sortedChildren;

    if (this.args.treeItem.type === 'station') {
      sortedChildren = this.args.treeItem.sortedChildren;
    } else {
      sortedChildren = sortByProp('order', this.args.treeItem.children ?? []);
    }

    if (isBlank(this.args.searchTerm)) {
      return sortedChildren;
    }

    const searchRegex = new RegExp(this.args.searchTerm, 'i');

    return sortedChildren.filter(treeItem =>
      searchRegex.test(treeItem.name) ||
      treeItem.children?.some(_treeItem => searchRegex.test(_treeItem.name)) ||
      treeItem.tasks?.some(_treeItem => searchRegex.test(_treeItem.name)),
    );
  }

  get hasChildren() {
    if (this.args.treeItem.type === this.args.type) {
      return false;
    }

    return !isEmpty(this.args.treeItem.children);
  }

  get expandedClass() {
    if (!this.expanded) {
      return null;
    }

    if (this.args.treeItem.type === 'area' || this.args.treeItem.type === 'group') {
      return 'tree-toggle-down';
    } else {
      return 'tree-station-opened';
    }
  }
  // jscpd:ignore-end

  constructor() {
    super(...arguments);
    this._expanded = this.isChecked;
  }

  @action
  expand() {
    this._expanded = !this._expanded;
  }
}
