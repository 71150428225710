/* import __COLOCATED_TEMPLATE__ from './user-config.hbs'; */
import UserPermissions from 'eflex/constants/user-permissions';
import { userRoles, userRolesValues } from 'eflex/constants/user-roles';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { pipe, map } from 'ramda';
import { sortByProp } from 'ramda-adjunct';

export default class UserConfigPanel extends Component {
  @service intl;
  @service licensing;
  @service workInstructionRepo;
  @service userRepo;
  @service currentUser;

  get userRoleOptions() {
    return userRolesValues;
  }

  get permissionOptions() {
    return pipe(
      map(permission => {
        permission.translatedName = this.intl.t(permission.name);
        return permission;
      }),
      sortByProp('translatedName'),
    )([...UserPermissions]);
  }

  get selectedPermissions() {
    return this.args.selectedUser?.permissions.map(permission =>
      UserPermissions.find(item => item.value === permission),
    );
  }

  @action
  setPermissions(permissions) {
    this.args.selectedUser.permissions = permissions.map(item => item.value);
  }

  @action
  setUserRole(role) {
    if (role === userRoles.ViewOnly.label) {
      Object.assign(this.args.selectedUser, {
        visibilityTags: [],
        authorizationTags: [],
        wieEditorTags: [],
        wieApproverTags: [],
        wieDeployerTags: [],
      });
    }

    this.args.selectedUser.role = role;
  }
}
