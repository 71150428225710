/* import __COLOCATED_TEMPLATE__ from './text-field.hbs'; */
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CriteriaVisorTextField extends Component {
  @service intl;

  @tracked isDisplayed = Boolean(this.value);

  get value() {
    return this.args[this.args.key];
  }

  get breadcrumb() {
    if (isBlank(this.value)) {
      return null;
    }

    return this.intl.t(`breadcrumbs.${this.args.key}`, { [this.args.key]: this.value });
  }

  constructor() {
    super(...arguments);
    this.args.initParam(this.args.key, this.value);
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.updateParam(this.args.key, null);
  }

  @action
  onChange(value) {
    this.args.updateParam(this.args.key, value);
  }
}
