/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { waitFor } from '@ember/test-waiters';

export default class ThreedViewer extends Component {
  _previousUrl = this.args.url;
  _viewer;

  renderViewer = task({ enqueue: true }, waitFor(async (element, [url]) => {
    if (window.isTesting) {
      return;
    }

    this._previousUrl = url;
    this._viewer?.dispose();

    const { DefaultViewer } = await import(
      /* webpackExports: ["DefaultViewer"] */
      'babylonjs-viewer'
    );

    this._viewer = new DefaultViewer(element, {
      model: url,
      templates: {
        main: {
          params: {
            fillScreen: true,
          },
        },
        navBar: {
          params: {
            hideVr: true,
            hideHd: true,
            hideHelp: true,
            hideAnimations: true,
            hideLogo: true,
            hideFullscreen: true,
          },
        },
        help: false,
        share: false,
      },

      camera: {
        behaviors: {
          autoRotate: false,
        },
      },

      environmentMap: {
        texture: 'babylon/envmap.env',
      },

      skybox: {
        cubeTexture: {
          url: 'babylon/skybox.dds',
        },
      },

      ground: {
        texture: 'babylon/ground.png',
      },

      scene: {
        assetsRootURL: '/',
      },
    });
  }));

  updateRender = task({ enqueue: true }, waitFor(async (element, [url]) => {
    if (this._previousUrl === url) {
      return;
    }

    await this.renderViewer.perform(element, [url]);
  }));

  willDestroy() {
    super.willDestroy(...arguments);
    this._viewer?.dispose();
  }
}
