/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import { tracked } from '@glimmer/tracking';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import Levels from 'eflex/constants/level-select-levels';
import generateDataForBiTimeChart from 'eflex/util/generate-data-for-bi-time-chart';
import FormatxAxisTimestamps from 'eflex/util/bi-format-xAxis-Timestamps';
import { waitFor } from '@ember/test-waiters';

export default class QualityTimeBarChartComponent extends Component {
  @service queryRunner;
  @service intl;

  @tracked tagName = '';
  @tracked chartData = null;
  @tracked selectedLevel = 'Day';

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  // jscpd:ignore-start
  getData = task({ drop: true }, waitFor(async params => {
    const qualityChartQuery = await this.queryRunner.queryWithParams.perform(params, [
      {
        $group: {
          _id: {
            $dateToString: {
              format: Levels[this.selectedLevel],
              date: '$timestamp',
            },
          },
          totalParts: {
            $sum: 1,
          },
          rejectCount: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    { $gte: ['$status', TaskStatuses.REJECT_RANGE_START] },
                    { $lte: ['$status', TaskStatuses.REJECT_RANGE_END] },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
          passCount: {
            $sum: {
              $cond: {
                if: {
                  $and: [
                    { $gte: ['$status', TaskStatuses.GOOD_RANGE_START] },
                    { $lte: ['$status', TaskStatuses.GOOD_RANGE_END] },
                  ],
                },
                then: 1,
                else: 0,
              },
            },
          },
        },
      },
      {
        $addFields: {
          date: '$_id',
          quality: {
            $round: [
              {
                $multiply: [{ $divide: ['$passCount', '$totalParts'] }, 100],
              },
              1,
            ],
          },
        },
      },
      {
        $sort: {
          date: 1,
        },
      },
      {
        $project: {
          _id: 0,
        },
      },
    ]);

    const qualityChartData = await this.queryRunner.runQuery.perform('BuildStatuses', qualityChartQuery);
    const chartData = generateDataForBiTimeChart(qualityChartData, this.selectedLevel);

    return {
      chartData: this.getEchartOptions(chartData),
      exportData: () => chartData,
    };
  }));
  // jscpd:ignore-end

  qualityData = trackedTask(this, this.getData, () => [this.params, this.selectedLevel]);

  getEchartOptions(qualityChartData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.qualityTime'),
      },

      legend: {
        show: true,
        bottom: 5,
      },

      tooltip: {
        trigger: 'axis',
      },

      xAxis: FormatxAxisTimestamps(qualityChartData, this.selectedLevel),

      yAxis: [
        {
          type: 'value',
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: '{value}%',
          },
        },
        {
          type: 'value',
          show: false,
        },
      ],

      series: [
        {
          name: this.intl.t('bi.chartLegend.timePeriodTotalParts'),
          data: qualityChartData.map((m) => m.totalParts ?? 0),
          type: 'line',
          yAxisIndex: 1,
          color: EchartsTheme.colorPalette.biBlue,
        },
        {
          name: this.intl.t('bi.chartLegend.timePeriodRejects'),
          data: qualityChartData.map((m) => m.rejectCount ?? 0),
          type: 'line',
          yAxisIndex: 1,
          color: EchartsTheme.colorPalette.danger,
        },
        {
          name: this.intl.t('bi.chartLegend.timePeriodQuality'),
          data: qualityChartData.map((m) => m.quality ?? 0),
          type: 'bar',
          yAxisIndex: 0,
          color: EchartsTheme.colorPalette.success,
        },
      ],
    };
  }
}
