import { template } from "@ember/template-compiler";
import BsPopover from 'eflex/components/bs-popover';
import { withDefault, isPresent } from '@eflexsystems/ember-composable-helpers';
import Link from 'eflex/components/link';
export default template(`
<a
  tabindex='0'
  class="badge bg-{{withDefault @type 'primary'}} pointer text-decoration-none"
  ...attributes
>
  {{@label}}
  {{#if (isPresent @options)}}
    <BsPopover @triggerEvents='focus'>
      {{#each @options as |option|}}
        <div class="mb-1">
          <Link @route={{@route}} @model={{option.id}}>{{yield option}}</Link>
        </div>
      {{/each}}
    </BsPopover>
  {{/if}}
</a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
