import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';

export default class KineticOperationsController extends Controller {
  @service kineticRepo;
  @service router;
  @controller('kineticOperations/operation') operationController;

  get bomIsSyncing() {
    return this.kineticRepo.kinetic.bom.isSyncing;
  }

  @action
  loadPart(part) {
    Object.assign(this.operationController, {
      selectedPart: part,
      selectedPartRev: null,
      operation: null,
    });
    this.router.transitionTo('kineticOperations');
  }

  @action
  loadPartRev(partRev) {
    Object.assign(this.operationController, {
      selectedPartRev: partRev,
      operation: null,
    });
    this.router.transitionTo('kineticOperations');
  }

  @action
  loadOperation(operation) {
    if (isEmpty(operation)) {
      this.operationController.operation = null;
      this.router.transitionTo('kineticOperations');
    } else {
      this.router.transitionTo('kineticOperations.operation', operation);
    }
  }

  sync = task(async () => {
    await this.kineticRepo.syncBom.perform();
  });
}
