import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { all, task, waitForQueue } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';

export default class StationWebSocketListener extends Helper {
  @service webSocket;

  #previousStationId;
  #rooms;

  get isLoading() {
    return this._setup.isRunning;
  }

  compute(positional, { station, roomPrefixes, ...actions }) {
    this._setup.perform(station, roomPrefixes, actions);
  }

  _setup = task(waitFor(async (station, roomPrefixes, actions) => {
    if (!station || station.id === this.#previousStationId) {
      return;
    }

    const isNew = this.#rooms == null;
    const events = Object.keys(actions);

    await waitForQueue('routerTransitions');

    if (isNew) {
      registerDestructor(this, () => {
        this.webSocket.removeListeners(events);
        this.#rooms.forEach(room => {
          this.webSocket.disconnectFromRoom(room);
        });
      });

      for (const event of events) {
        this.webSocket.addListener(event, actions[event]);
      }
    } else {
      roomPrefixes
        .forEach(roomPrefix => {
          const previousRoom = `${roomPrefix}-${this.#previousStationId}`;
          this.webSocket.disconnectFromRoom(previousRoom);
        });
    }

    this.#previousStationId = station.id;
    const rooms = this.#rooms = roomPrefixes.map(roomPrefix => `${roomPrefix}-${station.id}`);

    await all(rooms.map(room => this.connectToRoom.perform(room)));

    return this;
  }));

  connectToRoom = task(waitFor(async room => {
    await this.webSocket.connectToRoom.perform(room);
  }));
}
