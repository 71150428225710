/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import getDelayTime from 'eflex/util/get-delay-time';
import $ from 'jquery';
import { all, task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorLibraryThumbView extends Component {
  @service workInstructionRepo;

  @tracked isDragging = false;

  scrollingActive = false;
  #scrollContainerSelector = '.library-scroll-container';

  scrollTime = getDelayTime(600);

  _dragScroll = task(waitFor(async scrollAmount => {
    while (this.scrollingActive) {
      // eslint-disable-next-line ember/no-jquery
      $(this.#scrollContainerSelector).animate({ scrollTop: scrollAmount }, this.scrollTime);
      await timeout(this.scrollTime);
    }
  }));

  scrollDown = task({ drop: true }, waitFor(async () => {
    this.scrollingActive = true;
    await this._dragScroll.perform('+=300px');
  }));

  scrollUp = task({ drop: true }, waitFor(async () => {
    this.scrollingActive = true;
    await this._dragScroll.perform('-=300px');
  }));

  stopScroll = task(waitFor(async () => {
    this.scrollingActive = false;
    await all([
      this._dragScroll.cancelAll({ resetState: true }),
      this.scrollDown.cancelAll({ resetState: true }),
      this.scrollUp.cancelAll({ resetState: true }),
    ]);
  }));

  willDestroy() {
    super.willDestroy(...arguments);
    // eslint-disable-next-line ember/no-jquery
    $(this.#scrollContainerSelector).clearQueue();
  }

  @action
  libraryCardDraggable(workInstruction) {
    return this.workInstructionRepo.userCanEdit(workInstruction);
  }

  @action
  dropOnFolder(folder, workInstruction) {
    this.isDragging = false;
    this.args.dropOnFolder(folder, workInstruction);
  }
}
