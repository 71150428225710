import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

class KineticAssemblyOperation extends Model {
  @attr('number', { defaultValue: 0 }) sequence;
  @attr('number', { defaultValue: 0 }) runQuantity;
  @attr('number', { defaultValue: 0 }) completedQuantity;
  @attr('number', { defaultValue: 0 }) reportedQuantity;

  @belongsTo('kineticOperation', { async: false, inverse: null }) operation;
  @belongsTo('kineticJobAssembly', { async: false, inverse: 'assemblyOperations' }) assembly;
  @hasMany('station', { async: false, inverse: null }) stations;

  get quantityToReport() {
    return this.completedQuantity - this.reportedQuantity;
  }

  get job() {
    return this.assembly.job;
  }

  get name() {
    return `${this.sequence}: ${this.operation.name}`;
  }

  get part() {
    return this.assembly.part;
  }

  get partRev() {
    return this.assembly.partRev;
  }

  get quantityCount() {
    return `${this.completedQuantity}/${this.runQuantity}`;
  }

  get nextAssemblyOp() {
    return this.assembly.getAssemblyOpAfter(this);
  }
}

export default KineticAssemblyOperation;
