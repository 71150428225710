/* import __COLOCATED_TEMPLATE__ from './tab-config-modal.hbs'; */
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

export default class JemTabConfigModal extends Component {
  save = task({ drop: true }, waitFor(async () => {
    if (this.args.tab.isInvalid) {
      return;
    }

    await this.args.station.save();
    this.args.closeModal?.(this.args.tab);
  }));

  @action
  cancel() {
    this.args.station.rollbackAttributes();
    this.args.closeModal();
  }
}
