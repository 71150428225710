import { template } from "@ember/template-compiler";
import { uniqueId, get, hash } from '@ember/helper';
import { t } from 'ember-intl';
import { notEq, or, not, eq, and } from 'ember-truth-helpers';
import { last, reverse, findBy } from '@eflexsystems/ember-composable-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import JemBolt from 'eflex/components/jem/bolt';
const JemTaskTorqueDetails = template(`
<div class="component-jem-task-torque-details" ...attributes>
  <div class="detail-section">
    <div class="pset detail-text-label">
      {{t "plant.station.program"}}
    </div>
    <div class="detail-text-value">
      {{@taskConfig.programNumber}}
    </div>
  </div>
  <div class="jem-bolts bg-white">
    {{#each @childStatus.groupedBoltProcessData as |groupedBolt|}}
      {{#let (uniqueId) (last groupedBolt) as |groupId lastBolt|}}
        <div class="jem-task-table jem-bolt-group bootstrap-table my-3 mx-2">
          <div class="row form-row border-bottom border-dark bolt-row
            {{if lastBolt.isPassed 'bolt-good'}}
            {{if (and (eq lastBolt @childStatus.activeBolt) @childStatus.isStarted) 'bolt-started fw-bold'}}
            {{if lastBolt.isRejected 'bolt-rejected'}}
            {{if (and lastBolt.isStarted (findBy 'isRejected' true groupedBolt)) 'bolt-rejected' }}
            {{if (and @childStatus.isRejected (not lastBolt.isPassed)) 'bolt-rejected' }}"
          >
            <div class="bolt-name col-auto">
              {{t "fastener"}} {{get groupedBolt '0.boltNumber'}}
            </div>
            <div class="col-auto ps-0">
              {{#unless lastBolt.isStarted}}
                <button
                  type="button"
                  class="btn btn-gray-300 btn-sm text-dark d-flex align-items-center"
                  data-bs-target="#{{groupId}}"
                  data-bs-toggle="collapse"
                  disabled={{eq groupedBolt.length 1}}
                >
                  <FaIcon class="me-1" @icon="list" @prefix="fas"/>
                  {{groupedBolt.length}}
                </button>
              {{/unless}}
            </div>
            <div class="bolt-status col-auto ms-auto text-uppercase">
              {{#if lastBolt.isPassed}}
                {{t "status.251"}}
              {{else if (or @childStatus.isRejected lastBolt.isRejected)}}
                {{t "rejected"}}
              {{else if (and lastBolt.isStarted (findBy 'isRejected' true groupedBolt))}}
                {{t "rejected"}}
              {{else if (notEq lastBolt @childStatus.activeBolt)}}
                {{t "waiting"}}
              {{else}}
                {{t "started"}}
              {{/if}}
            </div>
          </div>
          <div class="row form-row header-row">
            <div class="col torque-col">{{t "torque"}}</div>
            <div class="col angle-col">{{t "angle"}}</div>
            <div class="col tightening-col">{{t "tightening"}}</div>
          </div>

          {{#each (reverse groupedBolt) as |bolt|}}
            <JemBolt
              id={{if bolt.isCollapsedResult groupId}}
              @bolt={{bolt}}
              @isActive={{eq bolt @childStatus.activeBolt}}
              @taskStatusClass={{@childStatus.taskStatusClass}}
              @torqueFormat={{or @task.torqueFormat @task.globalTorqueFormat}}
            />
          {{/each}}
        </div>
      {{/let}}
    {{/each}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskTorqueDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
