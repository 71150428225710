/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PAGE_SIZE } from 'eflex/constants/pagination';
import { isPresent } from '@ember/utils';

export default class EmberGrid extends Component {
  @tracked _previousDataLength = 0;
  @tracked _hideLoad = false;
  @tracked _skip = 0;
  take = PAGE_SIZE;

  get usePages() {
    return isPresent(this.args.page);
  }

  get showLoadMore() {
    if (this.args.fetch == null || this._hideLoad || this.usePages) {
      return false;
    }

    return this.args.data?.length - this._previousDataLength >= PAGE_SIZE;
  }

  onResetPagination = () => {
    this.args.updatePage?.(1);
    Object.assign(this, {
      _skip: 0,
      _hideLoad: false,
      _previousDataLength: 0,
    });
  };

  sendFetch = () => {
    const dataLength = this.args.data?.length;

    if (this._previousDataLength === dataLength) {
      this._hideLoad = true;
    }

    this._previousDataLength = dataLength;

    this._skip = this._skip + PAGE_SIZE;

    return this.args.fetch?.({
      skip: this._skip,
      take: PAGE_SIZE,
    });
  };

  updatePage = (page) => {
    this.args.updatePage(page);
    return this.args.fetch?.({
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    });
  };
}
