/* import __COLOCATED_TEMPLATE__ from './serial-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { generatesSerialNumber, STATION_LOAD_OPTIONS } from 'eflex/constants/station-options';
import { isBlank } from '@ember/utils';
import onKey from 'ember-keyboard/modifiers/on-key';

export default class JemSerialInput extends Component {
  onKey = onKey;

  get canGenerateNewPart() {
    return generatesSerialNumber(this.args.station);
  }

  get newPartButtonDisabled() {
    if (this.args.disabled) {
      return true;
    }

    if (this.args.station.usesComponents) {
      return isBlank(this.args.bomSourceLookupValue);
    }

    return false;
  }

  @action
  onLoadNewPart() {
    let partValue;
    switch (this.args.station.loadOption) {
      case STATION_LOAD_OPTIONS.bomSourceLookupAuto: {
        partValue = this.args.bomSourceLookupValue;
        break;
      }
      case STATION_LOAD_OPTIONS.operationScan:
      case STATION_LOAD_OPTIONS.operationSelectAuto: {
        partValue = this.args.kineticJobNumber;
        break;
      }
      default: {
        partValue = this.args.modelCode;
      }
    }

    return this.args.onSubmit(partValue);
  }

  @action
  onSubmitWithEnter(serialNumber, event, ekEvent) {
    ekEvent.stopImmediatePropagation();
    ekEvent.stopPropagation();
    this.args.onSubmit(serialNumber);
  }
}
