import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import formatDate from 'eflex/helpers/format-date';
import { waitFor } from '@ember/test-waiters';

export default class PartGenealogyController extends Controller {
  queryParams = ['searchTerm', 'searchMode', 'beginDate', 'endDate'];

  @service eflexAjax;
  @service fileDownloader;
  @service router;

  @tracked searchResult;
  @tracked searchMode = 'descendants';
  @tracked beginDate;
  @tracked endDate;
  @tracked searchTerm;

  get searchNotRan() {
    return isEmpty(this.searchResult);
  }

  get marriageData() {
    return this.searchResult?.marriageData;
  }

  get resultCount() {
    return this.searchResult?.count;
  }

  get limitReached() {
    return this.searchResult?.limitReached;
  }

  get limit() {
    return this.searchResult?.limit;
  }

  get hasSearchResults() {
    return this.resultCount > 0;
  }

  get noSearchMatch() {
    return !isEmpty(this.searchResult) && !this.hasSearchResults;
  }

  get hasDateCritera() {
    return !isEmpty(this.beginDate) || !isEmpty(this.endDate);
  }

  searchSerials = task({ drop: true }, waitFor(async (params = {}) => {
    if (!params.searchTerm) {
      return;
    }

    this.searchResult = await this.eflexAjax.get.perform('marriages', {
      ...params,
      searchMode: this.searchMode,
    });
  }));

  downloadExport = task({ drop: true }, waitFor(async (type, params = {}) => {
    if (!params.searchTerm) {
      return;
    }

    params = {
      ...params,
      searchMode: this.searchMode,
      type,
    };

    await this.fileDownloader.getFile.perform(
      'marriages',
      'text/csv',
      this.#getCsvFilename(type, params),
      params,
    );
  }));

  #getCsvFilename(type, params) {
    const name = [params.searchTerm.replace(/[^\s\w-]/gi, ''), params.searchMode, type];

    if (params.beginDate && params.endDate) {
      name.push(
        formatDate(params.beginDate, 'YYYY-MM-DD', 'hh-mma'),
        formatDate(params.endDate, 'YYYY-MM-DD', 'hh-mma'),
      );
    }

    name.push('marriage-history.csv');
    return name.join('_');
  }

  @action
  nodeClicked(node) {
    this.router.transitionTo('parts.buildHistory', { queryParams: { search: node.data('id') } });
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
