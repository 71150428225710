/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { removeObject } from 'eflex/util/array-helpers';

export default class StatusServer extends Component {
  @tracked newLinkName;
  @tracked newLinkUrl;
  @tracked adding = false;

  @service store;
  @service currentUser;

  addLink = task(waitFor(async () => {
    if (
      isBlank(this.newLinkName) ||
      isBlank(this.newLinkUrl) ||
      this.args.statuses.flatMap(status => status.links).some(link => link.name === this.newLinkName)
    ) {
      return;
    }

    this.adding = false;

    const link = this.store.createRecord('serverStatusLink', {
      url: this.newLinkUrl,
      name: this.newLinkName,
    });

    this.args.server.links.push(link);

    try {
      await this.args.server.save();
    } finally {
      Object.assign(this, {
        newLinkName: null,
        newLinkUrl: null,
      });
    }
  }));

  deleteLink = task(waitFor(async link => {
    removeObject(this.args.server.links, link);
    await this.args.server.save();
  }));
}
