import { template } from "@ember/template-compiler";
import { get, hash } from '@ember/helper';
import Component from '@glimmer/component';
import TaskStatuses from 'eflex/constants/task-statuses';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
export class JemTaskVisionDetails extends Component {
    get someChildrenRejected() {
        return this.args.childStatus?.visionProcessData?.some((item1)=>item1.isRejected) ?? false;
    }
    get showCaptureImageButton() {
        const childStatus1 = this.args.childStatus;
        if (!childStatus1) {
            return false;
        }
        const rejectedOrStarted1 = childStatus1.isStarted || childStatus1.isRejected || this.someChildrenRejected;
        return rejectedOrStarted1 && this.args.task.triggerType === 'captureButton' && childStatus1.parent.isStarted;
    }
    get barcodeStrings() {
        return this.args.taskConfig?.strings?.map((item1)=>item1.barcodeString) ?? [];
    }
    get children() {
        const processData1 = this.args.childStatus?.visionProcessData ?? [];
        return processData1.map((kid1)=>{
            let taskStatusClass1;
            if (kid1.isNotRequired) {
                taskStatusClass1 = 'not-required';
            } else if (kid1.isRejected) {
                taskStatusClass1 = 'danger';
            } else if (kid1.isGood) {
                taskStatusClass1 = 'success';
            }
            return {
                name: kid1.name,
                barcode: kid1.barcode,
                taskStatusClass: taskStatusClass1
            };
        });
    }
    captureImage = ()=>{
        return this.args.onTaskComplete?.(TaskStatuses.NOT_RECEIVED);
    };
    static{
        template(`
    <div ...attributes>
      {{#if this.showCaptureImageButton}}
        <BsButton
          @type="primary"
          @size="lg"
          @disabled={{@childStatus.isHolding}}
          @onClick={{this.captureImage}}
          class="text-uppercase capture-image"
        >
          {{#if this.someChildrenRejected}}
            {{t "retakeImage"}}
          {{else}}
            {{t "captureImage"}}
          {{/if}}
        </BsButton>
      {{/if}}
      {{#each this.children as |child index|}}
        <div class="vision-barcode">
          <div class="input-name detail-text-label">
            {{child.name}}
          </div>
          {{#if (get this.barcodeStrings index)}}
            <div class="expected-value d-flex align-items-baseline">
              <div class="detail-text-small">
                {{t "expecting"}}
              </div>
              <div class="detail-text-label ms-2">
                {{get this.barcodeStrings index}}
              </div>
            </div>
          {{/if}}
          <div class="displayed-value status detail-text-value {{child.taskStatusClass}}">
            {{child.barcode}}
          </div>
        </div>
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskVisionDetails
        task=@task
        childStatus=@childStatus
        taskConfig=@taskConfig
        captureImage=this.captureImage
        onTaskComplete=@onTaskComplete
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
