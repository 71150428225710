import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { all, task, timeout } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { sum } from 'ramda';

export default class IntegrationTesterController extends Controller {
  @service eflexAjax;
  @service notifier;
  @service store;

  @tracked eventName;
  @tracked jobCount = 1;
  @tracked assembliesPerJob = 1;
  @tracked requiredQuantity = 10;
  @tracked _selectedPart;
  @tracked _selectedPartRev;
  @tracked selectedOperations;
  @tracked persist = true;
  @tracked results = new TrackedArray();
  @tracked totalTimeToCreate;
  @tracked totalTimeToSave;
  @tracked current;
  @tracked data;

  kineticParts = this.store.peekAll('kineticPart');
  kineticPartRevs = this.store.peekAll('kineticPartRev');
  kineticOperations = this.store.peekAll('kineticOperation');

  get eventNames() {
    return [
      {
        groupName: 'Inbox',
        options: [
          'Site_1.0',
          'ReasonCode_1.0',
          'Station_1.0',
          'Employee_1.0',
          'Shift_1.0',
          'BOM_1.0',
        ],
      },
      {
        groupName: 'Outbox',
        options: [
          'SyncSite_1.0',
          'SyncReasonCode_1.0',
          'SyncStation_1.0',
          'SyncShift_1.0',
          'SyncEmployee_1.0',
          'ClockInOut_1.0',
          'JobActivity_1.0',
        ],
      },

    ];
  }

  get numRecords() {
    let numRecords = this.jobCount;
    numRecords += this.jobCount * this.assembliesPerJob;
    return numRecords;
  }

  get selectedPart() {
    return this._selectedPart;
  }

  set selectedPart(val) {
    this._selectedPart = val;
  }

  get selectedPartRev() {
    return this._selectedPartRev;
  }

  set selectedPartRev(val) {
    this._selectedPartRev = val;
  }

  get filteredPartRevs() {
    return this.kineticPartRevs?.filter(partRev => {
      return partRev.parent === this.selectedPart;
    }) ?? [];
  }

  get filteredOperations() {
    return this.kineticOperations?.filter(operation => {
      return operation.parent === this.selectedPartRev;
    }) ?? [];
  }

  get createJobsDisabled() {
    return isEmpty(this.selectedOperations);
  }

  async #sendIntegrationJson(type) {
    let json;

    try {
      json = JSON.parse(this.data);
    } catch {
      this.notifier.sendError('Invalid JSON');
      return;
    }

    await this.eflexAjax.post.perform(`kinetic/${type}`, {
      eventName: this.eventName,
      data: json,
    });
  }

  addToOutbox = task({ drop: true }, waitFor(async () => {
    await this.#sendIntegrationJson('integrationOutbox');
  }));

  addToInbox = task({ drop: true }, waitFor(async () => {
    await this.#sendIntegrationJson('integrationInbox');
  }));

  generate = task(waitFor(async () => {
    await timeout(1); // let animation start

    const times = {
      jobTimes: [],
      assemblyTimes: [],
      assemblyOpTimes: [],
    };

    this.current = 1;

    const jobs = [];
    const start = performance.now();

    for (let i = 0; i < this.jobCount; i++) {
      jobs.push(this._createJob(i, times));
    }

    Object.assign(this, {
      results: new TrackedArray(),
      totalTimeToCreate: Math.round(performance.now() - start),
    });

    for (const type of ['job', 'assembly', 'assemblyOp']) {
      const typeTimes = times[`${type}Times`];
      const total = Math.round(sum(typeTimes));
      const average = Math.round(total / typeTimes.length);

      this.results.push({
        type,
        times: typeTimes,
        average,
        total,
      });
    }

    const assemblies = jobs.flatMap(job => job.assemblies);

    const saveStart = performance.now();

    await all(jobs.map(job => job.save()));
    await all(assemblies.map(assembly => assembly.save()));

    this.totalTimeToSave = Math.round(performance.now() - saveStart);
  }));

  _createJob(jobNumber, times) {
    const jobStart = performance.now();
    const job = this.store.createRecord('kineticJob', { jobNumber: `JOB-${jobNumber}` });

    times.jobTimes.push(performance.now() - jobStart);
    this._incrementTotal();

    for (let i = 0; i < this.assembliesPerJob; i++) {
      const assemblyStart = performance.now();
      const assembly = this._createAssembly(job, i);
      times.assemblyTimes.push(performance.now() - assemblyStart);
      this._incrementTotal();

      this.selectedOperations.forEach((operation, opI) => {
        const assemblyOpStart = performance.now();
        this._createAssemblyOp(assembly, operation, opI);
        times.assemblyOpTimes.push(performance.now() - assemblyOpStart);
        this._incrementTotal();
      });
    }

    return job;
  }

  _createAssembly(job, assemblySequence) {
    return this.store.createRecord('kineticJobAssembly', {
      assemblySequence,
      requiredQuantity: this.requiredQuantity,
      job,
      partRev: this.selectedPartRev,
    });
  }

  _createAssemblyOp(assembly, operation, sequence) {
    this.store.createRecord('kineticAssemblyOperation', {
      sequence,
      runQuantity: this.requiredQuantity,
      operation,
      assembly,
    });
  }

  _incrementTotal() {
    this.current += 1;
    if (this.current % 500 === 0 || this.current === this.numRecords) {
      // eslint-disable-next-line no-console
      console.log('Created', this.current, 'of', this.numRecords, 'total records.');
    }
  }

  @action
  loadPart(part) {
    this.selectedPart = part;
    this.selectedPartRev = null;
    this.selectedOperations = null;
  }

  @action
  loadPartRev(partRev) {
    this.selectedPartRev = partRev;
    this.selectedOperations = null;
  }

  @action
  selectOperations(operations) {
    this.selectedOperations = operations;
  }

  @action
  setEventName(eventName) {
    this.eventName = eventName;

    const employeeId = '105';
    const shift = 1;
    const resourceGroupId = 'group1';
    const resourceId = 'station1';

    let data;

    switch (eventName) {
      case 'Site_1.0': {
        data = {
          name: 'Main Plant',
          timeZone: 'America/Detroit',
        };
        break;
      }
      case 'ReasonCode_1.0': {
        data = {
          reasonCode: 'SCMBD',
          description: 'Machine Breakdown',
          reasonType: 'Scrap',
        };
        break;
      }

      case 'Station_1.0': {
        data = {
          departmentId: 'area1',
          departmentDescription: 'Area 1',
          resourceGroupId,
          resourceGroupDescription: 'Group 1',
          resourceId,
          resourceDescription: 'Station 1',
        };
        break;
      }

      case 'Employee_1.0': {
        data = {
          employeeId,
          supervisorEmployeeId: employeeId,
          name: 'Charles Johnson',
          email: 'charles@somewhere.com',
          mobile: '0773585415',
          status: 'Active',
          defaultShift: shift,
          defaultResourceGroupId: resourceGroupId,
        };
        break;
      }

      case 'Shift_1.0': {
        data = {
          shift,
          name: 'Shift A',
          startDate: new Date(),
          endDate: null,
        };
        break;
      }

      case 'BOM_1.0': {
        data = {
          materials: [],
          partNumber: 'ECPC101',
          partDescription: 'ECPC Test 1',
          revisionNumber: 'B',
          revisionDescription: 'Big',
          operations: [
            {
              sequence: 0,
              operationCode: 'AssyUnit',
              operationCodeDescription: 'Assembly',
              resources: [
                { resourceGroupId, resourceId },
                { resourceGroupId, resourceId },
              ],
            },
            {
              sequence: 1,
              operationCode: 'Hammer',
              operationCodeDescription: 'Hammer Time',
              resources: [
                { resourceGroupId, resourceId },
                { resourceGroupId, resourceId },
              ],
            },
          ],
        };
        break;
      }

      case 'ClockInOut_1.0': {
        data = {
          employeeId,
          shift,
          clockInDateTime: new Date(),
          clockOutDateTime: null,
        };
        break;
      }

      case 'JobActivity_1.0': {
        data = {
          jobNumber: 'JOB-0',
          assemblySequence: 0,
          operationSequence: 0,
          resourceId,
          employeeId,
          activityType: 'Production',
        };
        break;
      }

      case 'SyncSite_1.0':
      case 'SyncReasonCode_1.0':
      case 'SyncStation_1.0':
      case 'SyncShift_1.0':
      case 'SyncEmployee_1.0': {
        data = {};
        break;
      }
    }

    this.data = JSON.stringify(data, null, 2);
  }
}
