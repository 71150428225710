/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import OEE_STATES from 'eflex/constants/oee-states';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
export default class OeeStatesPieChart extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;

  get params() {
    return {
      stationIds: this.args.stationIds,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
      buttonIds: this.args.buttonIds,
      states: this.args.states,
    };
  }

  getData = task({ drop: true }, waitFor(async params => {
    const oeeStatesQuery = await this.queryRunner.queryWithParams.perform(
      params,
      [
        {
          $group: {
            _id: '$state',
            totalTimeInState: {
              $sum: {
                $dateDiff: {
                  startDate: '$startDate',
                  endDate: '$endDate',
                  unit: 'millisecond',
                },
              },
            },
          },
        },
      ],
      true,
    );

    let oeeStates = await this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery);
    oeeStates = oeeStates.filter((oeeState) => Object.values(OEE_STATES.STATES).includes(oeeState._id));

    // return this.getEchartOptions(oeeStates);
    return {
      chartData: this.getEchartOptions(oeeStates),
      exportData: () => this.formatExportData(clone(oeeStates)),
    };
    // jscpd:ignore-end
  }));

  oeeStateData = trackedTask(this, this.getData, () => [this.params]);

  getEchartOptions(oeeStates) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.oeeStatesHistoryPieChart'),
      },
      tooltip: {
        formatter(params) {
          // eslint-disable-next-line max-len
          return `<div style="min-width:175px;">${params.name.toUpperCase()}: <span style="float:right;font-weight:bold;">${
            params.percent
          }% </span> <br> Total:  <span style="float:right;font-weight:bold;">${moment
            .duration(params.value, 'milliseconds')
            .format('d[d] h[h] m[m] s[s]', {
              precision: 1,
              trim: 'both mid',
            })}</span></div>`;
        },
      },
      legend: {
        top: 'bottom',
        formatter(name) {
          return `${name.toUpperCase()}`;
        },
      },
      series: [
        {
          label: {
            formatter(params) {
              return ` {b|${params.name.toUpperCase()}: } {per|${params.percent}%} `;
            },
            backgroundColor: echartsTheme.colorPalette.labelBackground,
            borderColor: echartsTheme.colorPalette.labelBorder,
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              b: {
                color: echartsTheme.colorPalette.oeeBackground,
                fontSize: 12,
                fontWeight: 'bold',
              },
              c: {
                color: echartsTheme.colorPalette.oeeBackground,
              },
              per: {
                fontSize: 12,
                color: '#fff',
                backgroundColor: echartsTheme.colorPalette.oeeBackground,
                padding: [3, 4],
                borderRadius: 4,
              },
            },
          },
          name: this.intl.t('bi.pieChart.legend'),
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          data: oeeStates.map((m) => ({
            value: m.totalTimeInState,
            name: m._id,
            itemStyle: {
              color: this.setPieSliceColor(m._id),
            },
          })),
        },
      ],
    };
  }

  setPieSliceColor(state) {
    // oee button colors, except for the buttons that share a color. those button colors came from george's dundas chart.
    switch (state) {
      case 'running': {
        return echartsTheme.colorPalette.success;
      }
      case 'faulted': {
        return '#e43743';
      }
      case 'starved': {
        return '#fada4a';
      }
      case 'blocked': {
        return echartsTheme.colorPalette.biBlue;
      }
      case 'changeover': {
        return 'chocolate';
      }
      case 'breaks': {
        return '#FF7F0E';
      }
      default: {
        return 'white';
      }
    }
  }

  formatExportData(oeeStates) {
    return oeeStates.map((oeeState) => ({
      state: oeeState._id,
      totalTimeInState: moment.duration(oeeState.totalTimeInState, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
        precision: 1,
        trim: 'both mid',
      }),
    }));
  }
}
