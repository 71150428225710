import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { sortByProp } from 'ramda-adjunct';
import { findIndex, propEq } from 'ramda';

class KineticJobAssembly extends Model {
  @attr('number') assemblySequence;
  @attr('number') requiredQuantity;
  @attr('date') updatedAt;
  @attr('date') createdAt;

  @belongsTo('kineticJob', { async: false, inverse: 'assemblies' }) job;
  @belongsTo('kineticPartRev', { async: false, inverse: null }) partRev;
  @hasMany('kineticAssemblyOperation', { async: false, inverse: 'assembly', embedded: true }) assemblyOperations;

  get part() {
    return this.partRev.part;
  }

  getAssemblyOpAfter(assemblyOperation) {
    const sorted = sortByProp('order', this.assemblyOperations ?? []);
    const opIndex = findIndex(propEq(assemblyOperation.sequence, 'sequence'))(sorted);

    return sorted[opIndex + 1];
  }
}

export default KineticJobAssembly;
