import { fabric } from 'fabric';
import { EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { isEmpty } from '@ember/utils';
import { dequal } from 'dequal';
import { sortBy, filter, pipe } from 'ramda';
import { dp } from '@eflexsystems/ramda-helpers';

const PORTRAIT = 0;
const LANDSCAPE = 1;

export function toGlobalPoint(obj, point) {
  return fabric.util
    .rotatePoint(point, obj.getCenterPoint(), fabric.util.degreesToRadians(obj.angle))
    .addEquals(new fabric.Point(obj.left, obj.top));
}

export function removeSelectedText(textbox) {
  textbox.removeChars(textbox.selectionStart, textbox.selectionEnd);
  textbox.selectionEnd = textbox.selectionStart;
  textbox.dirty = true;

  if (!textbox.hiddenTextarea) {
    return;
  }

  textbox.hiddenTextarea.focus();
  textbox.hiddenTextarea.value = textbox.text;
  textbox.hiddenTextarea.selectionStart = textbox.selectionStart;
  textbox.hiddenTextarea.selectionEnd = textbox.selectionStart;
}

export function pasteTextAtLocation(textbox, text, location) {
  let newText = textbox.text.slice(0, location);
  newText = newText.concat(text);
  newText = newText.concat(textbox.text.slice(textbox.selectionEnd));

  const selectionEnd = textbox.selectionStart + text.length;
  textbox.selectionEnd = selectionEnd;
  textbox.text = newText;
  textbox.dirty = true;

  if (!textbox.hiddenTextarea) {
    return;
  }

  textbox.hiddenTextarea.focus();
  textbox.hiddenTextarea.value = newText;
  textbox.hiddenTextarea.selectionStart = location;
  textbox.hiddenTextarea.selectionEnd = selectionEnd;
}

export function getAllCanvasImages(source) {
  const images = source.getObjects?.('image');
  source.getObjects?.('group').forEach(group => { images.push(...getAllCanvasImages(group)); });

  return images;
}

export function getCanvasDefaults() {
  return {
    backgroundColor: '#313340',
    width: 1600,
    height: 1200,
    renderOnAddRemove: true,
    enableRetinaScaling: false,
    controlsAboveOverlay: true,
    perPixelTargetFind: true,
    preserveObjectStacking: true,
  };
}

export function cleanupCanvas(canvas) {
  if (canvas != null) {
    canvas.forEachObject(obj => {
      obj.exitEditing?.();
    });
    canvas.dispose();
  }

  window.wieCanvas = null;
}

export function initializeCanvas(element, options = {}) {
  const canvasOptions = {
    ...getCanvasDefaults(),
    ...options,
  };

  // These must always be ints, or it skews the grid overlay
  canvasOptions.width = parseInt(canvasOptions.width);
  canvasOptions.height = parseInt(canvasOptions.height);

  const canvas = new fabric.Canvas(element, canvasOptions);

  // we put the initialized canvas in global scope for ease of testing and debugging
  window.wieCanvas = canvas;

  canvas.setDimensions(
    {
      height: 'auto',
      width: 'auto',
      'max-height': '100%',
      'max-width': '100%',
    },
    { cssOnly: true },
  );

  return canvas;
}

export function getCanvasCoordsForClick(canvas, event) {
  const canvasX = event.offsetX ?? event.layerX;
  const canvasY = event.offsetY ?? event.layerY;
  const widthScale = canvas.width / canvas.upperCanvasEl.offsetWidth;
  const heightScale = canvas.height / canvas.upperCanvasEl.offsetHeight;

  return {
    x: canvasX * widthScale,
    y: canvasY * heightScale,
  };
}

export function getCanvasObjects(canvas, type, includeChildren = true) {
  let objects = EflexObjTypes.options.has(type)
    ? canvas.getObjects().filter(item => item.eflex?.type === type)
    : canvas.getObjects(type);

  if (!includeChildren) {
    objects = objects.filter(item => !item.eflex?.childObject);
  }

  return objects;
}

export function getCanvasObjectById(canvas, id) {
  return getCanvasObjects(canvas).find(item => item.id === id);
}

export function getCanvasObjectByPoint(canvas, x, y, searchGroups = false) {
  const point = new fabric.Point(x, y);

  const objects = canvas
    .getObjects()
    .filter(item => !item.eflex?.childObject)
    .filter((obj) => obj.containsPoint(point) && !canvas.isTargetTransparent(obj, x, y));

  if (isEmpty(objects)) {
    return;
  }

  const obj = objects.at(-1);

  if (obj.type === 'group' && obj.eflex?.type !== EflexObjTypes.ICON && searchGroups) {
    // Objects in a group are positioned relative to the center point of the group
    const centerX = obj.width / 2 + obj.left;
    const centerY = obj.height / 2 + obj.top;

    // Adjust the check point to be relative to the center point of the group
    const groupPoint = new fabric.Point(x - centerX, y - centerY);

    const groupObjects = obj.getObjects().filter((_obj) => _obj.containsPoint(groupPoint));

    if (groupObjects.length > 0) {
      return groupObjects.at(-1);
    }
  } else {
    return obj;
  }
}

export function updateObjectProperty(obj, prop, value) {
  if (obj == null || dequal(obj[prop], value)) {
    return;
  }

  if (!obj.set) {
    obj[prop] = value;
  } else {
    obj.set(prop, value);
  }
}

export function getLineParts(source, line, position, type) {
  return pipe(
    filter(({ eflex }) => {
      if (eflex == null) {
        return false;
      } else if (position == null && type == null) {
        return eflex.childObject && eflex.itemKey === line.eflex?.itemKey;
      } else {
        return (
          (position == null || eflex.itemPosition === position) &&
          eflex.itemPosition != null &&
          eflex.type === type &&
          eflex.itemKey === line.eflex?.itemKey
        );
      }
    }),
    sortBy(dp('eflex.type')),
  )(source.getObjects());
}

export function getArrowsByLine(canvas, line, position) {
  return getLineParts(canvas, line, position, EflexObjTypes.ARROWHEAD);
}

export function scaleCanvas(canvas, wrapperWidth, wrapperHeight, nativeWidth, nativeHeight) {
  const orientation = nativeHeight > nativeWidth ? PORTRAIT : LANDSCAPE;

  let scaleRatio = orientation === LANDSCAPE ? wrapperWidth / nativeWidth : wrapperHeight / nativeHeight;

  let scaledWidth = nativeWidth * scaleRatio;
  let scaledHeight = nativeHeight * scaleRatio;

  if (scaledHeight > wrapperHeight) {
    scaleRatio = wrapperHeight / nativeHeight;
    scaledWidth = nativeWidth * scaleRatio;
    scaledHeight = nativeHeight * scaleRatio;
  }

  if (scaledWidth > wrapperWidth) {
    scaleRatio = wrapperWidth / nativeWidth;
    scaledWidth = nativeWidth * scaleRatio;
    scaledHeight = nativeHeight * scaleRatio;
  }

  if (scaleRatio <= 1) {
    // have to set dimensions twice to set the canvas DOM attributes, then set the css
    canvas
      .setDimensions({
        height: nativeHeight,
        width: nativeWidth,
      })
      .setDimensions(
        {
          height: 'auto',
          width: 'auto',
        },
        { cssOnly: true },
      )
      .setZoom(1)
      .calcOffset()
      .renderAll();
  } else {
    canvas
      .setDimensions({
        height: scaledHeight,
        width: scaledWidth,
      })
      .setZoom(scaleRatio)
      .calcOffset()
      .renderAll();
  }
}
