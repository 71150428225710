import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { and, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import BsTooltip from 'eflex/components/bs-tooltip';
export default class JemTaskComponentOption extends Component {
    get displayedComponentOptionValue() {
        const taskConfig1 = this.args.taskConfig;
        if (!taskConfig1 || this.args.task.component?.isAlwaysRun) {
            return null;
        }
        if (!taskConfig1.configOption.isSimple) {
            return this.args.childStatus?.getRawBuildDataValue(taskConfig1);
        }
        return taskConfig1.configOption.value;
    }
    static{
        template(`
    {{#if (and @childStatus @task.usesComponents (not @task.component.isAlwaysRun))}}
      <div class="component-option p-2" ...attributes>
        {{#if @task.component.isAlwaysRun}}
          {{t "plant.area.component.alwaysRun"}}
        {{else}}
          {{@taskConfig.configOption.name}}
        {{/if}}
        {{#if this.displayedComponentOptionValue}}
          <div class="detail-text-value component-option-value">
            {{this.displayedComponentOptionValue}}
          </div>
        {{/if}}
        {{#if (and @taskConfig (not @taskConfig.configOption.isSimple))}}
          <span class="complex-component-icon">
            <BsTooltip>
              {{t "complexComponent"}}
            </BsTooltip>
          </span>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
