import { template } from "@ember/template-compiler";
import Portal from 'ember-stargate/components/portal';
import { hash } from '@ember/helper';
import Component from '@glimmer/component';
import { registerDestructor } from '@ember/destroyable';
import { Modal } from 'bootstrap';
import { optional, withDefault } from '@eflexsystems/ember-composable-helpers';
import { on } from '@ember/modifier';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
let Base = class Base extends Component {
    onDidInsert = (element1)=>{
        const modal1 = new Modal(element1);
        registerDestructor(this, ()=>{
            modal1.dispose();
        });
        modal1.show();
    };
    static{
        template(`
    <div
      class="modal"
      data-bs-focus={{withDefault @focus 'true'}}
      data-bs-keyboard={{withDefault @keyboard 'true'}}
      data-bs-backdrop="static"
      {{didInsert this.onDidInsert}}
      {{on 'hidden.bs.modal' (optional @onCancel)}}
      ...attributes
    >
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const HeaderClose = template(`
<button
  class="btn-close btn-close-white"
  type="button"
  data-bs-dismiss="modal"
  aria-label="close"
  ...attributes
>
</button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Header = template(`
<div class="modal-header" ...attributes>
  {{#if (has-block)}}
    {{yield (hash close=(component HeaderClose))}}
  {{else}}
    <h5 class="modal-title">
      {{@text}}
    </h5>
    <HeaderClose />
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const FooterConfirm = template(`
<BsButton
  @type="primary"
  class="modal-confirm fs-3"
  @disabled={{@disabled}}
  @onClick={{@onConfirm}}
  ...attributes
>
  {{#if (has-block)}}
    {{yield}}
  {{else}}
    {{t "ok"}}
  {{/if}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const FooterClose = template(`
<BsButton @type="secondary" class="modal-close fs-3" data-bs-dismiss="modal" ...attributes>
  {{#if (has-block)}}
    {{yield}}
  {{else}}
    {{t "cancel"}}
  {{/if}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Footer = template(`
<div class="modal-footer p-4" ...attributes>
  {{#if (has-block)}}
    {{yield
      (hash
        close=(component FooterClose)
        confirm=(component
          FooterConfirm
          onConfirm=@onConfirm
          disabled=@disabled
        )
      )
    }}
  {{else}}
    <FooterConfirm
      @onConfirm={{@onConfirm}}
      @disabled={{@disabled}}
    />
    {{#unless @confirmOnly}}
      <FooterClose />
    {{/unless}}
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Body = template(`
<div
  class="modal-body py-2"
  ...attributes
>
  {{yield}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
<Portal @target="modal">
  <Base
    class="standard-modal modal"
    @onCancel={{@onCancel}}
    @focus={{@focus}}
    @keyboard={{@keyboard}}
    ...attributes
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-{{@size}}">
      <div class="modal-content">
        {{#if (has-block)}}
          {{yield
            (hash
              header=(component Header)
              body=(component Body)
              footer=(component
                Footer
                onConfirm=@onConfirm
                disabled=@disabled
              )
            )
          }}
        {{else}}
          <Header @text={{@header}} />
          <Body>
            <p>
              {{@text}}
            </p>
          </Body>
          <Footer
            @onConfirm={{@onConfirm}}
            @confirmOnly={{@confirmOnly}}
            @disabled={{@disabled}}
          />
        {{/if}}
      </div>
    </div>
  </Base>
</Portal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
