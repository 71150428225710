/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DropDownsTimezones extends Component {
  @service intl;

  get timezones() {
    const timezones = moment.tz
      .names()
      .map(timezone => ({
        name: timezone,
        value: timezone,
      }));

    timezones.unshift({
      name: this.args.defaultValue ?? this.intl.t('settings.server.timezone.browser'),
      value: null,
    });

    return timezones;
  }
}
