/* import __COLOCATED_TEMPLATE__ from './web-cam.hbs'; */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';

const CAPTURE_TRIGGER_TYPES = new Set([
  CameraTriggerValues.captureAdvance,
  CameraTriggerValues.taskActive,
]);

export default class WebCam extends Component {
  @service webCam;
  @service notifier;

  @tracked width;
  @tracked height;

  constructor() {
    super(...arguments);
    this.setupCamera.perform();
  }

  setupCamera = task({ onState: null }, waitFor(async () => {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    registerDestructor(this, () => {
      this.webCam.destroyStreamTracks();
    });

    await this.webCam.initStream.linked().perform(this.args.cameraId);
  }));

  captureImage = task({ restartable: true }, waitFor(async element => {
    if (!this.webCam.cameraStreamPlaying) {
      this.notifier.sendWarning('cameraNotLoaded');
      return;
    }

    const imageWidth = element.videoWidth;
    const imageHeight = element.videoHeight;
    let blob;

    if (
      this.args.triggerType !== CameraTriggerValues.captureButton &&
      window.ImageCapture &&
      this.webCam.cameraStream
    ) {
      const imageCapture = new ImageCapture(this.webCam.cameraStream.getVideoTracks()[0]);
      blob = await imageCapture.takePhoto({
        imageWidth,
        imageHeight,
      });
    } else {
      blob = await this.getCanvasBlob.perform(element, imageWidth, imageHeight);
    }

    this.args.onCaptureImage?.(blob);
  }));

  getCanvasBlob = task(
    { onState: null },
    waitFor(async (videoElement, imageWidth, imageHeight) => {
      if (window.isTesting) {
        return new Blob([''], { type: 'image/jpeg' });
      }

      const canvas = document.createElement('canvas');
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      const context = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, imageWidth, imageHeight);
      const { canvasToBlob } = await import('blob-util');
      const blob = await canvasToBlob(canvas, 'image/jpeg');
      canvas.remove();
      return blob;
    }),
  );

  @action
  onResize(element) {
    const { width, height } = element.parentElement.getBoundingClientRect();
    Object.assign(this, {
      width,
      height,
    });
  }

  onPlaying = task({ restartable: true }, waitFor(async ({ target }) => {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    this.webCam.cameraStreamPlaying = true;

    if (CAPTURE_TRIGGER_TYPES.has(this.args.triggerType)) {
      await this.captureImage.perform(target);
    }
  }));
}
