/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TreeSelectBranch extends Component {
  get canExpand() {
    return isPresent(this.args.branchData.children) &&
      (this.args.maxDepth == null || this.args.depth < this.args.maxDepth);
  }

  _updateChecked(data, checked) {
    data.forEach(child => {
      child.checked = checked;
      if (isPresent(child.children)) {
        this._updateChecked(child.children, checked);
      }
    });
  }

  @action
  clicked() {
    if (this.canExpand) {
      this.args.branchData.expanded = !this.args.branchData.expanded;
    } else {
      this.args.onClick(this.args.branchData);
    }
  }

  @action
  updateChecked() {
    this._updateChecked([this.args.branchData], !this.args.branchData.checked);
    this.args.onCheckedChanged();
  }

  @action
  childCheckedChanged() {
    this.args.branchData.checked = this.args.branchData.children.every(item => item.checked);
    this.args.onCheckedChanged();
  }
}
