import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class KineticJobsRoute extends AuthenticatedRoute {
  @service store;
  @service notifier;
  @service eventBus;
  @service router;

  model(params) {
    return this._getData.perform(params);
  }

  setupController(controller, jobs) {
    Object.assign(controller, {
      jobs: new TrackedArray(jobs),
    });
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.searchTerm = null;
  }

  fetch = task(waitFor(async (params, pagination) => {
    const jobs = await this._getData.perform({ ...params, ...pagination });
    this.controller.jobs.push(...jobs);
  }));

  search = task(waitFor(async (params = {}) => {
    try {
      const jobs = await this._getData.perform({
        ...params,
        skip: 0,
      });

      Object.assign(this.controller, {
        jobs: new TrackedArray(jobs),
      });

      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    }
  }));

  _getData = task(waitFor(async (params = {}) => {
    return new TrackedArray(await this.store.query('kineticJob', {
      skip: 0,
      take: PAGE_SIZE,
      ...params,
    }));
  }));

  searchJobNumber = task(waitFor(async searchTerm => {
    this.controller.searchTerm = searchTerm;
    await this.search.perform({ searchTerm });
  }));
}
