/* import __COLOCATED_TEMPLATE__ from './instructions.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class JemInstructions extends Component {
  @tracked showFullScreenImage = false;
  @tracked showDeleteTabModal = false;

  @service resizeListener;
  @service stationRepo;
  @service store;

  // try not to change the ordering in here
  // its very sensitive to ember data caching
  // hance the belongsTo.value call
  @cached
  get currentInstructionTriggerConfig() {
    const station = this.args.station;
    const type = this.args.type;

    if (
      !this.args.stationIsRunning &&
      (type === 'task' || type === 'station') &&
      station.loadJemConfiguration != null
    ) {
      return station.loadJemConfiguration;
    }

    if (type === 'station') {
      return this.stationRepo.getJemConfig(station, this.args.model);
    }

    if (type === 'task') {
      if (this.args.currentTaskIsRunning) {
        return station.currentInstructionTriggerConfig;
      }

      const triggerConfig = this.args.currentTaskConfig?.triggerConfigs.find(_triggerConfig =>
        _triggerConfig.constructor.modelName === 'work-instruction-hardware-trigger-config' &&
        _triggerConfig.event === 'start',
      );

      if (triggerConfig) {
        return triggerConfig;
      }

      if (station.belongsTo('currentInstructionTriggerConfig').value()) {
        return station.currentInstructionTriggerConfig;
      }

      return null;
    }

    return this.store.peekRecord('customTabJemConfiguration', type);
  }

  get showImageCaptureTab() {
    return this.args.task?.usesWebCam || this.isWebCamLoad;
  }

  get showVisionImageTab() {
    return this.args.childStatus?.taskType === taskTypes.vision;
  }

  get isWebCamLoad() {
    return this.args.station.isWebCamScan && !this.args.stationIsRunning;
  }

  onDeleteTab = task({ drop: true }, waitFor(async () => {
    await this.args.onDeleteTab(this.currentInstructionTriggerConfig);
    this.showDeleteTabModal = false;
  }));

  @action
  enterFullScreen() {
    this.showFullScreenImage = true;
    this.resizeListener.triggerResize();
  }

  @action
  exitFullScreen() {
    this.showFullScreenImage = false;
    this.resizeListener.triggerResize();
  }
}
