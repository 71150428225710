import { template } from "@ember/template-compiler";
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { isEmpty, isBlank } from '@ember/utils';
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { hash } from '@ember/helper';
import eventBus from 'eflex/helpers/event-bus';
import { t } from 'ember-intl';
import { not, gt, and } from 'ember-truth-helpers';
import sub from 'ember-math-helpers/helpers/sub';
import { on } from '@ember/modifier';
import onKey from 'ember-keyboard/modifiers/on-key';
import conditionalAutofocus from 'eflex/modifiers/conditional-autofocus';
import inputVal from 'eflex/helpers/input-val';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import set from 'eflex/helpers/set';
let JemTaskBarcodeDetails = class JemTaskBarcodeDetails extends Component {
    get showInput() {
        return this.args.childStatus?.isStarted && !this.args.hideBarcodeInput;
    }
    get barcodeStrings() {
        if (this.args.passThrough) {
            return [
                this.args.childStatus?.getRawBuildDataValue(this.args.taskConfig)
            ];
        }
        return this.args.taskConfig?.strings.filter((item1)=>item1.enabled).map((item1)=>item1.barcodeString) ?? [];
    }
    get barcodeStringsDisplay() {
        const barcodeStrings1 = this.barcodeStrings;
        if (barcodeStrings1.some((string1)=>isEmpty(string1))) {
            return null;
        }
        return barcodeStrings1.slice(0, 3).join(', ');
    }
    onSubmit = (barcode1)=>{
        if (!this.showInput || this.args.disabled) {
            return;
        }
        this.args.onSubmit(barcode1, this.args.childStatus.status);
    };
    static{
        template(`
    {{eventBus barcodeScanned=this.onSubmit}}

    <div class="expected barcode-strings detail-text-label detail-section mb-0" ...attributes>
      {{#if (and this.barcodeStrings this.barcodeStringsDisplay @childStatus)}}
        <div class="detail-text-small mb-0">
          {{t "expecting"}}
        </div>
        <div class="barcodes detail-text-label mb-0">
          {{this.barcodeStringsDisplay}}
        </div>
        {{#if (gt this.barcodeStrings.length 3)}}
          <div class="detail-text-small more-count">
            {{t "more" count=(sub this.barcodeStrings.length 3)}}
          </div>
        {{/if}}
      {{/if}}

      {{#if this.showInput}}
        <input
          class="scanned-barcode form-control mb-0"
          disabled={{@disabled}}
          placeholder={{t "jem.scanPart"}}
          value={{@barcode}}
          {{on "input" (inputVal @onInput)}}
          {{onKey 'Enter' (inputVal this.onSubmit)}}
          {{conditionalAutofocus (not @disabled)}}
        />
        <KeyboardWedgeEvent @onScan={{this.onSubmit}} />
      {{else if @childStatus.scannedBarcode}}
        <div class="scanned-barcode detail-text-value">
          {{@childStatus.scannedBarcode}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default class JemTaskBarcode extends Component {
    @tracked
    barcode;
    @service
    jemRepo;
    onTaskComplete = task({
        drop: true
    }, waitFor(async (taskStatus1)=>{
        if (taskStatus1 === TaskStatuses.RESTART || TaskStatuses.isRejected(taskStatus1)) {
            this.barcode = null;
            return this.args.onTaskComplete(taskStatus1);
        }
        if (isBlank(this.barcode)) {
            return;
        }
        if (this.args.childStatus.task.confirmRepair) {
            await this.args.onRepair(taskStatus1, this.args.childStatus, this.barcode);
        } else {
            await this.jemRepo.submitBarcode.perform(this.args.childStatus, this.args.taskConfig, this.barcode);
            this.barcode = null;
        }
    }));
    onSubmit = task(waitFor(async (barcode1, status1)=>{
        this.barcode = barcode1;
        await this.onTaskComplete.perform(status1);
    }));
    static{
        template(`
    {{yield
      (hash
        taskDetails=(component
          JemTaskBarcodeDetails
          barcode=this.barcode
          disabled=@disabled
          hideBarcodeInput=@task.hideBarcodeInput
          passThrough=@task.passThrough
          childStatus=@childStatus
          taskConfig=@taskConfig
          onSubmit=this.onSubmit.perform
          onInput=(set this 'barcode')
        )
        onTaskComplete=this.onTaskComplete.perform
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
