/* import __COLOCATED_TEMPLATE__ from './image-type.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CriteriaVisorImageType extends Component {
  @tracked isDisplayed = Boolean(this.args.imageType);

  get imageTypeOptions() {
    return [
      { label: 'JPG', value: 'jpg' },
      { label: 'BMP', value: 'bmp' },
      { label: 'PDF', value: 'pdf' },
      { label: 'SVG', value: 'svg' },
      { label: 'PNG', value: 'png' },
    ];
  }

  get breadcrumb() {
    return this.imageTypeOptions.find(opt => opt.value === this.args.imageType)?.label;
  }

  constructor() {
    super(...arguments);
    this.args.initParam('imageType', this.args.imageType);
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.args.updateParam('imageType', null);
    this.isDisplayed = isDisplayed;
  }

  @action
  onImageTypeSelection(imageType) {
    this.args.updateParam('imageType', imageType?.value);
  }
}
