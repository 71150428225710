/* import __COLOCATED_TEMPLATE__ from './bom-variables.hbs'; */
import Component from '@glimmer/component';

class JemBomVariables extends Component {
  get displayVariableValue() {
    const selectedId = this.args.jemDisplayVariable?.id;
    const variable = this.args.buildDatumVariables?.find(item => item.id === selectedId);
    return variable?.rawValue;
  }
}

export default JemBomVariables;
