import Service from '@ember/service';
import config from 'eflex/config/environment';

export default class RaygunService extends Service {
  rg4js;

  async loadRaygun() {
    this.rg4js = (await import('raygun4js')).default;
    this.rg4js('apiKey', config.raygun.apiKey);
    this.rg4js('enableCrashReporting', true);
    this.rg4js('setVersion', config.APP.version);
    this.rg4js('logContentsOfXhrCalls', true);

    this.rg4js('withCustomData', {
      environment: config.environment,
      appName: config.APP.name,
    });
  }

  sendError(error) {
    this.rg4js?.('send', error);
  }
}
