/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class SearchInput extends Component {
  @action
  onSubmit() {
    if (!this.args.disabled) {
      return this.args.onSubmit?.(this.args.value);
    }
  }

  @action
  onClear(e) {
    if (isEmpty(e.target.value)) {
      return this.args.onClear?.();
    }
  }
}
