/* eslint-disable prefer-const */
import Cache from '@ember-data/json-api';
import { graphFor, peekGraph } from '@ember-data/graph/-private';
import { dequal } from 'dequal';
import { isArray } from '@ember/array';
import { assert } from '@ember/debug';

const unloadDeleted = (identifier, storeWrapper) => {
  const store = storeWrapper._store;
  store._instanceCache.unloadRecord(identifier);
  store.recordArrayManager.identifierRemoved(identifier);
};

const attrEquals = (attributeDefinition, oldValue, newValue) => {
  if (attributeDefinition?.options?.embedded) {
    return dequal(oldValue, newValue);
  }

  return oldValue === newValue;
};

const unloadEmbeddedFromDiff = (
  identifier,
  relationshipDefinition,
  relationshipData,
  storeWrapper,
  graph,
) => {
  if (!relationshipDefinition.options.embedded || relationshipDefinition.options.noCascade) {
    return;
  }

  const relationship = graph.get(identifier, relationshipDefinition.name);
  const remoteState = relationship.remoteState;

  if (!remoteState) {
    return;
  }

  if (relationshipDefinition.kind === 'hasMany') {
    [...remoteState].forEach(member => {
      if (!relationshipData.data.some(rel => rel.id === member.id)) {
        unloadDeleted(member, storeWrapper);
      }
    });
  } else if (remoteState.id && remoteState.id !== relationshipData.data?.id) {
    unloadDeleted(remoteState, storeWrapper);
  }
};

const rollbackEmbedded = (identifier, storeWrapper) => {
  const store = storeWrapper._store;

  if (store.isDestroying || store.isDestroyed) {
    return;
  }

  const record = store.peekRecord(identifier);

  if (!record) {
    return;
  }

  record.eachRelationship((relationshipName, relationship) => {
    if (!relationship.options?.embedded) {
      return;
    }

    const related = record[relationshipName];
    if (isArray(related)) {
      related.forEach(child => {
        if (child?.isDirty) {
          child.rollbackAttributes();
        }
      });
    } else if (related?.isDirty) {
      related.rollbackAttributes();
    }
  });
};

const cleanEmbedded = (identifier, storeWrapper, useLocalState) => {
  const graph = peekGraph(storeWrapper);

  if (!graph) {
    return;
  }

  const relationships = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor(identifier);

  for (const relationshipName in relationships) {
    const relationshipDefinition = relationships[relationshipName];

    if (
      (!relationshipDefinition.options.cascadeDelete && !relationshipDefinition.options.embedded) ||
      relationshipDefinition.options.noCascade
    ) {
      continue;
    }

    const relationshipGraph = graph.get(identifier, relationshipDefinition.name);

    let state;

    if (useLocalState) {
      state = relationshipGraph.localState;
    } else {
      state = relationshipGraph.remoteState;
    }

    if (state) {
      if (relationshipDefinition.kind === 'hasMany') {
        [...state].forEach(member => {
          unloadDeleted(member, storeWrapper);
        });
      } else {
        unloadDeleted(state, storeWrapper);
      }
    }
  }
};

function notifyAttributes(storeWrapper, identifier, keys) {
  if (!keys) {
    storeWrapper.notifyChange(identifier, 'attributes');
    return;
  }

  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < keys.length; i++) {
    storeWrapper.notifyChange(identifier, 'attributes', keys[i]);
  }
}

function calculateChangedKeys(attributesDefinition, cached, updates) {
  const changedKeys = [];

  if (updates) {
    const keys = Object.keys(updates);
    const length = keys.length;
    const localAttrs = cached.localAttrs;
    const original = Object.assign(Object.create(null), cached.remoteAttrs, cached.inflightAttrs);

    for (let i = 0; i < length; i++) {
      let key = keys[i];
      let value = updates[key];

      // A value in localAttrs means the user has a local change to
      // this attribute. We never override this value when merging
      // updates from the backend so we should not sent a change
      // notification if the server value differs from the original.
      if (localAttrs && localAttrs[key] !== undefined) {
        continue;
      }

      if (!attrEquals(attributesDefinition[key], original[key], value)) {
        changedKeys.push(key);
      }
    }
  }

  return changedKeys;
}

function setupRelationships(
  storeWrapper,
  identifier,
  data,
) {

  const relationships = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor(identifier);
  const keys = Object.keys(relationships);

  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < keys.length; i++) {
    const relationshipName = keys[i];
    const relationshipData = data.relationships[relationshipName];

    if (!relationshipData) {
      continue;
    }

    const graph = graphFor(storeWrapper);

    unloadEmbeddedFromDiff(
      identifier,
      relationships[relationshipName],
      relationshipData,
      storeWrapper,
      graph,
    );

    graph.push({
      op: 'updateRelationship',
      record: identifier,
      field: relationshipName,
      value: relationshipData,
    });
  }
}

function patchLocalAttributes(identifier, cached, attributesDefinition) {
  const { localAttrs, remoteAttrs, inflightAttrs, changes } = cached;
  if (!localAttrs) {
    cached.changes = null;
    return false;
  }
  let hasAppliedPatch = false;
  let mutatedKeys = Object.keys(localAttrs);

  for (let i = 0, length = mutatedKeys.length; i < length; i++) {
    let attr = mutatedKeys[i];
    // eslint-disable-next-line max-len, no-nested-ternary
    const existing = inflightAttrs && attr in inflightAttrs ? inflightAttrs[attr] : remoteAttrs && attr in remoteAttrs ? remoteAttrs[attr] : undefined;

    if (attrEquals(attributesDefinition[attr], localAttrs[attr], existing)) {
      hasAppliedPatch = true;
      delete localAttrs[attr];
      delete changes[attr];
    }
  }

  return hasAppliedPatch;
}

function cacheIsEmpty(cached) {
  return !cached || cached.remoteAttrs === null && cached.inflightAttrs === null && cached.localAttrs === null;
}

function _isEmpty(peeked) {
  if (!peeked) {
    return true;
  }
  const isNew = peeked.isNew;
  const isDeleted = peeked.isDeleted;
  const isEmpty = cacheIsEmpty(peeked);
  return (!isNew || isDeleted) && isEmpty;
}

function recordIsLoaded(cached, filterDeleted = false) {
  if (!cached) {
    return false;
  }
  const isNew = cached.isNew;
  const isEmpty = cacheIsEmpty(cached);

  // if we are new we must consider ourselves loaded
  if (isNew) {
    return !cached.isDeleted;
  }
  // even if we have a past request, if we are now empty we are not loaded
  // typically this is true after an unloadRecord call

  // if we are not empty, not new && we have a fulfilled request then we are loaded
  // we should consider allowing for something to be loaded that is simply "not empty".
  // which is how RecordState currently handles this case; however, RecordState is buggy
  // in that it does not account for unloading.
  return filterDeleted && cached.isDeletionCommitted ? false : !isEmpty;
}

function _isLoading(peeked, storeWrapper, identifier) {
  // TODO refactor things such that the cache is not required to know
  // about isLoading
  // @ts-expect-error
  const req = storeWrapper._store.getRequestStateService();
  // const fulfilled = req.getLastRequestForRecord(identifier);
  const isLoaded = recordIsLoaded(peeked);
  return !isLoaded &&
  // fulfilled === null &&
  req.getPendingRequestsForRecord(identifier).some(_req => _req.type === 'query');
}

function putOne(
  cache,
  identifiers,
  resource,
) {
  assert(
    `You must include an 'id' for the resource data ${resource.type}`,
    resource.id !== null && resource.id !== undefined && resource.id !== '',
  );
  assert(
    // eslint-disable-next-line max-len
    `Missing Resource Type: received resource data with a type '${resource.type}' but no schema could be found with that name.`,
    cache.__storeWrapper.getSchemaDefinitionService().doesTypeExist(resource.type),
  );
  let identifier = identifiers.peekRecordIdentifier(resource);

  if (identifier) {
    identifier = identifiers.updateRecordIdentifier(identifier, resource);
  } else {
    identifier = identifiers.getOrCreateRecordIdentifier(resource);
  }
  cache.upsert(identifier, resource, cache.__storeWrapper.hasRecord(identifier));
  // even if the identifier was not "existing" before, it is now
  return identifier;
}

export default class EmberDataCache extends Cache {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  upsert(
    identifier,
    data,
    calculateChanges,
  ) {
    let changedKeys;
    const peeked = this.__safePeek(identifier, false);
    const existed = !!peeked;
    const cached = peeked || this._createCache(identifier);
    const isLoading = _isLoading(peeked, this.__storeWrapper, identifier) || !recordIsLoaded(peeked);
    let isUpdate = !_isEmpty(peeked) && !isLoading;

    if (cached.isNew) {
      cached.isNew = false;
      this.__storeWrapper.notifyChange(identifier, 'identity');
      this.__storeWrapper.notifyChange(identifier, 'state');
    }

    if (calculateChanges) {
      const attributesDefinition = this.__storeWrapper.getSchemaDefinitionService().attributesDefinitionFor(identifier);
      // eslint-disable-next-line max-len
      changedKeys = existed ? calculateChangedKeys(attributesDefinition, cached, data.attributes) : Object.keys(data.attributes || {});
    }

    cached.remoteAttrs = Object.assign(cached.remoteAttrs || Object.create(null), data.attributes);

    if (cached.localAttrs) {
      const attributesDefinition = this.__storeWrapper.getSchemaDefinitionService().attributesDefinitionFor(identifier);
      if (patchLocalAttributes(identifier, cached, attributesDefinition)) {
        this.__storeWrapper.notifyChange(identifier, 'state');
      }
    }

    if (!isUpdate) {
      this.__storeWrapper.notifyChange(identifier, 'added');
    }

    if (data.id) {
      cached.id = data.id;
    }

    if (data.relationships) {
      setupRelationships(this.__storeWrapper, identifier, data);
    }

    // eslint-disable-next-line unicorn/explicit-length-check
    if (changedKeys && changedKeys.length) {
      notifyAttributes(this.__storeWrapper, identifier, changedKeys);
    }

    return changedKeys;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  didCommit(committedIdentifier, result) {
    const payload = result.content;
    const operation = result.request.op;
    const data = payload && payload.data;

    if (!data) {
      assert(
        // eslint-disable-next-line max-len
        `Your ${committedIdentifier.type} record was saved to the server, but the response does not have an id and no id has been set client side. Records must have ids. Please update the server response to provide an id in the response or generate the id on the client side either before saving the record or while normalizing the response.`,
        committedIdentifier.id,
      );
    }

    const { identifierCache } = this.__storeWrapper;
    const existingId = committedIdentifier.id;
    const identifier =
      operation !== 'deleteRecord' && data
        ? identifierCache.updateRecordIdentifier(committedIdentifier, data)
        : committedIdentifier;

    const cached = this.__peek(identifier, false);
    if (cached.isDeleted) {
      graphFor(this.__storeWrapper).push({
        op: 'deleteRecord',
        record: identifier,
        isNew: false,
      });
      cached.isDeletionCommitted = true;
      this.__storeWrapper.notifyChange(identifier, 'removed');
      cleanEmbedded(identifier, this.__storeWrapper, false);
    }

    cached.isNew = false;

    let newCanonicalAttributes;
    if (data) {
      if (data.id && !cached.id) {
        cached.id = data.id;
      }

      if (identifier === committedIdentifier && identifier.id !== existingId) {
        this.__storeWrapper.notifyChange(identifier, 'identity');
      }

      assert(
        // eslint-disable-next-line max-len
        `Expected the ID received for the primary '${identifier.type}' resource being saved to match the current id '${cached.id}' but received '${identifier.id}'.`,
        identifier.id === cached.id,
      );

      if (data.relationships) {
        setupRelationships(this.__storeWrapper, identifier, data);
      }
      newCanonicalAttributes = data.attributes;
    }

    const attributesDefinition = this.__storeWrapper.getSchemaDefinitionService().attributesDefinitionFor(identifier);
    const changedKeys = calculateChangedKeys(attributesDefinition, cached, newCanonicalAttributes);

    // eslint-disable-next-line max-len
    cached.remoteAttrs = Object.assign(cached.remoteAttrs || Object.create(null), cached.inflightAttrs, newCanonicalAttributes);
    cached.inflightAttrs = null;
    patchLocalAttributes(identifier, cached, attributesDefinition);

    if (cached.errors) {
      cached.errors = null;
      this.__storeWrapper.notifyChange(identifier, 'errors');
    }

    notifyAttributes(this.__storeWrapper, identifier, changedKeys);
    this.__storeWrapper.notifyChange(identifier, 'state');

    const included = payload && payload.included;
    if (included) {
      for (let i = 0, length = included.length; i < length; i++) {
        putOne(this, identifierCache, included[i]);
      }
    }

    return {
      data: identifier,
    };
  }

  getAttr(identifier, attr) {
    const cached = this.__peek(identifier, true);
    if (!cached) {
      return null;
    }
    return super.getAttr(identifier, attr);
  }

  setAttr(identifier, attr, value) {
    const cached = this.__peek(identifier, false);
    // eslint-disable-next-line max-len, no-nested-ternary
    const existing = cached.inflightAttrs && attr in cached.inflightAttrs ? cached.inflightAttrs[attr] : cached.remoteAttrs && attr in cached.remoteAttrs ? cached.remoteAttrs[attr] : undefined;

    const attributesDefinition = this.__storeWrapper.getSchemaDefinitionService().attributesDefinitionFor(identifier);

    if (!attrEquals(attributesDefinition[attr], existing, value)) {
      cached.localAttrs = cached.localAttrs || Object.create(null);
      cached.localAttrs[attr] = value;
      cached.changes = cached.changes || Object.create(null);
      cached.changes[attr] = [existing, value];
    } else if (cached.localAttrs) {
      delete cached.localAttrs[attr];
      delete cached.changes[attr];
    }

    this.__storeWrapper.notifyChange(identifier, 'attributes', attr);
  }

  unloadRecord(identifier) {
    cleanEmbedded(identifier, this.__storeWrapper, true);
    super.unloadRecord(identifier);
  }

  rollbackAttrs(identifier) {
    rollbackEmbedded(identifier, this.__storeWrapper);
    return super.rollbackAttrs(identifier);
  }
}
