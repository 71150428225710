/* import __COLOCATED_TEMPLATE__ from './task-grid.hbs'; */
import getDelayTime from 'eflex/util/get-delay-time';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import $ from 'jquery';
import { sum } from 'ramda';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

export default class JemTaskGrid extends Component {
  @service taskRepo;
  @service currentUser;

  onResize = task({ restartable: true }, waitFor(async element => {
    // eslint-disable-next-line ember/no-jquery
    $(window).trigger('resize.JColResizer');
    await timeout(getDelayTime(100));
    this.#saveWidthsToStorage(element);
  }));

  onDidInsert = task(waitFor(async element => {
    await import('@eflexsystems/colresizable');

    // eslint-disable-next-line ember/no-jquery
    this.colResizable = $(element).colResizable({
      postbackSafe: true,
      removePadding: false,
      headerOnly: true,
      minWidth: 60,
      onResize: ({ currentTarget }) => {
        this.#saveWidthsToStorage(currentTarget);
      },
    });

    registerDestructor(this, () => {
      this.colResizable?.destroy();
      this.colResizable = null;
    });

    await this.onResize.perform(element);
  }));

  getTaskConfig = (treeTask, model, buildDatum) => {
    return this.taskRepo.getConfigForModelOrBuildDatum(
      treeTask,
      model,
      buildDatum,
    );
  };

  getChildStatus = (treeTask, buildStatus) => {
    return buildStatus?.getChildStatusForTask(treeTask);
  };

  taskIsDisplayed = (taskConfig, treeTask, buildStatus) => {
    if (taskConfig?.disabled) {
      return false;
    }

    const decisionTags = treeTask.decisionTags;

    if (buildStatus && !isEmpty(decisionTags)) {
      const allowedDecisions = new Set(buildStatus.getAllowedDecisions(treeTask));
      if (!decisionTags.some(tag => allowedDecisions.has(tag))) {
        return false;
      }
    }

    const jemVisibilityTags = treeTask.jemVisibilityTags;

    if (isPresent(jemVisibilityTags)) {
      const userVisibilityTags = new Set(this.currentUser.user.visibilityTags);
      if (!jemVisibilityTags.some(tag => userVisibilityTags.has(tag))) {
        return false;
      }
    }

    return true;
  };

  #saveWidthsToStorage(element) {
    this.colResizable?.forceSyncGrips();

    if (element == null) {
      return;
    }
    const widths = [...element.querySelectorAll('th')].map(elem =>
      // eslint-disable-next-line ember/no-jquery
      $(elem).outerWidth(),
    );

    // colResizable needs a string in this format for localstorage
    localStorage['jem-task-table'] = `${widths.join(';')};${sum(widths)}`;
  }
}
