/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { waitForPromise } from '@ember/test-waiters';

export default class DiffModal extends Component {
  formattedDiff = trackedFunction(this, async () => {
    const left = this.args.left;
    const right = this.args.right;

    const [jsondiffpatch, htmlFormatter] = await waitForPromise(
      Promise.all([
        import('jsondiffpatch'),
        import('jsondiffpatch/formatters/html'),
      ]),
    );

    const delta = jsondiffpatch
      .create({
        objectHash(obj, index) {
          return obj.id ?? obj._id ?? obj?.eflex?.id ?? obj?.eflex?._id ?? `$$index:${index}`;
        },
      })
      .diff(left, right);

    htmlFormatter.hideUnchanged();

    return htmlFormatter.format(delta, left);
  });
}
