/* import __COLOCATED_TEMPLATE__ from './user-dropdown.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import LocaleMap from 'eflex/util/locale-map';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { capitalize } from '@ember/string';

export default class UserDropdown extends Component {
  @service currentUser;
  @service session;

  languages = LocaleMap;

  @tracked showLogoutModal = false;

  get user() {
    return this.currentUser.user;
  }

  get userRole() {
    return this.user?.role;
  }

  get userAbbreviation() {
    if (!this.user) {
      return null;
    }

    return capitalize((this.user.userName.slice(0, 1)));
  }

  @action
  noHide() {
    return false;
  }

  @action
  languageChanged(language) {
    return this.currentUser.changeLanguage.perform(language.lang);
  }

  @action
  logOut() {
    this.session.invalidate();
  }
}
