/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class BiPanelUniqueSerial extends Component {
  options = [
    {
      label: 'useUniqueSerialNumbers',
      value: true,
    },
    {
      label: 'useTotalCycles',
      value: false,
    },
  ];
}
