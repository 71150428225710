import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { cached } from '@glimmer/tracking';
import { HARDWARE_REQUIRED_TASK_TYPES, HARDWARE_NOT_REQUIRED_TASK_TYPES, HARDWARE_OPTIONAL_TASK_TYPES } from 'eflex/constants/tasks/task-types';
import { isEmpty } from '@ember/utils';
import getTaskHardwareInput from 'eflex/helpers/get-task-hardware-input';
import getTaskHardwareOutput from 'eflex/helpers/get-task-hardware-output';
import getHardwareInputDefIo from 'eflex/helpers/get-hardware-input-def-io';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
const checkPickHasFault = (station1, task1)=>{
    const output1 = getTaskHardwareOutput(station1, task1);
    if (output1 == null || !output1.isConnected) {
        return true;
    }
    const input1 = getTaskHardwareInput(station1, task1);
    if (input1 != null && !input1.isConnected) {
        return true;
    }
    return false;
};
const checkErrorProofingHasFault = (station1, hardwareInputDefs1)=>{
    if (isEmpty(hardwareInputDefs1)) {
        return false;
    }
    return hardwareInputDefs1.some((inputDef1)=>{
        const input1 = getHardwareInputDefIo(station1, inputDef1);
        return !input1 || !input1.isConnected;
    });
};
export default class JemTaskTaskFault extends Component {
    @service
    intl;
    @cached
    get hasHardwareFault() {
        const task1 = this.args.task;
        const taskType1 = task1.taskType;
        if (HARDWARE_NOT_REQUIRED_TASK_TYPES.has(taskType1)) {
            return false;
        }
        const hardware1 = this.args.hardware;
        if (HARDWARE_OPTIONAL_TASK_TYPES.has(taskType1) && hardware1 == null) {
            return false;
        }
        const station1 = this.args.station;
        if (task1.isErrorProofing) {
            return checkErrorProofingHasFault(station1, task1.hardwareInputDefs);
        } else if (task1.isPick) {
            return checkPickHasFault(station1, task1);
        }
        return hardware1?.isConnected === false;
    }
    get taskErrorMessage() {
        if (HARDWARE_REQUIRED_TASK_TYPES.has(this.args.task.taskType) && this.args.hardware == null) {
            return this.intl.t('jem.hardwareMissing');
        } else if (this.hasHardwareFault) {
            return this.intl.t('jem.hardwareUnreachable');
        } else if (this.shouldFaultCalibrationExpiration) {
            return this.intl.t('jem.calibrationExpired');
        } else {
            return null;
        }
    }
    get daysUntilCalibrationExpiration() {
        return moment(this.args.hardware?.calibrationExpirationDate).startOf('day').diff(moment().startOf('day'), 'days');
    }
    get shouldWarnCalibrationExpiration() {
        const hardware1 = this.args.hardware;
        return (hardware1 && hardware1.calibrationExpirationDate && hardware1.calibrationDate && hardware1.warningThresholdDays && moment(hardware1.calibrationExpirationDate).startOf('day').subtract(hardware1.warningThresholdDays, 'days') <= moment().startOf('day'));
    }
    get shouldFaultCalibrationExpiration() {
        const hardware1 = this.args.hardware;
        return (hardware1 && hardware1.calibrationExpirationDate && hardware1.calibrationDate && moment(hardware1.calibrationExpirationDate).startOf('day') <= moment().startOf('day'));
    }
    static{
        template(`
    {{#if this.taskErrorMessage}}
      <div class="fault-message bg-danger text-white text-center font-size-base p-1">
        <FaIcon @icon="triangle-exclamation" class="me-2" />{{this.taskErrorMessage}}
      </div>
    {{else if this.shouldWarnCalibrationExpiration}}
      <div class="fault-message bg-warning text-black text-center font-size-base p-1">
        <FaIcon
          @icon="circle-exclamation"
          class="me-2" />{{t "jem.calibrationWarning" days=this.daysUntilCalibrationExpiration}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
