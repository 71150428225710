/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { without } from 'ramda';
import MarriageStatuses from 'eflex/constants/part-marriage-statuses';
import TaskStatuses from 'eflex/constants/task-statuses';

export default class RepairPanel extends Component {
  @service store;
  @service currentUser;
  @service intl;

  @tracked showConfirmModal = false;
  @tracked divorceChild = false;
  @tracked containChild = false;
  @tracked scrapChild = false;

  get actionsDisabled() {
    return this.args.childStatus.isDivorced;
  }

  get confirmModalText() {
    if (this.divorceChild && !this.containChild && !this.scrap) {
      return this.intl.t('repair.divorceChildConfirm');
    } else if (this.divorceChild && this.containChild && !this.scrap) {
      return this.intl.t('repair.containChildConfirm');
    } else if (this.divorceChild && this.containChild && this.scrap) {
      return this.intl.t('repair.scrapChildConfirm');
    } else {
      return null;
    }
  }

  commitMarriageRepair = task(waitFor(async () => {
    const childStatus = this.args.childStatus;
    const scannedBarcode = childStatus.scannedBarcode;
    const buildStatus = childStatus.parent;
    const userName = this.currentUser.user.userName;

    if (this.divorceChild || this.scrapChild) {
      buildStatus.marriedSerialNumbers = without([scannedBarcode], buildStatus.marriedSerialNumbers);
      buildStatus.status = TaskStatuses.NEEDS_REWORK;

      const newMarriageStatus = MarriageStatuses.createStatusRecord(
        userName,
        Date.now(),
        scannedBarcode,
        MarriageStatuses.DIVORCED,
      );
      childStatus.partMarriageStatus = [...childStatus.partMarriageStatus, newMarriageStatus];
      childStatus.status = TaskStatuses.NEEDS_REWORK;
    }

    if (this.containChild) {
      const jemContainment = this.store.createRecord('jemContainment', {
        serialNumber: scannedBarcode,
        userName,
        scrap: this.scrapChild,
      });

      await jemContainment.save();
    }

    await buildStatus.save();
    await this.args.onClose?.();
  }));

  @action
  divorceChildAction() {
    this.divorceChild = true;
    this.showConfirmModal = true;
  }

  @action
  containChildAction() {
    this.divorceChild = true;
    this.containChild = true;
    this.showConfirmModal = true;
  }

  @action
  scrapChildAction() {
    this.divorceChild = true;
    this.containChild = true;
    this.scrapChild = true;
    this.showConfirmModal = true;
  }
}
