/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class BiAverageQualityLabel extends Component {
  get averageReject() {
    return (this.args.rejectCount / this.args.total) * 100;
  }

  get averageRejectRate() {
    return this.averageReject?.toFixed(2).replace(/\.00/, '');
  }

  get averageQuality() {
    return (100 - this.averageReject).toFixed(2).replace(/\.00/, '');
  }
}
