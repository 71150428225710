/* import __COLOCATED_TEMPLATE__ from './hardware.hbs'; */
import Component from '@glimmer/component';
import AdamIo from 'eflex/components/hardware/adam-io';
import CognexCamera from 'eflex/components/hardware/cognex-camera';
import Datalogic from 'eflex/components/hardware/datalogic';
import Edhr from 'eflex/components/hardware/edhr';
import Keyence from 'eflex/components/hardware/keyence';
import LightGuide from 'eflex/components/hardware/light-guide';
import NodeRed from 'eflex/components/hardware/node-red';
import OpenProtocolTool from 'eflex/components/hardware/open-protocol-tool';
import PlcHardware from 'eflex/components/hardware/plc-hardware';
import TurckIo from 'eflex/components/hardware/turck-io';
import UniversalRobot from 'eflex/components/hardware/universal-robot';
import WebCam from 'eflex/components/hardware/web-cam';
import ZebraPrinter from 'eflex/components/hardware/zebra-printer';

export default class HardwarePanel extends Component {
  get hardwareConfigComponent() {
    switch (this.args.hardware.constructor.modelName) {
      case 'adam-io': {
        return AdamIo;
      }
      case 'cognex-camera': {
        return CognexCamera;
      }
      case 'datalogic': {
        return Datalogic;
      }
      case 'edhr': {
        return Edhr;
      }
      case 'keyence': {
        return Keyence;
      }
      case 'light-guide': {
        return LightGuide;
      }
      case 'node-red': {
        return NodeRed;
      }
      case 'open-protocol-tool': {
        return OpenProtocolTool;
      }
      case 'plc-hardware': {
        return PlcHardware;
      }
      case 'turck-io': {
        return TurckIo;
      }
      case 'universal-robot': {
        return UniversalRobot;
      }
      case 'web-cam': {
        return WebCam;
      }
      case 'zebra-printer': {
        return ZebraPrinter;
      }
      default: {
        return null;
      }
    }
  }
}
