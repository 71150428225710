/* import __COLOCATED_TEMPLATE__ from './task-config.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import TaskConfigBarcode from 'eflex/components/task-config/barcode';
import TaskConfigButton from 'eflex/components/task-config/button';
import TaskConfigCobot from 'eflex/components/task-config/cobot';
import TaskConfigDecision from 'eflex/components/task-config/decision';
import TaskConfigErrorProofing from 'eflex/components/task-config/errorproofing';
import TaskConfigLightGuide from 'eflex/components/task-config/lightguide';
import TaskConfigMultispindle from 'eflex/components/task-config/multispindle';
import TaskConfigNodeRed from 'eflex/components/task-config/nodered';
import TaskConfigPick from 'eflex/components/task-config/pick';
import TaskConfigPlc from 'eflex/components/task-config/plc';
import TaskConfigPushToSchedule from 'eflex/components/task-config/pushtoschedule';
import TaskConfigSerialNumberTransfer from 'eflex/components/task-config/serialnumbertransfer';
import TaskConfigTimer from 'eflex/components/task-config/timer';
import TaskConfigTorque from 'eflex/components/task-config/torque';
import TaskConfigVision from 'eflex/components/task-config/vision';

class TaskConfigComponent extends Component {
  @service taskConfigRepo;

  get taskConfigComponent() {
    switch (this.args.task.taskType) {
      case 'barcode': {
        return TaskConfigBarcode;
      }
      case 'button': {
        return TaskConfigButton;
      }
      case 'cobot': {
        return TaskConfigCobot;
      }
      case 'decision': {
        return TaskConfigDecision;
      }
      case 'errorProofing': {
        return TaskConfigErrorProofing;
      }
      case 'lightGuide': {
        return TaskConfigLightGuide;
      }
      case 'multispindle': {
        return TaskConfigMultispindle;
      }
      case 'nodeRed': {
        return TaskConfigNodeRed;
      }
      case 'pick': {
        return TaskConfigPick;
      }
      case 'plc': {
        return TaskConfigPlc;
      }
      case 'pushToSchedule': {
        return TaskConfigPushToSchedule;
      }
      case 'serialNumberTransfer': {
        return TaskConfigSerialNumberTransfer;
      }
      case 'timer': {
        return TaskConfigTimer;
      }
      case 'torque': {
        return TaskConfigTorque;
      }
      case 'vision': {
        return TaskConfigVision;
      }
      default: {
        return null;
      }
    }
  }

  get hasComplexOption() {
    return this.args.task?.component?.options?.some(item => item.isSimple === false) ?? false;
  }

  copyConfigToAll = (taskConfig) => {
    this.taskConfigRepo.copyToAllContexts(taskConfig);
  };
}

export default TaskConfigComponent;
