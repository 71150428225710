import moment from 'moment-timezone';

export default function formatCycleTime(value) {
  if (value == null) {
    return;
  }

  value = parseInt(value);
  return moment.duration(value).format('m[m] s[s]', 1);
}
