/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { StrokeOptions, ShapeTypes, FABRIC_FILL_HEX_LENGTH } from 'eflex/constants/work-instructions/tool-props';
import { hexOpacityToDec, decOpacityToHex } from 'eflex/util/opacity-helper';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import { all, task, waitForProperty, waitForQueue } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { updateObjectProperty } from 'eflex/util/fabric-helpers';

export default class WorkInstructionEditorToolPropertiesShape extends Component {
  @service imageEditor;
  @service systemConfig;
  @service store;

  @tracked selectedShape = ShapeTypes.RECT;
  @tracked selectedFillColor = '#FFFFFF';
  @tracked selectedFillOpacity = 100;
  @tracked selectedStrokeColor = '#000000';
  @tracked selectedStrokeOpacity = 100;
  @tracked currentShape;
  @tracked link = '';
  @tracked startHidden = false;
  @tracked dynamicObjectName;
  @tracked selectedStrokeWidth = 1;
  @tracked selectedStrokeStyle = StrokeOptions.default;
  @tracked strokeDashArray = [];
  @tracked calculatedFillColor = '#FFFFFFFF';
  @tracked calculatedStrokeColor = '#000000FF';

  get shapeSelectDisabled() {
    return !this.imageEditor.inToolMode;
  }

  get shapeTypes() {
    return [
      { value: ShapeTypes.RECT },
      { value: ShapeTypes.ELLIPSE },
      { value: ShapeTypes.TRIANGLE },
    ];
  }

  // jscpd:ignore-start
  onDidInsert = task(waitFor(async () => {
    await all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    if (this._activeObject() != null) {
      this.updateSelectedProperties();
    } else {
      this.#updateFromSystemConfig();
    }

    this.imageEditor
      .on('selection:updated', this.updateSelectedProperties)
      .on('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      .on('mouse:down', this.#placeShape)
      .on('mouse:move', this.#adjustShape)
      .on('mouse:up', this.#releaseShape);
  }));

  _isShape(obj) {
    return this.shapeTypes.map(item => item.value).includes(obj?.type);
  }

  _activeObject() {
    const obj = this.imageEditor.canvas?.getActiveObject();
    if (this._isShape(obj)) {
      return obj;
    } else {
      return null;
    }
  }

  resetProperties() {
    Object.assign(this, {
      dynamicObjectName: null,
      link: '',
    });
  }
  // jscpd:ignore-end

  #updateFromSystemConfig = () => {
    this.resetProperties();
    const config = this.systemConfig.getWieDefaults('shape');

    const fillOpacity = config.fill?.opacity ?? 0;
    const strokeOpacity = config.stroke?.opacity ?? 0;

    Object.assign(this, {
      selectedShape: config.shape,

      calculatedFillColor: config.fill?.color + decOpacityToHex(fillOpacity),
      selectedFillColor: config.fill?.color,
      selectedFillOpacity: fillOpacity,

      calculatedStrokeColor: config.stroke?.color + decOpacityToHex(strokeOpacity),
      selectedStrokeColor: config.stroke?.color,
      selectedStrokeOpacity: strokeOpacity,
      strokeDashArray: generateStrokeDashArray(config.stroke?.style, config.stroke?.width),
      selectedStrokeWidth: config.stroke?.width,
      selectedStrokeStyle: config.stroke?.style,
    });
  };

  @action
  updateSelectedProperties() {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      selectedShape: obj.type,

      calculatedFillColor: obj.fill,
      selectedFillColor: obj.fill?.slice(0, FABRIC_FILL_HEX_LENGTH),
      selectedFillOpacity: hexOpacityToDec(obj.fill?.slice(FABRIC_FILL_HEX_LENGTH)),

      calculatedStrokeColor: obj.stroke,
      selectedStrokeColor: obj.stroke?.slice(0, FABRIC_FILL_HEX_LENGTH),
      selectedStrokeOpacity: hexOpacityToDec(obj.stroke?.slice(FABRIC_FILL_HEX_LENGTH)),
      strokeDashArray: obj.strokeDashArray,
      selectedStrokeWidth: obj.strokeWidth,
      selectedStrokeStyle: obj.eflex?.strokeDashArrayStyle ?? StrokeOptions.default,
      dynamicObjectName: obj.eflex?.dynamicObjectName,
      startHidden: obj.eflex?.startHidden,
      link: obj.eflex?.link,
    });
  }

  #placeShape = ({ e }) => {
    const { canvas } = this.imageEditor;
    if (!this.imageEditor.inToolMode || canvas.getActiveObject() != null) {
      return;
    }
    this.imageEditor.disableHistory();
    const coords = canvas.getPointer(e);
    this.imageEditor.disableHistory();

    const shape = this.imageEditor.addShape(this.selectedShape, {
      left: coords.x,
      top: coords.y,
      padding: 2,
      type: this.selectedShape,
      fill: this.calculatedFillColor,
      stroke: this.calculatedStrokeColor,
      strokeWidth: this.selectedStrokeWidth,
      strokeDashArray: this.strokeDashArray,
      eflex: {
        strokeDashArrayStyle: this.selectedStrokeStyle,
        dynamicObjectName: this.dynamicObjectName,
        startHidden: this.startHidden,
        link: this.link,
      },
    });

    this.currentShape = shape;
  };

  _adjustShapeCenterOut(shape, xDiff, yDiff) {
    const width = Math.abs(xDiff);
    const height = Math.abs(yDiff);

    shape.originX = 'center';
    shape.originY = 'center';

    if (shape.type === ShapeTypes.ELLIPSE) {
      shape.set({ rx: width, ry: height });
    } else {
      shape.set({ width: width * 2, height: height * 2 });
    }
  }

  _adjustShapeCornerOut(shape, xDiff, yDiff, fixedRatio = true) {
    const width = Math.abs(xDiff);
    const height = fixedRatio ? width : Math.abs(yDiff);

    shape.originX = xDiff > 0 ? 'right' : 'left';
    shape.originY = yDiff > 0 ? 'bottom' : 'top';

    if (shape.type === ShapeTypes.ELLIPSE) {
      shape.set({ rx: width / 2, ry: height / 2 });
    } else {
      shape.set({ width, height });
    }
  }

  #adjustShape = ({ e }) => {
    if (this.currentShape == null) {
      return;
    }

    const { canvas } = this.imageEditor;

    const centerOut = e.altKey && e.shiftKey;
    const fixedRatio = !e.altKey && e.shiftKey;

    const coords = canvas.getPointer(e);
    const xDiff = this.currentShape.left - coords.x;
    const yDiff = this.currentShape.top - coords.y;

    if (centerOut) {
      this._adjustShapeCenterOut(this.currentShape, xDiff, yDiff);
    } else {
      this._adjustShapeCornerOut(this.currentShape, xDiff, yDiff, fixedRatio);
    }

    this.currentShape.setCoords();
    canvas.renderAll();
  };

  #releaseShape = () => {
    this.imageEditor.enableHistory();

    if (this.currentShape == null) {
      return;
    }

    const { canvas } = this.imageEditor;

    this.currentShape.setCoords();
    canvas.fire('object:modified', { target: this.currentShape });
    canvas.setActiveObject(this.currentShape);

    this.args.objectCreated?.(this.currentShape);
    this.currentShape = null;
  };

  willDestroy() {
    super.willDestroy(...arguments);

    this.imageEditor
      .off('selection:updated', this.updateSelectedProperties)
      .off('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas
      ?.off('mouse:down')
      .off('mouse:move')
      .off('mouse:up');
  }

  // jscpd:ignore-start
  @action
  updateLink(val) {
    this.link = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'link', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateDynamicObjectName(val) {
    this.dynamicObjectName = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'dynamicObjectName', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStartHidden(val) {
    this.startHidden = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'startHidden', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateFillColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedFillColor: color,
      selectedFillOpacity: opacity,
      calculatedFillColor: combinedColor,
    });

    const obj = this._activeObject();
    updateObjectProperty(obj, 'fill', combinedColor);
    this.imageEditor.notifyModified(obj);
  }
  // jscpd:ignore-end

  @action
  updateStrokeColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedStrokeColor: color,
      selectedStrokeOpacity: opacity,
      calculatedStrokeColor: combinedColor,
    });

    const obj = this._activeObject();
    updateObjectProperty(obj, 'stroke', combinedColor);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStroke(strokeDashArray, selectedStrokeStyle, selectedStrokeWidth) {
    Object.assign(this, {
      strokeDashArray,
      selectedStrokeStyle,
      selectedStrokeWidth,
    });


    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'strokeDashArrayStyle', selectedStrokeStyle);
    updateObjectProperty(obj, 'strokeDashArray', strokeDashArray);
    updateObjectProperty(obj, 'strokeWidth', selectedStrokeWidth);
    this.imageEditor.notifyModified(obj);
  }

  @action
  onMakeDefault() {
    const shapeDefault = this.store.createRecord('wieConfig/shape', {
      shape: this.selectedShape,
      fill: this.store.createRecord('wieConfig/fill', {
        color: this.selectedFillColor,
        opacity: this.selectedFillOpacity,
      }),
      stroke: this.store.createRecord('wieConfig/stroke', {
        color: this.selectedStrokeColor,
        opacity: this.selectedStrokeOpacity,
        style: this.selectedStrokeStyle,
        width: this.selectedStrokeWidth,
      }),
    });

    this.systemConfig.config.wie.editorDefaults.shape = shapeDefault;
    return this.args.saveDefault();
  }
}
