/* import __COLOCATED_TEMPLATE__ from './tree.hbs'; */
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { sortByProp } from 'ramda-adjunct';

export default class CriteriaVisorTree extends Component {
  @tracked searchTerm;
  @tracked isDisplayed;

  @service intl;
  @service areaRepo;
  @service store;
  @service stationRepo;
  @service taskRepo;

  get #paramName() {
    if (this.args.type === 'station') {
      return 'stationIds';
    } else {
      return 'taskIds';
    }
  }

  get #allTreeItems() {
    if (this.args.type === 'station') {
      return this.stationRepo.stations;
    } else {
      return this.taskRepo.tasks;
    }
  }

  get #selectedTreeItemIds() {
    return this.args[this.#paramName];
  }

  get #allChecked() {
    return this.#allTreeItems.length === this.selectedTreeItems.size;
  }

  @cached
  get selectedTreeItems() {
    const treeItems = this.#selectedTreeItemIds?.map(treeItemId => this.store.peekRecord(this.args.type, treeItemId));
    return new Set(treeItems ?? []);
  }

  get breadcrumb() {
    if (this.#allChecked) {
      return null;
    }

    return this.selectedTreeItemsText;
  }

  get selectedTreeItemsText() {
    const selected = [...this.selectedTreeItems];
    const length = selected.length;

    if (length === 1) {
      return selected[0].name;
    }

    let caption;
    if (this.args.type === 'station') {
      caption = 'criteria.treeSearchStations';
    } else {
      caption = 'criteria.treeSearchTasks';
    }

    return this.intl.t(caption, { length });
  }

  get filteredAreas() {
    let areas = this.areaRepo.areas;

    if (!isBlank(this.searchTerm)) {
      const searchRegex = new RegExp(`${this.searchTerm}`, 'i');
      areas = areas.filter(area =>
        searchRegex.test(area.name) ||
        area.children.some(group => searchRegex.test(group.name)) ||
        area.stations.some(station => searchRegex.test(station.name)) ||
        area.tasks.some(treeTask => searchRegex.test(treeTask.name)),
      );
    }

    return sortByProp('order', areas ?? []);
  }

  constructor() {
    super(...arguments);
    const treeItemIds = this.#selectedTreeItemIds;
    this.args.initParam(this.#paramName, treeItemIds);
    this.isDisplayed = isPresent(treeItemIds);
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.#setAllTreeItemsChecked(isDisplayed);
  }

  @action
  toggleAll() {
    this.#setAllTreeItemsChecked(!this.#allChecked);
  }

  @action
  onTreeItemChecked(treeItem, isChecked) {
    const selectedTreeItems = new Set(this.selectedTreeItems);
    let children;

    if (treeItem.type === this.args.type) {
      children = [treeItem];
    } else if (this.args.type === 'station') {
      children = treeItem.stations;
    } else {
      children = treeItem.tasks;
    }

    children.forEach(child => {
      if (isChecked) {
        selectedTreeItems.add(child);
      } else {
        selectedTreeItems.delete(child);
      }
    });

    this.#updateTreeItemIds(selectedTreeItems);
  }

  #setAllTreeItemsChecked(checkAll) {
    if (!checkAll) {
      this.#updateTreeItemIds([]);
      return;
    }

    this.#updateTreeItemIds(this.#allTreeItems);
  }

  #updateTreeItemIds(selectedTreeItems) {
    selectedTreeItems = [...selectedTreeItems];

    if (isEmpty(selectedTreeItems)) {
      this.args.updateParam(this.#paramName, null);
      return;
    }

    const treeItemIds = selectedTreeItems.map(item => item.id);
    this.args.updateParam(this.#paramName, treeItemIds);
  }
}
