/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ref } from 'ember-ref-bucket';
import { waitFor } from '@ember/test-waiters';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';

export default class ConfigTable extends Component {
  @ref('scrollArea') scrollArea;
  @tracked topVisibility = 'opacity-0';
  @tracked bottomVisibility = 'opacity-1';

  onTopIntersection = task({ keepLatest: true }, waitFor(async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      this.topVisibility = 'opacity-0';
    } else {
      this.topVisibility = 'opacity-1';
    }

    await timeout(getDelayTime(150));
  }));

  onBottomIntersection = task({ keepLatest: true }, waitFor(async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      this.bottomVisibility = 'opacity-0';
    } else {
      this.bottomVisibility = 'opacity-1';
    }

    await timeout(getDelayTime(150));
  }));
}
