import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { bomSourceDropdownOptions } from 'eflex/constants/bom-source-types';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class BomSourcesIndexController extends Controller {
  @tracked selectedBomSource;
  @tracked showNewModal = false;
  @tracked showDeleteModal = false;
  @tracked selectedNewType = bomSourceDropdownOptions[0];

  bomSourceDropdownOptions = bomSourceDropdownOptions;

  @service bomSourceRepo;
  @service router;

  deleteSource = task(waitFor(async () => {
    await this.bomSourceRepo.delete.perform(this.selectedBomSource);
    this.showDeleteModal = false;
    this.selectedBomSource = null;
  }));

  @action
  createSource() {
    const bomSource = this.bomSourceRepo.create(this.selectedNewType.id);

    Object.assign(this, {
      showNewModal: false,
      selectedNewType: bomSourceDropdownOptions[0],
    });
    this.router.transitionTo('bomSources.bomSource', bomSource);
  }

  @action
  toggleDeleteModal(source) {
    Object.assign(this, {
      showDeleteModal: Boolean(source),
      selectedBomSource: source,
    });
  }
}
