import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import { flatten, pipe, pluck } from 'ramda';
import { compact, sortByProps } from 'ramda-adjunct';
import { dp } from '@eflexsystems/ramda-helpers';

export default class KineticJobsController extends Controller {
  queryParams = [
    { searchTerm: { as: 'search' } },
    'beginDate',
    'endDate',
  ];

  @tracked jobs = new TrackedArray();

  get allOperations() {
    return pipe(
      pluck('allOperations'),
      flatten,
      compact,
      sortByProps([dp('assembly.job.jobNumber'), dp('assembly.assemblySequence'), 'sequence']),
    )(this.jobs ?? []);
  }
}
