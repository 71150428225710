/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import filterByIds from 'eflex/helpers/filter-by-ids';

export default class BiPanelProcessData extends Component {
  @service jemProcessDataDefRepo;

  get controlType() {
    return this.args.controlType || 'power-select-multiple';
  }

  get filteredProcessDataDefs() {
    let processDataDefs =
      this.jemProcessDataDefRepo.jemProcessDataDefs.filter(item => item.type === 'float');

    if (isPresent(this.args.stationIds)) {
      processDataDefs = processDataDefs.filter((processDataDef) =>
        this.args.stationIds.includes(processDataDef.task?.station?.id),
      );
    }

    return processDataDefs;
  }

  get selected() {
    if (this.controlType === 'power-select-multiple') {
      return filterByIds(this.filteredProcessDataDefs, this.args.processDataDefIds);
    }

    const selectedId = this.args.processDataDefIds[0];

    if (!selectedId) {
      return null;
    }

    return this.filteredProcessDataDefs.find(dataDef => dataDef.id === selectedId);
  }

  @action
  onChange(value) {
    if (this.controlType === 'power-select-multiple') {
      value = value.map(item => item.id);
    } else {
      value = [value.id];
    }

    this.args.onChange(value);
  }
}
