/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  employeeId: [
    validator('inline', {
      validate(employeeId, options, modal) {
        if (modal.isInvalid) {
          return modal.intl.t('kinetic.invalidEmployee');
        }
        return true;
      },
    }),
  ],
})
class KineticEmployeeModal extends Component {
  @service kineticShiftRepo;
  @service kineticEmployeeRepo;
  @service intl;

  @tracked employeeId;
  @tracked employee;
  @tracked shift;
  @tracked isInvalid = false;

  get kineticShifts() {
    return this.kineticShiftRepo.kineticShifts;
  }

  get loginDisabled() {
    return !this.employee || !this.shift;
  }

  get shiftDisabled() {
    return !this.employee || this.employee.currentShift;
  }

  setEmployee = task(waitFor(async () => {
    this.employee = await this.kineticEmployeeRepo.getEmployee.perform(this.employeeId);

    if (!this.employee) {
      this.isInvalid = true;
      this.shift = null;
      return;
    }

    this.shift = this.employee.currentShift ?? this.employee.defaultShift;
  }));
}
export default KineticEmployeeModal;

