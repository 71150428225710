import { template } from "@ember/template-compiler";
import BlankTemplate from 'eflex/components/blank-template';
import { hash } from '@ember/helper';
export default template(`
  {{yield
    (hash
      taskDetails=BlankTemplate
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
