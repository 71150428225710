import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { isPresent } from '@ember/utils';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class GridRoute extends AuthenticatedRoute {
  @service notifier;
  @service eventBus;

  model(queryParams) {
    return this.getData.perform({
      ...queryParams,
      skip: isPresent(queryParams.page) ? (queryParams.page - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
    });
  }

  fetch = task(waitFor(async (params, pagination) => {
    const data = await this.getData.perform({
      ...params,
      ...pagination,
    });

    if (this.controller.selectAll) {
      data.forEach(datum => { datum.isSelected = true; });
    }

    if (isPresent(this.controller.page)) {
      this.controller.model = data;
    } else {
      this.controller.model.push(...data);
    }
  }));

  search = task(waitFor(async (params = {}) => {
    try {
      const data = await this.getData.perform({
        ...params,
        skip: 0,
        take: PAGE_SIZE,
      });
      if (this.controller.selectAll) {
        data.forEach(datum => { datum.isSelected = true; });
      }
      this.controller.model = data;
      this.eventBus.trigger('resetPagination');
    } catch (e) {
      console.error(e);
      this.notifier.sendError('logs.searchFailed');
    } finally {
      this.controller.selectAll = false;
    }
  }));

  resetController(controller) {
    super.resetController(...arguments);
    if (isPresent(controller.page)) {
      controller.page = 1;
    }
  }
}
