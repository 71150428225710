import { template } from "@ember/template-compiler";
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { and } from 'ember-truth-helpers';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
const setDeviceOnHardwareModel = (hardware1, device1)=>{
    Object.assign(hardware1, {
        cameraName: device1.label,
        cameraId: device1.deviceId
    });
};
export default class HardwareWebCam extends Component {
    @service
    webCam;
    availableDevices = trackedFunction(this, async ()=>{
        await this.webCam.load.perform('videoinput');
        return this.webCam.devices.filter((item1)=>item1.kind === 'videoinput');
    });
    static{
        template(`
    <div class="row form-row">
      <div class="col">
        {{#let this.availableDevices as |availableDevices|}}
          {{#if (and @hardware.isNew)}}
            <@form.element
              @controlType="power-select"
              @label={{t "webCam"}}
              @options={{availableDevices.promise}}
              @optionLabelPath="label"
              @value={{findBy "deviceId" @hardware.cameraId availableDevices.value}}
              @onChange={{fn setDeviceOnHardwareModel @hardware}}
            as |el|>
              <el.control @triggerClass="form-control" />
            </@form.element>
          {{else}}
            <@form.element
              @label={{t "webCam"}}
              @value={{@hardware.cameraName}}
            as |el|>
              <el.control readonly class="form-control-plaintext" />
            </@form.element>
          {{/if}}
        {{/let}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
