/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';

export default class TaskCard extends Component {
  @service taskRepo;
  @service store;
  @service currentUser;

  @cached
  get taskConfig() {
    const task = this.args.task;

    if (task.usesComponents) {
      return this.taskRepo.getConfig(task, task.component?.options[0]);
    } else {
      return this.taskRepo.getConfig(task, this.args.selectedContext);
    }
  }

  get startAndEndRows() {
    const task = this.args.task;

    if (task.sizeY === 1) {
      return task.row;
    }

    const x = parseInt(task.row);
    const y = parseInt(task.sizeY) - 1;

    return `${task.row}-${x + y}`;
  }

  @action
  moveTaskToStation(task, { id }) {
    const station = this.store.peekRecord('station', id);

    return this.args.moveTaskToStation(task, station);
  }
}
