import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';

export default class SerialNumberMonitorIndexRoute extends AuthenticatedRoute {
  @service bomSourceRepo;
  @service router;

  afterModel() {
    const bomSource = this.bomSourceRepo.allBomSources[0];
    if (bomSource != null) {
      this.router.transitionTo('serialNumberMonitor.bomSource', bomSource.id);
    }
  }
}
