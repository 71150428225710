/* import __COLOCATED_TEMPLATE__ from './log-level.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class CriteriaVisorLogLevel extends Component {
  @service intl;

  @tracked isDisplayed = Boolean(this.args.logLevel);
  @tracked includeInactive = Boolean(this.args.includeInactive);

  get includeInactiveBreadcrumb() {
    if (!this.args.includeInactive) {
      return null;
    }

    return this.intl.t('breadcrumbs.includeInactive');
  }

  get logLevelBreadcrumb() {
    const logLevel = this.args.logLevel;

    if (isBlank(logLevel) || logLevel === 'All') {
      return null;
    }

    return this.intl.t('breadcrumbs.logLevel', { logLevel });
  }

  constructor() {
    super(...arguments);
    this.args.initParam('logLevel', this.args.logLevel);
    this.args.initParam('includeInactive', this.args.includeInactive);
  }

  @action
  onDisplayedChanged(isDisplayed) {
    this.isDisplayed = isDisplayed;
    this.args.updateParam('logLevel', null);
    this.args.updateParam('includeInactive', null);
  }

  @action
  onSelectedLevelChanged(logLevel) {
    if (logLevel === 'All') {
      this.args.updateParam('logLevel', null);
      return;
    }

    this.args.updateParam('logLevel', logLevel);
  }

  @action
  onIncludeInactiveChanged(includeInactive) {
    if (includeInactive) {
      this.args.updateParam('includeInactive', true);
    } else {
      this.args.updateParam('includeInactive', null);
    }
  }
}
