import Controller from '@ember/controller';
import { task, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { sortByPaths } from 'ramda-adjunct';
import { prop, pipe, filter } from 'ramda';

export default class ProductionSchedulerController extends Controller {
  @service validationErrorNotifier;
  @service stationRepo;

  @tracked showSchedulePanel = false;
  @tracked selectedStation;

  get sortedStations() {
    return pipe(
      filter(prop('productionScheduleEnabled')),
      sortByPaths([
        ['area', 'order'],
        ['group', 'order'],
        ['order'],
      ]),
    )(this.stationRepo.stations);
  }

  get allProductionSchedules() {
    return this.sortedStations.flatMap(station => station.productionSchedules);
  }

  get isDirty() {
    return this.allProductionSchedules.some(item => item.isDirty);
  }

  get isInvalid() {
    return this.allProductionSchedules
      .filter(item => item.isDirty)
      .some(item => item.isInvalid);
  }

  save = task({ drop: true }, waitFor(async () => {
    const toSave = this.allProductionSchedules.filter(item => item.isDirty);

    if (toSave.some(item => item.isInvalid)) {
      this.validationErrorNotifier.sendErrors(toSave);
      return;
    }

    await all(toSave.map(productionSchedule => productionSchedule.save()));
  }));

  @action
  closeSchedulePanel() {
    Object.assign(this, {
      showSchedulePanel: false,
      selectedStation: null,
    });
  }

  @action
  rollback() {
    this.allProductionSchedules
      .filter(item => item.isDirty)
      .forEach(allProductionSchedule => { allProductionSchedule.rollbackAttributes(); });
  }

  @action
  openSchedulePanel(station) {
    Object.assign(this, {
      showSchedulePanel: true,
      selectedStation: station,
    });
  }

  @action
  onTreeItemUpdate() {
    if (this.selectedStation == null || this.selectedStation.productionScheduleEnabled) {
      return;
    }

    this.selectedStation.productionSchedules.forEach(productionSchedule => {
      productionSchedule.unloadRecord();
    });
    this.closeSchedulePanel();
  }
}
