import Service, { inject as service } from '@ember/service';

export default class ProductionScheduleRepoService extends Service {
  @service store;

  async getCurrent(station) {
    if (!station.productionScheduleEnabled) {
      return;
    }

    const productionSchedules = await this.store.query('productionSchedule', {
      stationId: station.id,
      isCompleted: false,
      includePartCounts: true,
      take: 1,
    });

    return productionSchedules[0];
  }
}
