/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import Control from 'ember-bootstrap/components/bs-form/element/control';
import { inject as service } from '@ember/service';

export default class DatePickerControl extends Control {
  @service intl;

  get locale() {
    const locale = this.intl.locale[0];
    if (locale === 'en-us') {
      return null;
    }
    return locale.replace(/-.*/, '');
  }

  onChange = ([date]) => {
    this.args.onChange?.(date);
  };
}
