import Service, { inject as service } from '@ember/service';
import { task, all, timeout } from 'ember-concurrency';
import SerialNumberStatuses from 'eflex/constants/serial-number-statuses';
import { clone, range } from 'ramda';
import { waitFor } from '@ember/test-waiters';

export default class BuildDataRepoService extends Service {
  @service store;
  @service notifier;

  buildData = this.store.peekAll('buildDatum');

  getTaskConfigForBuildDatum(treeTask, buildDatum) {
    const { component } = treeTask;

    const componentOptionId = component.isAlwaysRun
      ? component.options?.[0]?.id
      : buildDatum.components?.find(item => item.id === component.id)?.option?.id;

    if (componentOptionId == null) {
      return null;
    }

    return treeTask.taskConfigs.find(item => item.configOption?.id === componentOptionId);
  }

  create(bomSource) {
    if (bomSource == null) {
      return;
    }

    return this.store.createRecord('buildDatum', {
      userCreated: true,
      bomSource,
      status: SerialNumberStatuses.Queued.status,
      components: bomSource.components.map(component => {
        const componentFragment = {
          id: component.id,
          captions: clone(component.captions),
          priority: component.priority,
          isAlwaysRun: component.isAlwaysRun,
          option: null,
        };

        if (component.isAlwaysRun) {
          componentFragment.option = {
            id: component.options[0].id,
          };
        }

        return componentFragment;
      }),

      variables: bomSource.variables.map(variable => {
        return {
          id: variable.id,
          captions: clone(variable.captions),
          dataType: variable.dataType,
        };
      }),
    });
  }

  save = task(waitFor(async buildDataSaves => {
    for (const datum of buildDataSaves) {
      // no promise all to preserve creation order
      await this.saveDatum.perform(datum);
    }
  }));

  saveDatum = task(waitFor(async buildDatum => {
    try {
      await buildDatum.save();
    } catch (error) {
      console.error(error);
      this.notifier.sendError(error, { serialNumber: buildDatum.serialNumber });
    }
  }));

  copyTargetAmount = task(waitFor(async (datumToCopy, serialNumber, target = 1, start = 1) => {
    target = parseInt(target);
    start = parseInt(start);
    serialNumber = serialNumber ?? datumToCopy.serialNumber;

    return await all(
      range(start, start + target).map(async (num) => {
        const copySerial = target > 1 ? `${serialNumber}-${num}` : serialNumber;

        return await this.copyDatum.perform(datumToCopy, copySerial);
      }),
    );
  }));

  copyDatum = task({ enqueue: true }, waitFor(async (datumToCopy, serialNumber) => {
    await timeout(0); // to prevent blocking event loop
    return datumToCopy.copy(true, {
      overwrite: {
        ...datumToCopy.copyableOptions.overwrite,
        serialNumber,
      },
    });
  }));
}
