/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { uniq, filter } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';

export default class ProcessDataUnits extends Component {
  get uniqueUnits() {
    return intoArray(
      filter(unit => !isBlank(unit)),
      compact,
      uniq,
    )(this.args.units ?? []);
  }

  @action
  onCreate(newName) {
    this.args.onChange(newName.trim());
  }

  @action
  onChange(newUnit) {
    if (newUnit === this.args.selected) {
      return;
    }

    this.args.onChange(newUnit);
  }

  @action
  showCreate(value) {
    return !this.uniqueUnits.includes(value);
  }
}
