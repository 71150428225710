import Service, { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { task, all } from 'ember-concurrency';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { waitFor } from '@ember/test-waiters';
import { removeObjects } from 'eflex/util/array-helpers';
import { reject, chain, without, filter, propEq, map } from 'ramda';
import { intoArray, dpPluck, dpEq } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';

const COPYABLE_MODELS = new Set(['work-instruction-hardware-trigger-config', 'station-jem-configuration']);

export default class WorkInstructionRepoService extends Service {
  @service store;
  @service systemConfig;
  @service licensing;
  @service logRepo;
  @service taskRepo;
  @service stationRepo;
  @service workInstructionImageRepo;
  @service userRepo;
  @service intl;
  @service fileDownloader;
  @service currentUser;

  folders = this.store.peekAll('workInstructionFolder');
  workInstructions = this.store.peekAll('workInstruction');
  workInstructionIcons = this.store.peekAll('workInstructionIcon');

  get isApprovalActive() {
    return (
      this.systemConfig.config?.enableWieApprovals &&
      this.licensing.license.wieApprovalEnabled
    );
  }

  get nonDeletedWieFolders() {
    return this.folders.filter(item => !item.isDeleted);
  }

  get userFolderWieEditorTags() {
    return this.getUserFolderWieTags('wieEditorTags');
  }

  get userFolderWieApproverTags() {
    return this.getUserFolderWieTags('wieApproverTags');
  }

  get userFolderWieDeployerTags() {
    return this.getUserFolderWieTags('wieDeployerTags');
  }

  get systemFolderWieEditorTags() {
    return this.getSystemFolderWieTags('wieEditorTags');
  }

  get systemFolderWieApproverTags() {
    return this.getSystemFolderWieTags('wieApproverTags');
  }

  get systemFolderWieDeployerTags() {
    return this.getSystemFolderWieTags('wieDeployerTags');
  }

  get systemUserWieEditorTags() {
    return this.getSystemUserWieTags('wieEditorTags');
  }

  get systemUserWieApproverTags() {
    return this.getSystemUserWieTags('wieApproverTags');
  }

  get systemUserWieDeployerTags() {
    return this.getSystemUserWieTags('wieDeployerTags');
  }

  getFolderWieTags(type) {
    return [...new Set(this.nonDeletedWieFolders.flatMap(folder => folder[type]))].toSorted();
  }

  getUserFolderWieTags(type) {
    return [...new Set(this.userRepo.getWieTags(type)?.concat(this.getFolderWieTags(type)))].toSorted();
  }

  getSystemFolderWieTags(type) {
    return [...new Set(this.systemConfig.getWieTags(type)?.concat(this.getFolderWieTags(type)))].toSorted();
  }

  getSystemUserWieTags(type) {
    return [...new Set(this.systemConfig.getWieTags(type)?.concat(this.userRepo.getWieTags(type)))].toSorted();
  }

  #hasDefaultPermission() {
    return this.currentUser.isAdmin || (this.currentUser.user?.isUser && !this.isApprovalActive);
  }

  getWritableFolders() {
    if (this.#hasDefaultPermission()) {
      return this.nonDeletedWieFolders;
    }

    return this.nonDeletedWieFolders.filter((folder) => {
      return folder.wieEditorTags?.some((tag) => this.currentUser.user?.wieEditorTags?.includes(tag));
    });
  }

  userCanEdit(instructionOrFolder) {
    if (this.#hasDefaultPermission()) {
      return true;
    }

    return instructionOrFolder?.wieEditorTags?.some((tag) =>
      this.currentUser.user?.wieEditorTags?.includes(tag),
    );
  }

  userCanEditSelected(instructionsOrFolders) {
    if (this.#hasDefaultPermission()) {
      return true;
    }

    return instructionsOrFolders.every((selected) => {
      return this.userCanEdit(selected);
    });
  }

  userCanCreate(folder) {
    if (this.#hasDefaultPermission()) {
      return true;
    }

    if (!folder) {
      return this.userHasRootFolderEditor();
    } else {
      return this.userCanEdit(folder);
    }
  }

  userHasRootFolderEditor() {
    if (this.#hasDefaultPermission()) {
      return true;
    }

    return this.systemConfig
      .getWieTags('wieEditorTags')
      ?.some((tag) => this.currentUser.user?.wieEditorTags?.includes(tag));
  }

  userIsEditorOnly() {
    const { user } = this.currentUser;

    return !isEmpty(user.wieEditorTags) &&
      isEmpty(user.wieApproverTags) &&
      isEmpty(user.wieDeployerTags) &&
      user.isNotAdmin;
  }

  userIsApproverDeployerOnly() {
    const { user } = this.currentUser;

    return isEmpty(user?.wieEditorTags) &&
      (!isEmpty(user?.wieApproverTags) || !isEmpty(user?.wieDeployerTags)) &&
      user.isNotAdmin;
  }

  userCanApprove(workInstruction) {
    if (this.currentUser.isAdmin) {
      return true;
    }

    return workInstruction?.wieApproverTags?.some((tag) =>
      this.currentUser.user.wieApproverTags?.includes(tag),
    );
  }

  userCanApproveSelected(workInstructions) {
    if (this.currentUser.isAdmin) {
      return true;
    }

    return workInstructions.every((selected) => {
      return this.userCanApprove(selected);
    });
  }

  userCanDeploy(workInstruction) {
    if (this.currentUser.isAdmin) {
      return true;
    }

    return workInstruction?.wieDeployerTags?.some((tag) =>
      this.currentUser.user.wieDeployerTags?.includes(tag),
    );
  }

  userCanDeploySelected(workInstructions) {
    if (this.currentUser.isAdmin) {
      return true;
    }

    return workInstructions.every((selected) => {
      return this.userCanDeploy(selected);
    });
  }

  queryInstructions = task({ enqueue: true }, waitFor(async query => {
    query.folder ??= 'noFolder';
    query.searchTerm ??= null;
    query.perPage = this.perPage;

    const { searchTerm, pageNumber, folder } = query;

    const instructions = await this.store.query('workInstruction', query);

    return {
      searchTerm,
      folder,
      pageNumber,
      perPage: this.perPage,
      instructions: [...instructions],
      instructionCount: instructions?.meta?.count ?? 0,
    };
  }));

  queryFolders = task({ enqueue: true }, waitFor(async query => {
    query.searchTerm ??= null;
    query.perPage = this.perPage;

    const { searchTerm, pageNumber } = query;

    const folders = await this.store.query('workInstructionFolder', query);

    return {
      searchTerm,
      pageNumber,
      perPage: this.perPage,
      folders: [...folders],
      folderCount: folders?.meta?.count ?? 0,
    };
  }));

  saveInstructions = task(waitFor(async workInstructions => {
    await all(workInstructions.map(workInstruction => workInstruction.save()));
  }));

  deleteFolders = task(waitFor(async folders => {
    folders.forEach((folder) => {
      folder.workInstructions.forEach(workInstruction => { workInstruction.unloadRecord(); });
    });

    await all(folders.map(folder => folder.destroyRecord()));
  }));

  deleteWorkInstructions = task(waitFor(async workInstructions => {
    await all(workInstructions.map(workInstruction => workInstruction.destroyRecord()));
  }));

  copyToAllContexts(treeItem, sourceConfig, event) {
    if (sourceConfig == null || !COPYABLE_MODELS.has(sourceConfig.constructor.modelName)) {
      return;
    }

    const isJemConfig = sourceConfig.constructor.modelName === 'station-jem-configuration';

    const contextsToCopy = this._getContextsToCopy(treeItem);

    const destinationConfigs = isJemConfig
      ? this._getJemConfigs(treeItem, sourceConfig, contextsToCopy)
      : this._getTriggerConfigs(treeItem, sourceConfig, contextsToCopy, event);

    const { externalImageUrl } = sourceConfig;
    if (externalImageUrl != null) {
      destinationConfigs.forEach(destinationConfig => {
        destinationConfig.setExternalImageUrl(externalImageUrl, sourceConfig.page);
      });
    } else {
      const { workInstruction } = sourceConfig;
      destinationConfigs.forEach(destinationConfig => {
        destinationConfig.setWorkInstruction(workInstruction);
      });
    }

    const { animations } = sourceConfig;
    if (animations != null) {
      for (const config of destinationConfigs) {
        config.copyAnimations(animations);
      }
    }
  }

  saveWithApprovals = task(waitFor(async workInstructions => {
    workInstructions.forEach(function (workInstruction) {
      const pending = workInstruction.approvalsRejects
        .filter(approval => approval.state === WieSettings.approvalStates.PENDING);
      removeObjects(workInstruction.approvalsRejects, pending);
    });

    await workInstructions.map(workInstruction => workInstruction.save());
  }));

  saveWithImageData = task(waitFor(async workInstruction => {
    if (workInstruction.displayImageData != null) {
      await this.workInstructionImageRepo.deleteWorkInstructionPreviews.perform(workInstruction);

      const { image, thumb } = await this.workInstructionImageRepo.saveWieImage.perform(
        workInstruction.displayImageData,
        { type: WieSettings.imageTypes.DISPLAY, thumbnail: true },
      );

      Object.assign(workInstruction, {
        displayImageData: null,
        displayImage: image.id,
        displayImageThumb: thumb.id,
      });
    }

    if (this.isApprovalActive) {
      await workInstruction.save();
    } else {
      await this.deploy.perform(workInstruction);
    }
  }));

  toggleRequestApprovalAndSave = task(waitFor(async workInstruction => {
    workInstruction.approvalRequested = !workInstruction.approvalRequested;
    workInstruction.approvalsRejects = [];
    await workInstruction.save();
  }));

  setPendingApprovalAndSave = task(waitFor(async (workInstructions, username) => {
    await all(
      intoArray(
        reject(workInstruction => workInstruction.approvalsRejects.some(approval => approval.username === username)),
        map(workInstruction =>
          this._approveReject.perform(workInstruction, username, WieSettings.approvalStates.PENDING),
        ),
      )(workInstructions),
    );
  }));

  deployMany = task(waitFor(async workInstructions => {
    await all(
      workInstructions.map(workInstruction => this.deploy.perform(workInstruction)),
    );
  }));

  deploy = task(waitFor(async workInstruction => {
    const [duplicate] = await all([
      this.workInstructionImageRepo.duplicateWieImage.perform(workInstruction.displayImage),
      this.workInstructionImageRepo.deleteWorkInstructionDeployedPreviews.perform(workInstruction),
      this._deleteRemovedImages.perform(workInstruction),
      this._deleteRemovedBackgroundImage.perform(workInstruction),
    ]);

    const isDynamic = workInstruction.getDynamicObjectNames(false).length;

    Object.assign(workInstruction, {
      deployedName: workInstruction.name,
      deployedCanvas: { ...workInstruction.canvas },
      deployedImage: duplicate.image.id,
      deployedImageThumb: duplicate.thumb.id,
      approvalRequested: false,
      approvalsRejects: [],
      isDynamic,
    });

    if (this.systemConfig.jem?.changeNotifications) {
      await all([this.logRepo.deployBySource.perform(workInstruction.id), workInstruction.save()]);
    } else {
      await workInstruction.save();
    }
  }));

  approveAndSave = task(waitFor(async workInstructions => {
    await all(
      workInstructions.map(workInstruction => {
        workInstruction.currentApproveReject.approve();
        return workInstruction.save();
      }),
    );
  }));

  rejectAndSave = task(waitFor(async workInstructions => {
    await all(
      workInstructions.map(workInstruction => {
        workInstruction.currentApproveReject.reject();
        return workInstruction.save();
      }),
    );
  }));

  _approveReject = task(waitFor(async (workInstruction, username, state, notes = null) => {
    const current = workInstruction.approvalsRejects.filter(approval => approval.username === username);
    removeObjects(workInstruction.approvalsRejects, current);

    const approveReject = this.store.createRecord('workInstructionApproveReject', {
      username,
      state,
      notes,
    });

    workInstruction.approvalsRejects.push(approveReject);
    await workInstruction.save();
  }));

  _deleteRemovedImages = task(waitFor(async workInstruction => {
    if (
      workInstruction.canvas == null ||
      workInstruction.canvas.objects == null ||
      workInstruction.deployedCanvas == null ||
      workInstruction.deployedCanvas.objects == null
    ) {
      return;
    }

    await all(
      intoArray(
        filter(propEq('image', 'type')),
        dpPluck('eflex.id'),
        compact,
        filter((imageId) => !this.getAllCanvasImages(workInstruction.canvas).some(item => item.eflex?.id === imageId)),
        map(imageId => this.workInstructionImageRepo.delete.perform(imageId)),
      )(this.getAllCanvasImages(workInstruction.deployedCanvas)),
    );
  }));

  _deleteRemovedBackgroundImage = task(waitFor(async workInstruction => {
    const { canvas } = workInstruction;
    const { deployedCanvas } = workInstruction;

    if (canvas == null || deployedCanvas?.backgroundImage == null) {
      return;
    }

    if (canvas.backgroundImage?.eflex.isExternal || deployedCanvas.backgroundImage?.eflex.isExternal) {
      return;
    }

    if (canvas.backgroundImage?.eflex.id !== deployedCanvas.backgroundImage.eflex.id) {
      await this.workInstructionImageRepo.delete.perform(deployedCanvas.backgroundImage.eflex.id);
    }
  }));

  deleteSelectedWorkInstructions = task(waitFor(async (currentWorkInstruction, workInstructions) => {

    const folders = workInstructions.filter(item => item.isFolder);
    workInstructions = workInstructions.filter(item => !item.isFolder);

    const currentWorkInstructionIsDeleted =
      currentWorkInstruction != null &&
      (workInstructions.includes(currentWorkInstruction) ||
      (currentWorkInstruction.folder != null && folders.includes(currentWorkInstruction.folder)));

    await this.deleteFolders.perform(folders);
    await this.deleteWorkInstructions.perform(workInstructions);

    return currentWorkInstructionIsDeleted;
  }));

  downloadInstructions = task(waitFor(async (downloadQueue, fileType) => {
    const ids = downloadQueue.filter(item => !item.isFolder).map(item => item.id);
    const folderIds = downloadQueue.filter(item => item.isFolder).map(item => item.id);

    if (isEmpty(ids) && isEmpty(folderIds)) {
      return;
    }

    if (ids.length === 1 && folderIds.length === 0) {
      await this._downloadSingle.perform(downloadQueue[0], fileType);
    } else {
      await this._downloadMultiple.perform(fileType, ids, folderIds);
    }
  }));

  _downloadSingle = task({ drop: true }, waitFor(async (instruction, fileType) => {
    await this.fileDownloader.getFile.perform(
      `workInstructionImages/${instruction.displayImage}`,
      fileType,
      `work-instructions_${dasherize(instruction.name)}.${fileType}`,
    );
  }));

  _downloadMultiple = task({ drop: true }, waitFor(async (fileType, ids, folderIds) => {
    let queryString = '';

    ids.forEach(id => {
      queryString += `ids[]=${id}&`;
    });

    folderIds.forEach(id => {
      queryString += `folderIds[]=${id}&`;
    });

    await this.fileDownloader.getFile.perform(
      'workInstructions',
      `${fileType}, application/zip`,
      `work-instructions_${Date.now()}.zip`,
      queryString,
    );
  }));

  createWorkInstruction({ width, height, name, canvas, displayImageData, folder }) {
    const props = {
      authorUsername: this.currentUser.user?.userName,
      width,
      height,
    };

    if (name != null) {
      props.name = name;
    }
    if (canvas != null) {
      props.canvas = canvas;
    }
    if (displayImageData != null) {
      props.displayImageData = displayImageData;
    }
    if (folder != null) {
      props.folder = folder;
    }

    return this.store.createRecord('workInstruction', props);
  }

  getAllCanvasImages(source) {
    const images = source.objects.filter(item => item.type === 'image');
    source.objects
      .filter(item => item.type === 'group')
      .forEach((group) => {
        images.push(...this.getAllCanvasImages(group));
      });

    return images;
  }

  createFolder() {
    const folder = this.store.createRecord('workInstructionFolder');
    folder.name = this.intl.t('workInstructionFolder.newFolder');
    return folder;
  }

  setExternalImageUrl(jemConfig, url, page = null) {
    Object.assign(jemConfig, {
      externalImageUrl: url,
      workInstruction: null,
      page,
    });

    if (this.animations != null) {
      jemConfig.animations = [];
    }
  }

  setWorkInstruction(jemConfig, workInstruction) {
    Object.assign(jemConfig, {
      externalImageUrl: null,
      workInstruction,
      page: null,
    });

    if (jemConfig.animations != null) {
      jemConfig.animations = [];
    }
  }

  deleteJemImage(jemConfig) {
    Object.assign(jemConfig, {
      externalImageUrl: null,
      workInstruction: null,
      page: null,
      animations: [],
    });
  }

  _getContextsToCopy(treeItem) {
    if (treeItem.usesComponents) {
      return treeItem.component.options; //treeItem is always a task for component-based
    } else {
      return treeItem.area.models;
    }
  }

  _getJemConfigs(treeItem, sourceConfig, contextsToCopy) {
    if (!treeItem.type === 'station') {
      return [];
    }

    return intoArray(
      map(context => this.stationRepo.getJemConfig(treeItem, context)),
      without([sourceConfig]),
      compact,
    )(contextsToCopy);
  }

  _getTriggerConfigs(treeItem, sourceConfig, contextsToCopy, event) {
    return intoArray(
      chain(context => this.taskRepo.getWorkInstructionTriggerConfigs(treeItem, context)),
      without([sourceConfig]),
      filter(dpEq(event, 'parentTrigger.event')),
    )(contextsToCopy);
  }
}
