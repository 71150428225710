import Model, { attr, hasMany } from '@ember-data/model';
import { flatten, pipe, pluck } from 'ramda';
import { compact, sortByProps } from 'ramda-adjunct';
import { dp } from '@eflexsystems/ramda-helpers';

class KineticJob extends Model {
  @attr('string') jobNumber;
  @attr('date') updatedAt;
  @attr('date') createdAt;

  @hasMany('kineticJobAssembly', { inverse: 'job', async: false, cascadeDelete: true }) assemblies;

  get allOperations() {
    return pipe(
      pluck('assemblyOperations'),
      flatten,
      compact,
      sortByProps([dp('assembly.assemblySequence'), 'sequence']),
    )(this.assemblies ?? []);
  }
}

export default KineticJob;
