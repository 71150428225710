import Service, { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { NotificationLogTypes } from 'eflex/constants/notification-log';
import { PAGE_SIZE } from 'eflex/constants/pagination';

export default class LogRepoService extends Service {
  @service eflexAjax;
  @service store;
  @service currentUser;

  logs = this.store.peekAll('log');

  acknowledgeLogs = task(waitFor(async logs => {
    logs.forEach(log => { log.acknowledge(this.currentUser.user); });
    await all(logs.map(log => log.save()));
  }));

  acknowledgeAll = task(waitFor(async logType => {
    const params = { acknowledgedBy: this.currentUser.user.toFragment() };

    if (logType != null) {
      params.logType = logType;
    }

    await this.eflexAjax.post.linked().perform('logs/acknowledgeAll', params);
  }));

  deployBySource = task(waitFor(async source => {
    await this.eflexAjax.post.linked().perform('logs/deploy', { source });
  }));

  fetchLogsForJem = task(waitFor(async (params = {}) => {
    return await this.store.query('log', {
      locationId: params?.stationId,
      logTypes: [NotificationLogTypes.JEM, NotificationLogTypes.WIE],
      afterDate: this.currentUser.user.createdAt,
      notAcknowledgedByUser: this.currentUser.user.id,
      sortField: 'timestamp',
      skip: params.skip ?? 0,
      take: params.take ?? PAGE_SIZE,
    });
  }));
}
