import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { inject as service } from '@ember/service';
import pProps from 'p-props';

export default class RepairRoute extends AuthenticatedRoute {
  @service store;
  @service buildStatusRepo;

  model(params) {
    return pProps({
      jemContainments: this.store.findAll('jemContainment'),
      buildStatusesAndContainment:
        this.buildStatusRepo.getBuildStatusesAndContainmentForRepair.perform(params.serialNumber),
    });
  }

  setupController(controller, { jemContainments, buildStatusesAndContainment }) {
    controller.jemContainments = jemContainments;
    Object.assign(controller, buildStatusesAndContainment);
  }
}
