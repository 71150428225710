/* import __COLOCATED_TEMPLATE__ from './info-bar.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { usesBomSourceLookup, STATION_LOAD_OPTIONS } from 'eflex/constants/station-options';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';

class JemInfoBar extends Component {
  @service currentUser;

  @tracked showEndActivityModal = false;

  get jobOpSelectorDisabled() {
    return this.args.stationIsRunning || this.args.notAuthorized;
  }

  get showEndActivity() {
    return this.args.station.usesOperations &&
      !this.args.stationIsRunning &&
      !this.args.notAuthorized &&
      isPresent(this.args.kineticAssemblyOperation);
  }

  get serialInputDisabled() {
    return (this.args.station.usesOperations && isEmpty(this.args.kineticAssemblyOperation)) ||
      this.args.notAuthorized;
  }

  get usesBomSourceLookup() {
    return usesBomSourceLookup(this.args.station);
  }

  get showSerialInput() {
    if (this.showModelDropdown && this.args.model == null && !this.args.station.usesOperations) {
      return false;
    }

    return !this.args.stationIsRunning && !this.args.waitingForData;
  }

  get showModelDropdown() {
    if (this.args.stationIsRunning) {
      return false;
    }

    const station = this.args.station;

    return (
      station?.loadOption === STATION_LOAD_OPTIONS.modelGenerateSerialNumber ||
      station?.loadOption === STATION_LOAD_OPTIONS.selectModel
    );
  }

  onEndActivity = task(waitFor(async (activityNotes, activityComplete) => {
    await this.args.onEndActivity(activityNotes, activityComplete);
    this.showEndActivityModal = false;
  }));
}

export default JemInfoBar;
