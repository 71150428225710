/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class InputsStrokeSelect extends Component {
  strokeOptions = StrokeOptions.options;

  get minimum() {
    return this.args.minimum ?? 1;
  }

  get maximum() {
    return this.args.maximum ?? 50;
  }

  get strokeLabel() {
    const strokeWidth = parseInt(this.args.strokeWidth);
    if (strokeWidth < 10) {
      return 'ones';
    } else if (strokeWidth < 100) {
      return 'tens';
    } else {
      return null;
    }
  }

  @action
  setStrokeStyle(style) {
    this.args.setStroke(
      generateStrokeDashArray(style, this.args.strokeWidth),
      style,
      this.args.strokeWidth,
    );
  }

  @action
  setStrokeWidth(width) {
    if (width < this.minimum || isNaN(width)) {
      width = this.minimum;
    } else if (width > this.maximum) {
      width = this.maximum;
    }

    this.args.setStroke(
      generateStrokeDashArray(this.args.strokeStyle, width),
      this.args.strokeStyle,
      width,
    );
  }
}
