/* import __COLOCATED_TEMPLATE__ from './task.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { isAncestorOf } from 'eflex/util/tree-helpers';

const TASK_CONFIGS_CHUNK_SIZE = 15;

export default class TaskPanel extends Component {
  @service taskRepo;
  @service taskConfigRepo;
  @service workInstructionRepo;
  @service workInstructionCache;
  @service defaultTaskConfigContext;

  @tracked taskConfigsCurrentChunks = 15;
  @tracked selectedContext = this.defaultTaskConfigContext.getContext(this.args.taskParent);
  @tracked scrollToTop = false;

  previousTask = this.args.task;

  get isTesting() {
    return window.isTesting ?? false;
  }

  @cached
  get taskConfigs() {
    const treeTask = this.args.task;

    if (treeTask == null) {
      return [];
    }

    const context = this.selectedContext;
    const usesComponents = this.args.taskParent.usesComponents;

    if (!treeTask) {
      return [];
    } else if (context?.allSelected && usesComponents) {
      return treeTask.children.filter(item => item.configOption?.component === treeTask.component);
    } else if (treeTask.usesOperations || context.allSelected) {
      return treeTask.children;
    } else if (usesComponents) {
      return treeTask.children.filter(item => item.configOption === context);
    } else {
      return treeTask.children.filter(item => item.configModel === context);
    }
  }

  get taskConfigsChunked() {
    return this.taskConfigs.slice(0, this.taskConfigsCurrentChunks);
  }

  @action
  copyWieToAll(triggerConfig, event) {
    this.workInstructionRepo.copyToAllContexts(this.args.task, triggerConfig, event);
  }

  constructor() {
    super(...arguments);
    this.workInstructionCache.setupAsync.perform();
  }

  @action
  onTreeItemDeleted(treeItem) {
    const treeTask = this.args.task;
    if (treeTask != null && (treeItem === treeTask || isAncestorOf(treeItem, treeTask))) {
      this.args.onCancel();
    }
  }

  @action
  onDidUpdate(elem, [treeTask]) {
    if (treeTask != null && treeTask !== this.previousTask) {
      this.scrollToTop = true;
    }

    this.previousTask = treeTask;
  }

  // jscpd:ignore-start
  @action
  selectedTaskType(value) {
    if (value === this.args.task?.taskType) {
      return;
    }

    this.taskRepo.changeType(this.args.task, value);
  }

  @action
  contextWasSelected(context) {
    this.selectedContext = context;
    if (context.allSelected) {
      this.scrollToTop = true;
    }
  }
  // jscpd:ignore-end

  @action
  tabBecameActive(tabName) {
    this.scrollToTop = true;
    this.args.tabBecameActive(tabName);
  }

  @action
  copyEventToAll(taskConfig) {
    this.taskConfigRepo.copyEventToAllContexts(taskConfig);
  }

  @action
  loadMoreInstructions() {
    if (this.taskConfigsChunked.length < this.taskConfigs.length) {
      this.taskConfigsCurrentChunks += TASK_CONFIGS_CHUNK_SIZE;
    }
  }

  @action
  copyDecisionToAll(taskConfig) {
    this.taskConfigRepo.copyDecisionToAllContexts(taskConfig);
  }
}
