/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import config from 'eflex/config/environment';
import Component from '@glimmer/component';
import moment from 'moment-timezone';

export default class Footer extends Component {
  year = moment().get('year');

  get realVersion() {
    return this.args.version ?? config.APP.version;
  }

  get version() {
    return '8.0.24.1-2214s';
  }
}
