/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable indent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import FormatDurationAxisBi from 'eflex/util/bi-duration-axis-formatter';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';

export default class CycleTimeSerialNumber extends Component {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;

  get params() {
    return {
      stationIds: this.args.stationIds,
      modelIds: this.args.modelIds,
      userIds: this.args.userIds,
      tags: this.args.tags,
      startDate: this.args.startDate,
      endDate: this.args.endDate,
      shiftNames: this.args.shiftNames,
    };
  }

  getData = task({ drop: true }, waitFor(async params => {
    const serialCycleTimeQuery = await this.queryRunner.queryWithParams.perform(params, [
      {
        $sort: {
          timestamp: 1,
        },
      },
      {
        $project: {
          serialNumber: 1,
          cycleTime: 1,
          timestamp: 1,
        },
      },
    ]);
    const serialCycleTimeData = await this.queryRunner.runQuery.perform('BuildStatuses', serialCycleTimeQuery);

    return {
      chartData: this.getEchartOptions(serialCycleTimeData),
      exportData: () => this.formatCycleTimeExportData(clone(serialCycleTimeData)),
    };
    // jscpd:ignore-end
  }));

  serialCycleTimeData = trackedTask(this, this.getData, () => [this.params]);

  getEchartOptions(cycleTimeData) {
    return {
      title: {
        text: this.intl.t('bi.chartLabel.cycleTimeBySerial'),
      },

      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const serialNumber = params[1].data.name;
          const tooltipData = params[0];
          return `<div style="text-align:center;">${serialNumber}</div><br>
            <div>${tooltipData.marker} ${tooltipData.name}:  <span style="font-weight:bold;">
            ${moment
              .duration(tooltipData.value, 'milliseconds')
              .format('d[d] h[h] m[m] s[s]', { precision: 1, trim: 'both mid' })}
            </span></div>`;
        },
      },

      legend: {
        show: false,
        selected: {
          'series 1': true,
          'series 2': false,
        },
      },

      yAxis: [
        {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: (value) => FormatDurationAxisBi(value),
          },
        },
        {
          type: 'value',
          show: false,
        },
      ],

      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: cycleTimeData.map((data) => moment(data.timestamp).format('MM/DD/YYYY hh:mm A')),
        axisLabel: {
          show: true,
          rotate: 75,
          interval: 'auto',
        },
      },

      series: [
        {
          yAxisIndex: 0,
          name: this.intl.t('navigation.bi.cycleTime'),
          data: cycleTimeData.map((m) => m.cycleTime),
          type: 'line',
          color: echartsTheme.colorPalette.biBlue,
          markLine: {
            data: [{ type: 'average', name: 'Avg' }],
            label: {
              show: true,
              position: 'insideEndTop',
              formatter: (params) =>
                `${this.intl.t('bi.chartLegend.avg')}: ${moment
                  .duration(params.value, 'milliseconds')
                  .format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid',
                  })}`,
            },
          },
        },
        {
          yAxisIndex: 1,
          data: cycleTimeData.map((m) => {
            return {
              name: m.serialNumber,
              value: 0, // we only care about the name
            };
          }),
          type: 'line',
          color: '#00000000', // transparent so this plot does not show up
        },
      ],
    };
  }

  formatCycleTimeExportData(cycleTimeData) {
    cycleTimeData.forEach((serialNumber) => {
      serialNumber.cycleTime = moment.duration(serialNumber.cycleTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
        precision: 1,
        trim: 'both mid',
      });
      serialNumber.timestamp = moment(serialNumber.timestamp).format('MM/DD/YYYY hh:mm A');
      delete serialNumber._id;
    });

    return cycleTimeData;
  }
}
