/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TreeSelectLeaf extends Component {
  get indentWidth() {
    return `${this.args.indent * 27}px`;
  }

  get isClickable() {
    return this.args.canExpand || (!this.args.canExpand && !this.args.checkable);
  }

  @action
  clicked() {
    if (this.isClickable) {
      this.args.onClick?.();
    }
  }
}
