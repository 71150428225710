import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import JwtSession from 'eflex/services/session';
import { getOwner } from '@ember/application';
import { waitFor } from '@ember/test-waiters';

export default class LoginController extends Controller {
  @service session;
  @service intl;
  @service systemConfig;

  @tracked password;
  @tracked errorMessage;
  @tracked username;

  _sendLoginRequest = task(waitFor(async credentials => {
    credentials.timezone = moment.tz.guess();
    credentials.clientTime = moment().toDate();

    try {
      await this.session.authenticate('authenticator:jwt', credentials);
    } catch (e) {
      if (!window.isTesting) {
        console.error(e);
      }
      let errorMessage = e.message;

      if (this.intl.exists(errorMessage)) {
        errorMessage = this.intl.t(errorMessage);
      }

      Object.assign(this, {
        password: null,
        errorMessage,
      });
    }
  }));

  badgeScan = task(waitFor(async badgeId => {
    await this._sendLoginRequest.perform({ badgeId });
  }));

  login = task(waitFor(async () => {
    if (this.session.isOidc) {
      const owner = getOwner(this);
      owner.unregister('service:session');
      owner.register('service:session', JwtSession);
      this.session = owner.lookup('service:session');
      await this.session.setup();
    }

    await this._sendLoginRequest.perform({
      username: this.username,
      password: this.password,
    });
  }));
}
