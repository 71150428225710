/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task, all } from 'ember-concurrency';
import { ALLOWED_EXTENSIONS, MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_MB } from 'eflex/constants/file-types';
import { range } from 'ramda';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class WorkInstructionEditorFiles extends Component {
  @service store;
  @service fileUploader;
  @service notifier;
  @service intl;
  @service hostedFileRepo;
  @service workInstructionRepo;
  @service currentUser;

  @tracked showConfirmModal = false;
  @tracked confirmModalText;
  @tracked confirmAction;
  @tracked replacementFile;

  get uploadDisabled() {
    return !this.currentUser.user?.isUser;
  }

  get selectedFiles() {
    return this.hostedFileRepo.hostedFiles.filter(item => item.isSelected);
  }

  get isLoading() {
    return this.remove.isRunning || this.import.isRunning || this.replace.isRunning;
  }

  get notOneSelected() {
    return this.selectedFiles.length !== 1;
  }

  get supportedFileTypes() {
    return ALLOWED_EXTENSIONS;
  }

  preRemove = task(waitFor(async () => {
    let text;
    const instructions = await this.store.query('workInstruction', {
      backgroundIds: this.selectedFiles.map(item => item.id),
    });

    if (instructions.length > 0) {
      text = this.intl.t('workInstructions.instructionsAffected', { count: instructions.length });
    } else {
      text = this.intl.t('workInstructions.deleteFiles', { count: this.selectedFiles.length });
    }

    Object.assign(this, {
      confirmModalText: text,
      showConfirmModal: true,
      confirmAction: 'remove',
    });
  }));

  remove = task({ drop: true }, waitFor(async () => {
    await all(this.selectedFiles.map(selectedFile => selectedFile.destroyRecord()));
  }));

  import = task(waitFor(async fileList => {
    await all(
      range(0, fileList.length).map(i =>
        this._uploadFile.perform(fileList.item(i)),
      ),
    );
  }));

  preReplace = task(waitFor(async fileList => {
    const instructions = await this.store.query('workInstruction', {
      backgroundIds: this.selectedFiles[0].id,
    });

    this.replacementFile = fileList.item(0);

    if (instructions.length > 0) {
      Object.assign(this, {
        confirmModalText: this.intl.t('workInstructions.instructionsAffected', { count: instructions.length }),
        showConfirmModal: true,
        confirmAction: 'replace',
      });
    } else {
      await this.replace.perform(this.replacementFile);
    }
  }));

  replace = task({ enqueue: true }, waitFor(async replacementFile => {
    await this._uploadFile.perform(replacementFile, this.selectedFiles[0]);
    this.replacementFile = null;
  }));

  removeReplace = task(waitFor(async () => {
    switch (this.confirmAction) {
      case 'replace': {
        await this.replace.perform(this.replacementFile);
        break;
      }
      case 'remove': {
        await this.remove.perform();
        break;
      }
    }

    await this.args.afterRemoveReplace?.();

    this.showConfirmModal = false;
  }));

  _uploadFile = task(waitFor(async (file, replacedFile = null) => {
    if (file.size > MAX_UPLOAD_SIZE) {
      this.notifier.sendError('fileTooLarge', {
        filename: file.name,
        size: MAX_UPLOAD_SIZE_MB,
      });
      return;
    }

    const { hostedFile } =
      replacedFile != null
        ? (await this.fileUploader.put.perform(`hostedFiles/${replacedFile.id}`, file))
        : (await this.fileUploader.post.perform('hostedFiles', file));

    this.store.push(this.store.normalize('hostedFile', hostedFile));
  }));

  @action
  toggleFile(file) {
    file.isSelected = !file.isSelected;
  }

  @action
  hideConfirmModal() {
    Object.assign(this, {
      showConfirmModal: false,
      confirmAction: null,
    });
  }
}
