/* import __COLOCATED_TEMPLATE__ from './kinetic-job-op-selector.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class JemKineticJobOpSelector extends Component {
  @service kineticRepo;

  get filteredAssemblies() {
    return this.kineticRepo.kineticJobAssemblies?.filter(assembly => {
      return assembly.job === this.args.selectedJob;
    }) ?? [];
  }

  get filteredOperations() {
    return this.kineticRepo.kineticAssemblyOperations?.filter(assemblyOp => {
      return assemblyOp.assembly === this.args.selectedAssembly;
    }) ?? [];
  }

  @action
  onOperationScanned(operationName) {
    //todo: this would be some sort of JOB-assembly-op scan? need to parse and load from that
    this.args.onOperationSelected(this.kineticRepo.kineticAssemblyOperations.find((operation) => {
      return operation.name === operationName;
    }));
  }
}
