import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';

export default class DebugAuditsController extends Controller {
  @service store;

  queryParams = ['searchTerm', 'beginDate', 'endDate', 'dtoId', 'username', 'ipAddress', 'verbAction', 'type', 'page'];

  @tracked showJsonModal = false;
  @tracked showDiffModal = false;
  @tracked showMetaModal = false;
  @tracked displayedDto;
  @tracked displayedMeta;
  @tracked diffLeft;
  @tracked diffRight;
  @tracked model = new TrackedArray();
  @tracked searchTerm;
  @tracked beginDate;
  @tracked endDate;
  @tracked dtoId;
  @tracked username;
  @tracked ipAddress;
  @tracked verbAction;
  @tracked type;
  @tracked page = 1;

  openDiffModal = task(waitFor(async audit => {
    const previous = await this.store.query('audit', {
      dtoId: audit.dto.id,
      take: 1,
      endDate: moment(audit.timestamp).subtract(1, 'milliseconds').toDate(),
    });

    if (isEmpty(previous)) {
      return;
    }

    Object.assign(this, {
      showDiffModal: true,
      diffLeft: previous[0].dto,
      diffRight: audit.dto,
    });
  }));

  @action
  openJsonModal(obj) {
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      showJsonModal: true,
      displayedDto: obj,
    });
  }

  @action
  openMetaModal(obj) {
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      showMetaModal: true,
      displayedMeta: obj,
    });
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
