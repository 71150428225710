import Hardware from 'eflex/models/hardware';
import { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { validIpAddress } from 'eflex/constants/regex';
import { portValidator, checkIpUnique } from 'eflex/util/validators';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

@buildValidations({
  name: [validator('presence', true)],

  port: [
    portValidator,
    validator('presence', true),
    validator('inline', {
      validate(port, options, datalogic) {
        return checkIpUnique(datalogic, getOwner(datalogic).lookup('service:hardwareRepo').datalogics);
      },
    }),
  ],

  ipAddress: [
    validator('presence', true),
    validator('format', {
      regex: validIpAddress,
      allowBlank: false,
    }),
    validator('inline', {
      validate(ipAddress, options, datalogic) {
        return checkIpUnique(datalogic, getOwner(datalogic).lookup('service:hardwareRepo').datalogics);
      },
    }),
  ],
})
class Datalogic extends Hardware {
  @service intl;

  @attr('string') ipAddress;
  @attr('number', { defaultValue: 51_236 }) port;

  get isConfigured() {
    return !isBlank(this.ipAddress) && !isBlank(this.port);
  }

  get hardwareGroup() {
    return this.intl.t('datalogic');
  }
}

export default Datalogic;
