/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { THREED_MIMES, IMAGE_MIMES, VIDEO_MIMES } from 'eflex/constants/file-types';
import getUrlType from 'eflex/util/get-url-type';
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';

export default class UrlViewer extends Component {
  type = trackedFunction(this, async () => {
    const url = this.args.url;

    if (url == null) {
      return;
    }

    return await getUrlType(url);
  });

  isPdf = (type) => type.includes('pdf');

  isSvg = (type) => type.includes('svg');

  isImage = (type) => IMAGE_MIMES.some(extension => type.includes(extension));

  is3d = (type) => THREED_MIMES.some(extension => type.includes(extension));

  isVideo = (type) => VIDEO_MIMES.some(videoType => type.includes(videoType));
}
