/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { isEmpty, isPresent } from '@ember/utils';
import { pipe, chain, map, difference, head, filter, prop } from 'ramda';
import { dequal } from 'dequal';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';

class TreeSelectItem {
  @tracked id;
  @tracked name;
  @tracked treeIconClass;
  @tracked children = [];
  @tracked path;
  @tracked modelName;
  @tracked position;
  @tracked checked = false;
  @tracked visible = true;
  @tracked expanded = false;

  constructor(props) {
    Object.assign(this, props);
  }
}

export default class TreeSelect extends Component {
  @tracked localTree = [];
  @tracked treePaths = new TrackedArray();
  @tracked selected;
  @tracked searchTerm = '';
  @tracked allChecked = false;
  @tracked verticalPosition = 'auto';
  @tracked contentMaxHeight;

  previousCheckedIds = this.args.checkedIds;

  constructor() {
    super(...arguments);
    let treeData = this.args.sourceTree;

    // Specifying a minimum depth will make sure only those paths are shown
    // rather than having an incomplete path selection. Example: if we want to
    // select a station but the group has no stations, we dont show that group
    if (this.args.minDepth != null && this.args.minDepth > 0) {
      treeData = intoArray(
        map(branch => this._empty(branch, 0)),
        compact,
      )(treeData);
    }

    treeData = this._copy(treeData);

    Object.assign(this, {
      localTree: treeData,
      selected: this._getSelection(treeData),
    });

    this._updateCheckByChildren(this.localTree);
    this._updateAllChecked();
  }

  @action
  onDidUpdate(element, [checkedIds]) {
    const checkedIdsChanged = !dequal(this.previousCheckedIds, checkedIds);

    if (!checkedIdsChanged) {
      return;
    }

    const toUncheck = difference(this.previousCheckedIds, checkedIds);
    const toCheck = difference(checkedIds, this.previousCheckedIds);
    this.previousCheckedIds = checkedIds;

    toUncheck.forEach(id => {
      this.setItemChecked(id, false);
    });

    toCheck.forEach(id => {
      this.setItemChecked(id);
    });

    this.selected = this._getSelection(this.localTree);
    this._updateCheckByChildren(this.localTree);
    this._updateAllChecked();
  }

  @action
  minWidth(element) {
    if (this.args.fullWidth) {
      this.contentWidth = `${element.clientWidth}px`;
    }
  }

  @action
  search(searchTerm) {
    this.searchTerm = searchTerm;
    this._updateVisible(this.localTree, searchTerm);
    this._updateAllChecked();
    this._updateSelected();
  }

  @action
  checkAllChanged(checked) {
    this.allChecked = checked;
    this._updateProperty('checked', checked, this.localTree);
    this._updateSelected();
  }

  @action
  dataCheckedChanged() {
    this._updateAllChecked();
    this._updateSelected();
  }

  @action
  remove(item) {
    this._uncheckTree(this.localTree, item.position.slice());
    this._updateAllChecked();
    this._updateSelected();
  }

  @action
  clear() {
    this.allChecked = false;
    this._updateProperty('checked', false, this.localTree);
    this._updateSelected();
    this.args.onClick?.(null);
  }

  @action
  clicked(branchData) {
    if (!this.clickable) {
      this._updateProperty('checked', false, this.localTree);

      if (branchData?.length) {
        this._updateProperty('checked', true, branchData);
      } else {
        this._updateProperty('checked', true, [branchData]);
      }

      this._updateSelected();
    }

    this.args.onClick?.(branchData);
  }

  @action
  dropdownClosed() {
    this._updateProperty('expanded', false, this.localTree);
  }

  @action
  dropdownOpen(component, { target }) {
    const trigger = target.closest('.tree-select-trigger')?.children[0] ??
    target.closest('.tree-select-trigger-target');

    if (!trigger) {
      return true;
    }

    const pageHeight = document.documentElement.clientHeight;
    const offset = trigger.getBoundingClientRect();
    const triggerBottom = offset.top + trigger.clientHeight;
    let height = pageHeight - triggerBottom - 35; // Leave a bit of a margin
    let verticalPosition = 'below';

    if (height < pageHeight / 3) {
      height = offset.top - 35;
      verticalPosition = 'above';
    }

    Object.assign(this, {
      contentMaxHeight: `${height}px`,
      verticalPosition,
    });

    // prevent opening when a remove button is pressed
    if (target.classList?.value?.includes('times')) {
      return false;
    }

    return true;
  }

  @action
  onBranchClick(close, branchData) {
    this.clicked(branchData);
    close();
  }

  findItemByPathArray(treeData, pathArray) {
    return pipe(
      intoArray(
        chain(item => {
          if (item.id !== pathArray[0]) {
            return null;
          }

          if (pathArray.length === 1) {
            return item;
          } else if (item.children.length > 0) {
            pathArray.shift();
            return this.findItemByPathArray(item.children, pathArray);
          } else {
            return null;
          }
        }),
        compact,
      ),
      head,
    )(treeData);
  }

  setItemChecked(id, checked = true) {
    const path = this.treePaths.find(p => p.match(new RegExp(`${id}$`)));
    const item = this.findItemByPathArray(this.localTree, path.split('#'));
    item.checked = checked;
  }

  _getSelection(treeData, depth = 0) {
    return intoArray(
      chain(child => {
        if (isPresent(child.children) && (this.args.maxDepth == null || depth < this.args.maxDepth)) {
          return this._getSelection(child.children, depth + 1);
        } else {
          return child;
        }
      }),
      compact,
      filter(prop('checked')),
    )(treeData);
  }

  _copy(sourceTree, indexPath = []) {
    if (sourceTree == null) {
      return [];
    }

    return sourceTree.map((sourceItem, index) => {
      const position = [...indexPath, index];
      const item = {
        id: sourceItem.id,
        name: sourceItem.name,
        treeIconClass: sourceItem.treeIconClass,
        path: sourceItem.path,
        modelName: sourceItem.modelName,
        expanded: sourceItem.expanded,
        position,
        checked: this.args.checkedIds?.includes(sourceItem.id) ?? false,
        children: this._copy(sourceItem.children, position),
      };

      this._checkByChildren(item);
      this.treePaths.push(item.path);

      return new TreeSelectItem(item);
    });
  }

  _updateCheckByChildren(treeData) {
    treeData.forEach(item => {
      if (isEmpty(item.children)) {
        return;
      }

      this._checkByChildren(item);
      this._updateCheckByChildren(item.children);

      //need to check children again if the child check status was affected by its own children
      this._checkByChildren(item);
    });
  }

  _checkByChildren(item) {
    if (isPresent(item.children)) {
      const checked = item.children.every(_item => _item.checked);
      if (item.checked !== checked) {
        item.checked = checked;
      }
    }
  }

  _empty(parent, depth = 0) {
    if (depth === this.args.minDepth) {
      return parent;
    }

    const children = intoArray(
      map(child => this._empty(child, depth + 1)),
      compact,
    )(parent.children);

    if (children.length > 0) {
      parent.children = children;
      return parent;
    }
  }

  _updateProperty(property, value, treeData) {
    treeData?.forEach(child => {
      child[property] = value;
      if (isPresent(child.children)) {
        this._updateProperty(property, value, child.children);
      }
    });
  }

  _updateVisible(treeData, searchTerm = '') {
    if (treeData == null) {
      treeData = [];
    }

    treeData.forEach(child => {
      const match = child.name?.toLowerCase().includes(searchTerm.toLowerCase().trim());
      let childMatch = false;

      if (match) {
        child.visible = true;
        this._updateProperty('visible', true, child.children);
      } else {
        if (isPresent(child.children)) {
          childMatch = this._updateVisible(child.children, searchTerm);
        }

        child.visible = childMatch;
      }
    });

    return treeData.some(item => item.visible);
  }

  _updateAllChecked() {
    this.allChecked = this.localTree.every(item => item.checked);
  }

  _updateSelected() {
    const selection = this._getSelection(this.localTree);
    this.selected = selection;
    this.args.onCheckedChanged?.(selection);
  }

  _uncheckTree(tree, position) {
    if (position.length === 0) {
      return;
    }
    const index = position.shift();
    const item = tree[index];
    item.checked = false;
    this._uncheckTree(item.children, position);
  }
}
