import ValidatorsMessages from '@eflexsystems/ember-tracked-validations/validators/messages';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class IntlValidatorsMessages extends ValidatorsMessages {
  @service intl;

  static create(props) {
    return new IntlValidatorsMessages(props);
  }

  getDescriptionFor(attribute, options = {}) {
    let key = 'errors.description';

    if (!isEmpty(options.descriptionKey)) {
      key = options.descriptionKey;
    } else if (!isEmpty(options.description)) {
      return options.description;
    }

    if (this.intl.exists(key)) {
      return this.intl.t(key, options);
    }

    return super.getDescriptionFor(...arguments);
  }

  getMessageFor(type, options = {}) {
    const key = options.messageKey ?? `errors.${type}`;

    if (this.intl.exists(key)) {
      return this.formatMessage(this.intl.t(key, options));
    }

    return super.getMessageFor(...arguments);
  }
}
