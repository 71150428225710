/* import __COLOCATED_TEMPLATE__ from './number.hbs'; */
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export default class BsNumberInput extends Component {
  onChange = (value) => {
    if (isPresent(value)) {
      value = Number(value);
    }

    this.args.onChange(value);
  };
}
