import { template } from "@ember/template-compiler";
import TaskStatuses from 'eflex/constants/task-statuses';
import Component from '@glimmer/component';
import JemTaskOkButton from 'eflex/components/jem/task-button/ok';
import { RETRY_STRATEGY_VALUES, REJECT_ALLOWED_RETRY_STRATEGIES } from 'eflex/constants/retry-strategy-values';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
const RetryButton = template(`
<BsButton
  class='reject-jem-task retry-button'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete @taskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'retry'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RestartButton = template(`
<BsButton
  class='restart-jem-task restart-button'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete @taskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'status.restart'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
let JemTaskButton = class JemTaskButton extends Component {
    get buttonComponent() {
        const { childStatus: childStatus1, task: task1 } = this.args;
        if (!childStatus1.isRunning && !childStatus1.isRestart) {
            if (childStatus1.isGood && task1.retryStrategy === RETRY_STRATEGY_VALUES.always) {
                return RestartButton;
            }
            if (childStatus1.isRejected && REJECT_ALLOWED_RETRY_STRATEGIES.has(task1.retryStrategy)) {
                return this.args.retryButtonOverride ?? RetryButton;
            }
        }
        return this.args.okButtonOverride ?? JemTaskOkButton;
    }
    static{
        template(`
    <this.buttonComponent
      class="jem-status-button text-uppercase h-100 mb-0"
      @type={{withDefault @type "task"}}
      @size={{withDefault @size "md"}}
      @disabled={{@disabled}}
      @taskStatuses={{TaskStatuses}}
      @onTaskComplete={{@onTaskComplete}}
      ...attributes
    >
      {{#if @notAuthorized}}
        <BsTooltip>
          {{t "notAuthorized"}}
        </BsTooltip>
      {{/if}}
    </this.buttonComponent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default JemTaskButton;
