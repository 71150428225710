/* import __COLOCATED_TEMPLATE__ from './dynamic-instruction.hbs'; */
import { inject as service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import { AnimationTypes } from 'eflex/constants/jem/instruction-animation-types';
import { TextTypes, EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { fabric } from 'fabric';
import toFastProperties from 'to-fast-properties';
import { clone } from 'ramda';
import { dequal } from 'dequal';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import {
  getCanvasObjects, getCanvasObjectByPoint, getCanvasObjectById,
  getCanvasCoordsForClick, updateObjectProperty, getArrowsByLine,
  scaleCanvas, cleanupCanvas, initializeCanvas,
} from 'eflex/util/fabric-helpers';
import { waitFor } from '@ember/test-waiters';

const ANIMATION_DURATION = 200;

export default class JemDynamicInstruction extends Component {
  @service store;

  previousAnimations = this.args.animations;
  previousWorkInstruction = this.args.workInstruction;
  canvas;

  getCanvasCoordsForClick = this.args.getCanvasCoordsForClick ?? getCanvasCoordsForClick;

  onResize = task({ drop: true }, waitFor(async element => {
    await timeout(getDelayTime(100));
    this._scaleCanvas(element);
  }));

  loadCanvas = task({ enqueue: true }, waitFor(async element => {
    cleanupCanvas(this.canvas);

    await this.store.findRecord('workInstruction', this.args.workInstruction.id, { reload: true });

    this.canvas = initializeCanvas(element.querySelector('.work-instruction-canvas'), {
      width: this.args.workInstruction.width,
      height: this.args.workInstruction.height,
      hoverCursor: 'default',
    });

    const canvasJson = this.args.workInstruction.deployedCanvas;
    canvasJson.selection = false;
    canvasJson.objects.forEach(obj => {
      obj.objectCaching = false;
      obj.selectable = false;
      obj.editable = false;

      if (obj.eflex != null) {
        obj.eflex.originalFillColor = obj.fill;
        obj.eflex.originalStrokeColor = obj.stroke;

        if (obj.type === TextTypes.TEXTBOX) {
          obj.eflex.originalStyles = clone(obj.styles);
        }

        if (obj.eflex.link?.length > 0) {
          obj.hoverCursor = 'pointer';
        }

        if (obj.eflex.startHidden && !this.args.previewMode) {
          obj.opacity = 0;
        }
      }
    });

    await new Promise((resolve) => {
      this.canvas.loadFromJSON(canvasJson, resolve);
    });

    this._scaleCanvas(element);
    this.prepareLineObjects();
    this.animate.perform(); // not yielded since it can use a while true for flash
  }));

  compareAttrs = task(
    { onState: null },
    waitFor(async (element, [workInstruction, animations, previewMode]) => {
      const workInstructionChanged = this.previousWorkInstruction !== workInstruction;
      const animationsChanged = !dequal(this.previousAnimations, animations);
      this.previousWorkInstruction = workInstruction;
      this.previousAnimations = animations;

      if (!workInstruction || workInstructionChanged || (previewMode && animations != null)) {
        await this.animate.cancelAll({ resetState: true });
      }

      if (workInstructionChanged) {
        await this.loadCanvas.perform(element);
      } else if (animationsChanged || (previewMode && animations != null)) {
        await this.animate.cancelAll({ resetState: true });
        this.animate.perform();

        if (previewMode) {
          await this.resetCanvas.perform();
        }
      }
    }),
  );

  resetCanvas = task({ restartable: true }, waitFor(async () => {
    this.setStartHiddenObjectOpacity(0);
    await timeout(this._getDelayTime(this.args.resetTimeout));

    const tasks = [this.animate.cancelAll({ resetState: true })];

    tasks.push(getCanvasObjects(this.canvas)?.map(obj =>
      this.resetObject.perform(obj),
    ));

    await all(tasks);
    this.setStartHiddenObjectOpacity(1);
  }));

  resetObject = task(waitFor(async obj => {
    let fillColor, strokeColor;
    if (obj.eflex != null) {
      await this.resetObjectOpacity.perform(obj);
      fillColor = obj.eflex.originalFillColor;
      strokeColor = obj.eflex.originalStrokeColor;
    }

    if (obj.type === TextTypes.TEXTBOX) {
      this.setTextboxColor(obj, null, strokeColor);
      if (obj.eflex != null) {
        obj.styles = clone(obj.eflex.originalStyles);
      }
    } else {
      this.setColor(obj, fillColor, strokeColor, true);
    }

    this._renderAllSafe();
  }));

  resetObjectOpacity = task(waitFor(async obj => {
    if (obj.eflex == null || !obj.eflex.isFlashing) {
      return;
    }

    const opacity = obj.eflex.startHidden ? 0 : 1;

    updateObjectProperty(obj.eflex, 'isFlashing', false);
    this.notifyModified(obj);

    await this._animateOpacity.perform(obj, opacity, {
      duration: 10,
      onChange: this._renderAllSafe,
    });
  }));

  showObject = task(waitFor(async obj => {
    await this.resetObjectOpacity.perform(obj);
    await this._animateOpacity.perform(obj, 1, {
      duration: ANIMATION_DURATION,
      easing: fabric.util.ease.easeInQuad,
      onChange: this._renderAllSafe,
    });
  }));

  hideObject = task(waitFor(async obj => {
    await this.resetObjectOpacity.perform(obj);
    await this._animateOpacity.perform(obj, 0, {
      duration: ANIMATION_DURATION,
      easing: fabric.util.ease.easeOutQuad,
      onChange: this._renderAllSafe,
    });
  }));

  flashObject = task(waitFor(async obj => {
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'isFlashing', true);
    this.notifyModified(obj);

    while (true) {
      await all([
        this._animateEaseOutAndIn.perform(obj),
        timeout(this._getDelayTime(ANIMATION_DURATION * 4)),
      ]);
    }
  }));

  _animateEaseOutAndIn = task(waitFor(async obj => {
    await this._animateOpacity.perform(obj, 0, {
      duration: ANIMATION_DURATION,
      easing: fabric.util.ease.easeOutQuad,
      onChange: this._renderAllSafe,
    });

    await this._animateOpacity.perform(obj, 1, {
      duration: ANIMATION_DURATION,
      easing: fabric.util.ease.easeInQuad,
      onChange: this._renderAllSafe,
    });
  }));

  animate = task({ enqueue: true }, waitFor(async () => {
    if (isEmpty(this.args.animations) || !this.canvas) {
      return;
    }

    const animateTasks = this.args.animations.map(animation => {
      const obj = getCanvasObjectById(this.canvas, animation.object);
      if (obj == null) {
        return null;
      }

      switch (animation.animation) {
        case AnimationTypes.SHOW: {
          this.setColor(obj, animation.fillColor, animation.strokeColor);
          return this.showObject.perform(obj);
        }

        case AnimationTypes.HIDE: {
          this.setColor(obj, animation.fillColor, animation.strokeColor);
          return this.hideObject.perform(obj);
        }

        case AnimationTypes.FLASH: {
          this.setColor(obj, animation.fillColor, animation.strokeColor);
          return this.flashObject.perform(obj);
        }

        case AnimationTypes.RESET: {
          return this.resetObject.perform(obj);
        }

        default: {
          return null;
        }
      }
    });

    await all(animateTasks);
  }));

  _animateOpacity = task(waitFor(async (obj, value, options) => {
    await new Promise(function (resolve) {
      options.onComplete = resolve;
      obj.animate('opacity', value, options);
    });
  }));

  openLink = task({ drop: true }, waitFor(async event => {
    if (event.target.tagName !== 'CANVAS') {
      return;
    }

    event.preventDefault();

    const { x, y } = this.getCanvasCoordsForClick(this.canvas, event);

    const obj = getCanvasObjectByPoint(this.canvas, x, y, true);
    const link = obj?.eflex?.link;

    if (isBlank(link)) {
      return;
    }

    if (link.startsWith('red:')) {
      await fetch(link.replace('red:', ''));
    } else {
      window.open(link);
    }
  }));

  willDestroy() {
    super.willDestroy(...arguments);
    cleanupCanvas(this.canvas);
    this.canvas = null;
  }

  _renderAllSafe = () => {
    try {
      this.canvas.renderAll();
    } catch (e) {
      // Don't care if animation is interrupted midway.
      if (!(e instanceof TypeError)) {
        throw e;
      }
    }
  };

  notifyModified(target) {
    if (!target) {
      return;
    }
    this.canvas.fire('object:modified', { target });
    this.canvas.renderAll();
  }

  _getDelayTime(time) {
    if (this.args.bypassDelayTime) {
      return time;
    } else {
      return getDelayTime(time);
    }
  }

  _scaleCanvas(element) {
    const display = element.querySelector('.work-instruction-display');

    if (display == null) {
      return;
    }

    const { width, height } = display.getBoundingClientRect();

    scaleCanvas(
      this.canvas,
      width,
      height,
      this.args.workInstruction?.width,
      this.args.workInstruction?.height,
    );
  }

  prepareLineObjects() {
    const lines = getCanvasObjects(this.canvas, EflexObjTypes.STRAIGHT_LINE);

    lines.forEach(line => {
      const arrowheads = getArrowsByLine(this.canvas, line);
      const layer = getCanvasObjects(this.canvas).indexOf(line);

      const id = line.id;
      delete line.id;
      toFastProperties(line);

      const objects = [line];
      objects.push(...arrowheads);
      objects.forEach(obj => { obj.opacity = 1; });

      const active = new fabric.ActiveSelection(objects, { canvas: this.canvas });
      const group = active.toGroup();
      group.set({
        id,
        selectable: false,
        editable: false,
        eflex: {
          type: EflexObjTypes.LINE_GROUP,
          startHidden: line.eflex.startHidden,
          originalStrokeColor: line.eflex.originalStrokeColor,
        },
      });

      group.moveTo(layer);

      if (line.eflex.startHidden && !this.args.previewMode) {
        group.opacity = 0;
      }

      this.canvas.discardActiveObject();
    });
  }

  setStartHiddenObjectOpacity(opacity = 0) {
    getCanvasObjects(this.canvas)?.forEach(obj => {
      if (obj.eflex?.startHidden) {
        obj.opacity = opacity;
      }
    });

    this._renderAllSafe();
  }

  setColor(obj, fillColor, strokeColor, allowNull = false) {
    const type = obj.eflex?.type ?? obj.type;

    switch (type) {
      case EflexObjTypes.LINE_GROUP: {
        this.setLineColor(obj, strokeColor);
        break;
      }
      case EflexObjTypes.ICON: {
        this.setIconColor(obj, fillColor);
        break;
      }
      case TextTypes.TEXTBOX: {
        this.setTextboxColor(obj, fillColor, strokeColor);
        break;
      }
      default: {
        if (fillColor != null || allowNull) {
          obj.set('fill', fillColor);
        }

        if (strokeColor != null || allowNull) {
          obj.set('stroke', strokeColor);
        }
      }
    }
  }

  setLineColor(lineGroup, strokeColor) {
    if (strokeColor == null) {
      return;
    }

    lineGroup.getObjects()?.forEach(function (obj) {
      switch (obj.eflex?.type) {
        case EflexObjTypes.STRAIGHT_LINE: {
          obj.set('stroke', strokeColor);
          break;
        }

        case EflexObjTypes.ARROWHEAD: {
          obj.set({
            fill: strokeColor,
            stroke: strokeColor,
          });
          break;
        }
      }
    });
  }

  setIconColor(icon, fillColor) {
    if (fillColor == null) {
      return;
    }

    if (icon.type === 'group') {
      icon.getObjects().forEach(obj => {
        this._setFill(obj, fillColor);
      });
    } else {
      this._setFill(icon, fillColor);
    }
  }

  setTextboxColor(obj, fillColor, strokeColor) {
    if (fillColor != null) {
      obj.setSelectionStyles({ fill: fillColor }, 0, obj.text.length);
    }

    if (strokeColor != null) {
      obj.set('stroke', strokeColor);
      obj.dirty = true;
    }
  }

  _setFill(obj, fillColor) {
    if (obj.fill) {
      obj.set('fill', fillColor);
    }

    if (obj.stroke) {
      obj.set('stroke', fillColor);
    }
  }
}
