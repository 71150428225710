import { task, all } from 'ember-concurrency';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class EvisionCameraConfigurationController extends Controller {
  @tracked showDefaultSettings = false;

  @service notifier;
  @service systemConfig;
  @service currentUser;
  @service taskRepo;

  get tasks() {
    return this.taskRepo.tasks.filter(item => item.isVision);
  }

  get defaultSettings() {
    return this.systemConfig.config.evision.fileRetention.defaultCameraConfiguration;
  }

  get isDirty() {
    return this.tasks.some(item => item.isDirty);
  }

  get isInvalid() {
    return this.tasks.some(item => item.isInvalid) || this.tasks.some(item => item.cameraConfiguration?.isInvalid);
  }

  save = task(waitFor(async () => {
    const invalidCamera = this.tasks.find(item => item.cameraConfiguration?.isInvalid);

    if (invalidCamera) {
      this.notifier.sendError('logs.cameraThumbnailConfigSmall', { treeItem: invalidCamera.name });
      return;
    }

    if (this.isInvalid) {
      return;
    }

    await all(this.tasks.map(_task => _task.save()));
  }));

  saveDefaultSettings = task(waitFor(async () => {
    if (this.defaultSettings.isInvalid) {
      this.notifier.sendError('evision.defaultThumbnailConfigError');
      return;
    }

    await this.systemConfig.config.save();
    this.showDefaultSettings = false;
    this.notifier.sendSuccess('logs.saved');
  }));

  @action
  rollback() {
    this.tasks.filter(item => !item.isDeleted).forEach(_task => { _task.rollbackAttributes(); });
  }

  @action
  rollbackDefaultSettings() {
    this.showDefaultSettings = false;
    this.systemConfig.config.rollbackAttributes();
  }
}
