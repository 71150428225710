import TaskParent from 'eflex/models/task-parent';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import validateTaskSequence from 'eflex/util/validate-task-sequence';
import { attr, belongsTo, hasMany } from '@ember-data/model';

@buildValidations({
  name: [validator('presence', true)],
  taskSequence: [
    validator('inline', { validate: validateTaskSequence }),
  ],
})
class KineticOperation extends TaskParent {
  @attr('string', { defaultValue: 'kineticOperation' }) type;
  @attr('number', { defaultValue: 0 }) sequence;
  @attr('string') operationCode;
  @attr('string') operationCodeDescription;
  @attr('string') path;

  @belongsTo('kineticPartRev', { inverse: 'operations', async: false }) parent;
  @hasMany('station', { async: false, inverse: null }) stations;

  get order() {
    return this.sequence;
  }

  get part() {
    return this.partRev?.part;
  }

  get partRev() {
    return this.parent;
  }
}

export default KineticOperation;
