import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { action } from '@ember/object';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { waitFor } from '@ember/test-waiters';
import { reject, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';

export default class SchedulesController extends Controller {
  @service validationErrorNotifier;
  @service scheduleRepo;

  @tracked scheduleCount;
  @tracked selectedSchedule;
  @tracked take;
  @tracked queriedSchedules;

  @tracked showSchedulePanel = false;
  @tracked category = 'current';
  @tracked page = 1;

  queryParams = ['category', 'page'];

  get schedules() {
    return this.scheduleRepo.schedules;
  }

  get nonDeletedSchedules() {
    if (!this.isCurrent) {
      return this.queriedSchedules;
    }

    const filters = [
      reject(prop('isDeleted')),
      reject(prop('isDestroyed')),
      reject(prop('isDestroying')),
      reject(prop('isArchived')),
      compact,
    ];

    return intoArray(...filters)(this.schedules);
  }

  get isDirty() {
    return this.schedules.some(item => item.isDirty);
  }

  get isInvalid() {
    return anyInvalid(this.nonDeletedSchedules);
  }

  get isCurrent() {
    return this.category === 'current';
  }

  save = task(waitFor(async () => {
    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(
        this.nonDeletedSchedules.concat(this.nonDeletedSchedules.flatMap(schedule => schedule.days)),
      );
      return;
    }

    await all(this.schedules.filter(item => item.isDirty).map(schedule => schedule.save()));
  }));

  @action
  rollback() {
    if (this.selectedSchedule?.isDeleted || this.selectedSchedule?.isNew) {
      this.closeSchedulePanel();
    }

    this.schedules.forEach(schedule => { schedule.rollbackAttributes(); });
  }

  @action
  createSchedule() {
    const schedule = this.scheduleRepo.createSchedule();
    this.openSchedulePanel(schedule);
  }

  @action
  deleteSchedule(schedule) {
    if (schedule.isFuture || schedule.isNew) {
      this.scheduleRepo.deleteSchedule(schedule);
    } else {
      this.scheduleRepo.archiveSchedule(schedule);
    }
  }

  @action
  changeCategory(category) {
    this.category = category;
    this.page = 1;
  }

  @action
  openSchedulePanel(schedule) {
    this.showSchedulePanel = true;
    this.selectedSchedule = schedule;
  }

  @action
  closeSchedulePanel() {
    this.showSchedulePanel = false;
    this.selectedSchedule = null;
  }
}
