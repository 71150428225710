/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { NotificationLogLevels, NotificationLogTypes } from 'eflex/constants/notification-log';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class NotificationLogCreateModal extends Component {
  @service store;
  @service currentUser;

  @tracked notes = [];

  _createLog(location, source) {
    return this.store.createRecord('log', {
      level: this.args.logLevel ?? NotificationLogLevels.INFO,
      logType: this.args.logType,
      pendingDeploy: this.args.pendingDeploy,
      plainMessage: this.notes,
      location: location?.asHistory() ?? null,
      source,
      createdBy: this.currentUser.user.toFragment(),
    });
  }

  _createAndSaveLog = task(waitFor(async () => {
    const log = this._createLog(this.args.location, this.args.source);
    await log.save();
  }));

  _createAndSaveWiLogs = task(waitFor(async () => {
    const logs = this.args.source.allUniqueAssignedStations?.map(station =>
      this._createLog(station, this.args.source.id),
    );

    await all(logs.map(log => log.save()));
  }));

  onConfirm = task(waitFor(async () => {
    if (this.args.logType === NotificationLogTypes.WIE) {
      await this._createAndSaveWiLogs.perform();
    } else {
      await this._createAndSaveLog.perform();
    }

    await this.args.onConfirm?.();
  }));
}
