import { defaults, defaultStack } from '@pnotify/core';

defaults.delay = 2000;

defaultStack.maxOpen = Infinity;
defaultStack.modal = false;

window.disablePnotify = function() {
  defaults.autoOpen = false;
};
