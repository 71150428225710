/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class BiPanelTaskTags extends Component {
  @service taskRepo;

  get tags() {
    return [...new Set(this.taskRepo.tasks.flatMap(_task => _task.tags))].toSorted();
  }
}
