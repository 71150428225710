/* import __COLOCATED_TEMPLATE__ from './web-cam-scanner.hbs'; */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';

const cleanupBarcodeScanner = (barcodeScanner) => {
  if (!barcodeScanner) {
    return;
  }

  barcodeScanner.isCancelled = true;
  barcodeScanner.stopContinuousDecode();
  barcodeScanner.videoCanPlayListener = null;
  barcodeScanner.videoEndedListener = null;
  barcodeScanner.videoPlayingEventListener = null;
  barcodeScanner.reset();
};

const getReader = async (barcodeSymbology) => {
  if (window.isTesting) {
    return {
      isCancelled: false,
      stopContinuousDecode() {},
      reset() {},
      decodeFromVideoDevice() {
        return Promise.resolve();
      },
    };
  }

  const { BrowserMultiFormatReader, DecodeHintType } = await import('@zxing/library');

  if (barcodeSymbology != null) {
    const hints = new Map([[DecodeHintType.POSSIBLE_FORMATS, [barcodeSymbology]]]);
    return new BrowserMultiFormatReader(hints);
  } else {
    return new BrowserMultiFormatReader();
  }
};

export default class WebCamScanner extends Component {
  @service webCam;
  @service eventBus;

  #previousCameraId = this.args.cameraId;
  #previousBarcodeSymbology = this.args.barcodeSymbology;
  #barcodeScanner;

  constructor() {
    super(...arguments);
    registerDestructor(this, () => {
      cleanupBarcodeScanner(this.#barcodeScanner);
    });
  }

  start = task(
    { keepLatest: true },
    waitFor(async (element, [cameraId, barcodeSymbology]) => {
      if (!this.webCam.devices.some(item => item.deviceId === cameraId)) {
        cameraId = undefined;
      }

      this.#barcodeScanner = await getReader(barcodeSymbology);

      const seen = new Set();

      await this.#barcodeScanner.decodeFromVideoDevice(cameraId, element, (value, err) => {
        if (this.#barcodeScanner.isCancelled) {
          cleanupBarcodeScanner(this.#barcodeScanner);
          return;
        }

        if (err != null) {
          return;
        }

        const { text } = value;

        if (seen.has(text)) {
          return;
        }

        seen.add(text);
        this.eventBus.trigger('barcodeScanned', text);
        this.args.onScan?.(text);
      });
    }),
  );

  onDidUpdate = task(waitFor(async (element, [cameraId, barcodeSymbology]) => {
    const cameraIdChanged = this.#previousCameraId !== cameraId;
    const barcodeSymbologyChanged = this.#previousBarcodeSymbology !== barcodeSymbology;
    this.#previousCameraId = cameraId;
    this.#previousBarcodeSymbology = barcodeSymbology;

    if (cameraIdChanged || barcodeSymbologyChanged) {
      cleanupBarcodeScanner(this.#barcodeScanner);
      await this.start.perform(element, [cameraId, barcodeSymbology]);
    }
  }));
}
